import { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation, NavigateFunction } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Auth } from 'aws-amplify'
import { CognitoUser } from '@aws-amplify/auth'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAppDispatch, GENERAL_LOGOUT_ACTION } from 'store/store'
import { fetchCurrentUser, fetchCurrentUserOnLoggingIn } from 'store/current_user'
import { getInboxesList, isInboxesListReady } from 'store/inboxes'
import {
  areCredentialsValid,
  buildStoredCredentials,
  clearStoredCredentials,
  setStoredCredentials,
  getOrRefreshAccessTokenWithCognitoOrStoredCredentials
} from 'utils/credentials_service'
import { setIsInboundKey } from 'utils/inbound_service'
import { Inbox } from 'interfaces/inbox.interface'
import { handleCrossAppCognitoAuthCookies, sanitizeRedirectPath } from 'apps/AuthenticationApp/utils/auth'
import useZendeskService from './useZendeskService'

declare global {
  interface Window {
    getAccessToken: () => Promise<string>
  }
}

interface UserCredentialHook {
  isUserLoading: boolean
}

export default function useUserCredentials(): UserCredentialHook {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const { hideZendesk } = useZendeskService()
  const [loading, setLoading] = useState(true)
  const [impersonateToken] = useState(() => {
    const searchParams = new URLSearchParams(search)
    return searchParams.get('token')
  })
  const inboxesListReady = useSelector(isInboxesListReady)
  const inboxesList = useSelector(getInboxesList)
  const flags = useFlags()

  const impersonateLogin = useCallback(() => {
    clearStoredCredentials()
    clearUserState()
    try {
      const parsedCredentials = JSON.parse(impersonateToken ?? '')
      const storedCredentials = buildStoredCredentials(parsedCredentials)
      if (!areCredentialsValid(storedCredentials)) return
      setStoredCredentials(parsedCredentials)
      appDispatch(fetchCurrentUser())
        .unwrap()
        .then(() => {
          setIsInboundKey()
        })
        .finally(() => {
          setLoading(false)
        })
    } catch (e) {}
  }, []) // eslint-disable-line

  const handleLogout = useCallback(async () => {
    const searchParams = new URLSearchParams(search)
    const accountClosedOrTerminated = searchParams.get('accountClosedOrTerminated')
    const noInboxes = searchParams.get('noInboxes')
    const redirectUrl = sanitizeRedirectPath(searchParams.get('redirect_url'))
    const isEligibleForReactivation = searchParams.get('isEligibleForReactivation')
    const missingMFA = searchParams.get('missingMFA')
    clearStoredCredentials()
    clearUserState()
    hideZendesk()
    await Auth.signOut().catch(error => {
      console.error('There was a problem with logout process', error)
    })
    dispatch({ type: GENERAL_LOGOUT_ACTION })
    if (missingMFA) {
      // in case of a missing MFA we do immediate, automatic logout so there might be some pending XHR requests,
      // without reloading the page we may end up on the login page with the store containing data of the previous user
      window.location.href = '/login'
    } else if (accountClosedOrTerminated != null) {
      navigate(`/login?accountClosedOrTerminated=${accountClosedOrTerminated}`)
    } else if (noInboxes != null) {
      navigate('/login?noInboxes=true')
    } else if (redirectUrl != null) {
      navigate(`/login?redirect_url=${redirectUrl}`)
    } else if (isEligibleForReactivation != null) {
      navigate('/login?isEligibleForReactivation=false')
    } else {
      navigate('/login')
    }
  }, [pathname, search, navigate, dispatch]) // eslint-disable-line

  useEffect(() => {
    window.getAccessToken = getOrRefreshAccessTokenWithCognitoOrStoredCredentials
  }, [])

  useEffect(() => {
    if (pathname === '/logout') handleLogout()
  }, [pathname]) // eslint-disable-line

  useEffect(() => {
    if (pathname !== '/login' || !inboxesListReady || inboxesList.length === 0) {
      return
    }

    checkMFAState(inboxesList, navigate, flags.logOutOnMissingMfa)
  }, [flags.logOutOnMissingMfa, inboxesList, inboxesListReady, navigate, pathname])

  useEffect(() => {
    if (pathname === '/logout') return
    if (pathname === '/' && impersonateToken != null) {
      impersonateLogin()
    } else {
      handleCrossAppCognitoAuthCookies()
      getOrRefreshAccessTokenWithCognitoOrStoredCredentials()
        .then(() => {
          appDispatch(fetchCurrentUserOnLoggingIn())
            .unwrap()
            .finally(() => {
              setLoading(false)
            })
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, []) // eslint-disable-line

  const clearUserState = (): void => {
    setLoading(false)
  }

  return {
    isUserLoading: loading
  }
}

async function checkMFAState(
  inboxesList: Inbox[],
  navigate: NavigateFunction,
  shouldLogOutOnMissingMFA: boolean
): Promise<void> {
  try {
    const user: CognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true })

    user.getUserData(async (err, data) => {
      if (err) {
        console.error('Failed to retrieve user data to check MFA state', err)
        return
      }

      const isLoginFromIDP = data?.UserAttributes?.some(({ Name, Value }) => Name === 'identities' && Value)

      if (isLoginFromIDP) {
        return
      }

      const isMFADisabled = data && !data.UserMFASettingList?.length

      if (isMFADisabled) {
        const isMFARequired = !inboxesList.some(inbox => inbox.account.mfa_optional === true)

        if (isMFARequired) {
          if (shouldLogOutOnMissingMFA) {
            navigate('/logout?missingMFA=true')
          } else {
            console.error('User logged in without enabled MFA')
          }
        }
      }
    })
  } catch (error) {
    console.error('Failed to retrieve current authenticated user to check MFA state:', error)
  }
}
