import { omit } from 'lodash'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, isAxiosError } from 'axios'
import { RootState } from 'store/store'
import { appCreateAsyncThunk } from 'store/app_create_async_thunk'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { Applicant, ApplicantResponseBody, ApplicantStatus, ApplicantDocuments } from 'interfaces/applicant.interface'
import { CourtOrder } from 'shared_components/CourtOrderedProtectedIndividualInput/CourtOrderedProtectedIndividualInput'

export interface ApplicantState {
  applicant: Applicant
  applicantStatus: RequestStatus
  applicantInitialLoading: boolean
  applicantError: RequestError | undefined
  applicantDocuments: ApplicantDocuments[]
  applicantDocumentsStatus: RequestStatus
  applicantDocumentsError: RequestError | undefined
  applicantUpdateStatus?: RequestStatus
  applicantUpdateError?: RequestError
}

const initialState: ApplicantState = {
  applicant: {
    first_name: '',
    last_name: '',
    has_ssn: true,
    primary_id_document: {
      type: '',
      id_number: '',
      issuing_country: '',
      issuing_state: '',
      expiration_date: null
    },
    secondary_id_document: { type: '' },
    home_address: {
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zip_code: '',
      country: 'US'
    },
    phone_number: '',
    status: ApplicantStatus.Unverified,
    flow_version: 'v2'
  },
  applicantStatus: RequestStatus.Pending,
  applicantInitialLoading: true,
  applicantError: undefined,
  applicantDocuments: [],
  applicantDocumentsStatus: RequestStatus.Pending,
  applicantDocumentsError: undefined
}

interface FetchApplicantDocumentsParams {
  accountId: number
}

export const fetchApplicant = createAsyncThunk('applicant/fetchApplicant', async () => {
  try {
    const response: { data: ApplicantResponseBody } = await apiClient.get('mail-authorization/applicant')
    return response.data
  } catch (e: any) {
    const error: AxiosError = { ...e }
    if (error.response?.status === 404) return initialState.applicant
    throw e
  }
})

export const fetchApplicantDocuments = createAsyncThunk(
  'applicant/fetchApplicantDocuments',
  async ({ accountId }: FetchApplicantDocumentsParams) => {
    try {
      const response: { data: ApplicantDocuments[] } = await apiClient.get(
        `mail-authorization/accounts/${accountId}/applicant/documents`
      )
      return response.data
    } catch (e: any) {
      const error: AxiosError = { ...e }
      if (error.response?.status === 404) return initialState.applicantDocuments
      throw e
    }
  }
)

export interface UpdateApplicantParams {
  accountId: number
  applicant: Applicant
  courtOrder: CourtOrder
}

export const updateApplicant = appCreateAsyncThunk(
  'applicant/updateApplicant',
  async ({ accountId, applicant, courtOrder }: UpdateApplicantParams, { getState }) => {
    const [previousCourtOrder] = getApplicantDocuments(getState())

    try {
      const { data: newApplicantData } = await apiClient.post<ApplicantResponseBody>('mail-authorization/applicant', {
        first_name: applicant.first_name,
        last_name: applicant.last_name,
        middle_initial: applicant.middle_initial,
        has_ssn: applicant.has_ssn,
        primary_id_document: {
          type: applicant.primary_id_document?.type,
          id_number: applicant.primary_id_document?.id_number,
          issuing_state: applicant.primary_id_document?.issuing_state,
          issuing_country: applicant.primary_id_document?.issuing_country,
          expiration_date: applicant.primary_id_document?.expiration_date
        },
        secondary_id_document: { type: applicant.secondary_id_document?.type },
        home_address: {
          address_line_1: applicant.home_address.address_line_1,
          address_line_2: applicant.home_address.address_line_2,
          city: applicant.home_address.city,
          state: applicant.home_address.state,
          zip_code: applicant.home_address.zip_code,
          country: applicant.home_address.country
        },
        phone_number: applicant.phone_number
      })
      if (previousCourtOrder?.id && (courtOrder.content || !courtOrder.id)) {
        await apiClient.delete(`mail-authorization/accounts/${accountId}/applicant/documents/${previousCourtOrder.id}`)
      }
      if (courtOrder.content) {
        const { data: newCourtOrderData } = await apiClient.post<ApplicantDocuments>(
          `mail-authorization/accounts/${accountId}/applicant/documents`,
          {
            type: 'court_ordered_protection',
            document: {
              name: courtOrder.name,
              content: courtOrder.content
            }
          }
        )

        return { applicant: newApplicantData, documents: [newCourtOrderData] }
      }

      return { applicant: newApplicantData, documents: [] }
    } catch (e: unknown) {
      if (isAxiosError(e)) {
        const errorMessage = e?.response?.data?.error_message
        if (typeof errorMessage === 'string') {
          throw new Error(errorMessage)
        }
      }

      throw new Error('An error occurred while updating the applicant. Please try again later or contact support.')
    }
  }
)

export const applicantSlice = createSlice({
  name: 'applicant',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchApplicant.fulfilled, (state, action) => {
        state.applicantStatus = RequestStatus.Success
        // Omitting the issuing_entity field since it's not used anymore in the UI
        state.applicant = omit(action.payload, 'primary_id_document.issuing_entity')
        state.applicantInitialLoading = false
        state.applicantError = undefined
      })
      .addCase(fetchApplicant.pending, state => {
        state.applicantStatus = RequestStatus.Pending
      })
      .addCase(fetchApplicant.rejected, (state, action) => {
        state.applicantStatus = RequestStatus.Error
        state.applicant = initialState.applicant
        state.applicantError = action.error.message
      })
      .addCase(fetchApplicantDocuments.fulfilled, (state, action) => {
        state.applicantDocuments = action.payload
        state.applicantDocumentsStatus = RequestStatus.Success
        state.applicantDocumentsError = undefined
      })
      .addCase(fetchApplicantDocuments.rejected, (state, action) => {
        state.applicantDocuments = []
        state.applicantStatus = RequestStatus.Error
        state.applicantError = action.error.message
      })
      .addCase(updateApplicant.pending, state => {
        state.applicantUpdateStatus = RequestStatus.Pending
      })
      .addCase(updateApplicant.fulfilled, (state, action) => {
        state.applicant = action.payload.applicant
        state.applicantDocuments = action.payload.documents
        state.applicantUpdateStatus = RequestStatus.Success
        state.applicantUpdateError = undefined
      })
      .addCase(updateApplicant.rejected, (state, action) => {
        state.applicantUpdateStatus = RequestStatus.Error
        state.applicantUpdateError = action.error.message
      })
  }
})

export const getApplicant = (state: RootState): Applicant => state.applicant.applicant
export const isApplicantReady = (state: RootState): boolean => state.applicant.applicantStatus === RequestStatus.Success
export const getHasApplicant = (state: RootState): boolean => state.applicant.applicant.created_at !== undefined
export const getApplicantStatus = (state: RootState): ApplicantStatus | undefined => state.applicant.applicant.status
export const getIsApplicantVerified = (state: RootState): boolean =>
  state.applicant.applicant.status === ApplicantStatus.Verified
export const getIsApplicantInitialLoading = (state: RootState): boolean => state.applicant.applicantInitialLoading
export const getApplicantDocuments = (state: RootState): ApplicantDocuments[] => state.applicant.applicantDocuments
export const areApplicantDocumentsReady = (state: RootState): boolean =>
  state.applicant.applicantDocumentsStatus === RequestStatus.Success
export const getIsApplicantUpdateInProgress = (state: RootState): boolean =>
  state.applicant.applicantUpdateStatus === RequestStatus.Pending
export const getApplicantUpdateError = (state: RootState): RequestError | undefined =>
  state.applicant.applicantUpdateError

export default applicantSlice.reducer
