import { createAsyncThunk, createSlice, miniSerializeError, SerializedError } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { RecipientsApiStructure } from 'apps/SettingsApp/interfaces/recipient_api_structure.interface'

export interface RecipientsStructureMigrationState {
  structure: RecipientsApiStructure
  status?: RequestStatus
  updateStatus?: RequestStatus
  applyStatus?: RequestStatus
  error?: RequestError
  updateError?: RequestError
  applyError?: RequestError
}

const initialState: RecipientsStructureMigrationState = {
  structure: []
}

interface BaseRecipientsStructureArgs {
  accountId: number
}

const serializeError = (e: unknown): SerializedError =>
  axios.isAxiosError(e)
    ? {
        message: e.response?.data.error.message || e.message
      }
    : miniSerializeError(e)

export const fetchRecipientsStructure = createAsyncThunk(
  'recipientsStructureMigration/fetchStructure',
  async ({ accountId }: BaseRecipientsStructureArgs): Promise<RecipientsApiStructure> => {
    try {
      const { data } = await apiClient.get(`/mail-authorization/accounts/${accountId}/migration/recipients-structure`)
      return data
    } catch (e) {
      if ((e as AxiosError).response?.status === 404) {
        return []
      }
      throw e
    }
  },
  { serializeError }
)

interface UpdateRecipientsStructureArgs extends BaseRecipientsStructureArgs {
  structure: RecipientsApiStructure
}

export const updateRecipientsStructure = createAsyncThunk(
  'recipientsStructureMigration/updateStructure',
  async ({ accountId, structure }: UpdateRecipientsStructureArgs): Promise<void> => {
    await apiClient.post(`/mail-authorization/accounts/${accountId}/migration/recipients-structure`, structure)
  },
  { serializeError }
)

export const applyRecipientsStructure = createAsyncThunk(
  'recipientsStructureMigration/applyStructure',
  async ({ accountId }: BaseRecipientsStructureArgs): Promise<void> =>
    apiClient.post(`/mail-authorization/accounts/${accountId}/migration/apply-recipients-structure`),
  { serializeError }
)

export const recipientsStructureMigrationSlice = createSlice({
  name: 'recipientsStructureMigration',
  initialState,
  reducers: {
    resetUpdateRecipientsStructureStatus: state => {
      state.updateStatus = undefined
    },
    resetApplyRecipientsStructureStatus: state => {
      state.applyStatus = undefined
    },
    resetRecipientsStructureErrors: state => {
      state.error = undefined
      state.updateError = undefined
      state.applyError = undefined
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRecipientsStructure.fulfilled, (state, action) => {
        state.status = RequestStatus.Success
        state.structure = action.payload
        state.error = undefined
      })
      .addCase(fetchRecipientsStructure.pending, state => {
        state.status = RequestStatus.Pending
      })
      .addCase(fetchRecipientsStructure.rejected, (state, action) => {
        state.status = RequestStatus.Error
        state.structure = initialState.structure
        state.error = action.error.message
      })
      .addCase(updateRecipientsStructure.fulfilled, state => {
        state.updateStatus = RequestStatus.Success
      })
      .addCase(updateRecipientsStructure.pending, state => {
        state.updateStatus = RequestStatus.Pending
        state.updateError = undefined
      })
      .addCase(updateRecipientsStructure.rejected, (state, action) => {
        state.updateStatus = RequestStatus.Error
        state.updateError = action.error.message
      })
      .addCase(applyRecipientsStructure.fulfilled, state => {
        state.applyStatus = RequestStatus.Success
      })
      .addCase(applyRecipientsStructure.pending, state => {
        state.applyStatus = RequestStatus.Pending
        state.applyError = undefined
      })
      .addCase(applyRecipientsStructure.rejected, (state, action) => {
        state.applyStatus = RequestStatus.Error
        state.applyError = action.error.message
      })
  }
})

export const {
  resetUpdateRecipientsStructureStatus,
  resetApplyRecipientsStructureStatus,
  resetRecipientsStructureErrors
} = recipientsStructureMigrationSlice.actions

export const getRecipientsStructure = (state: RootState): RecipientsApiStructure =>
  state.settingsApp.recipientsStructureMigration.structure
export const getRecipientsStructureStatus = (state: RootState): RequestStatus | undefined =>
  state.settingsApp.recipientsStructureMigration.status
export const getUpdateRecipientsStructureStatus = (state: RootState): RequestStatus | undefined =>
  state.settingsApp.recipientsStructureMigration.updateStatus
export const getApplyRecipientsStructureStatus = (state: RootState): RequestStatus | undefined =>
  state.settingsApp.recipientsStructureMigration.applyStatus
export const getUpdateRecipientsStructureError = (state: RootState): RequestError | undefined =>
  state.settingsApp.recipientsStructureMigration.updateError
export const getApplyRecipientsStructureError = (state: RootState): RequestError | undefined =>
  state.settingsApp.recipientsStructureMigration.applyError

export default recipientsStructureMigrationSlice.reducer
