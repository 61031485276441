import { SerializedError } from '@reduxjs/toolkit'
import { AxiosError, isAxiosError } from 'axios'

export interface SerializerOptions {
  defaultMessage?: string
  extractMessage?: (error: AxiosError<any, any>) => string | undefined
}

export default function getApiErrorSerializer({
  defaultMessage = 'An error occurred while processing your request. Please try again later or contact support.',
  extractMessage = (error: AxiosError<any, any>): string | undefined => error.response?.data?.error_mesage
}: SerializerOptions = {}): (error: unknown) => SerializedError {
  return (error: unknown) => {
    if (isAxiosError(error)) {
      const errorMessage = extractMessage(error)

      if (typeof errorMessage === 'string') {
        return { message: errorMessage }
      }
    }

    return {
      message: defaultMessage
    }
  }
}
