import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'

export interface UiState {
  isMobileSubnavigationOpen: boolean
}

const initialState: UiState = {
  isMobileSubnavigationOpen: false
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleIsMobileSubnavigationOpen(state) {
      state.isMobileSubnavigationOpen = !state.isMobileSubnavigationOpen
    }
  }
})

export default uiSlice.reducer

export const { toggleIsMobileSubnavigationOpen } = uiSlice.actions
export const getIsMobileSubnavigationOpen = (state: RootState): boolean => state.uiStore.isMobileSubnavigationOpen
