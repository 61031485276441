import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { Country } from 'interfaces/country.interface'

const PREDEFINED_COUNTRIES = ['AU', 'CA', 'GB', 'US']

export interface CountriesState {
  countriesList: Country[]
  countriesListStatus: RequestStatus
  countriesListError: RequestError | undefined
  embargoedCountriesList: Country[]
  embargoedCountriesListStatus: RequestStatus
  embargoedCountriesListError: RequestError | undefined
}

const initialState: CountriesState = {
  countriesList: [],
  countriesListStatus: RequestStatus.Pending,
  countriesListError: undefined,
  embargoedCountriesList: [],
  embargoedCountriesListStatus: RequestStatus.Pending,
  embargoedCountriesListError: undefined
}

export const fetchCountries = createAsyncThunk('countries/fetchCountriesList', async () => {
  const response: { data: Country[] } = await apiClient.get('/config/countries')
  return response.data
})

export const fetchEmbargoedCountries = createAsyncThunk('countries/fetchEmbargoedCountriesList', async () => {
  const response: { data: Country[] } = await apiClient.get('/config/countries?include_embargoed=true')
  return response.data
})

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setCountriesInitialState: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countriesListStatus = RequestStatus.Success
        state.countriesList = action.payload
        state.countriesListError = undefined
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.countriesListStatus = RequestStatus.Error
        state.countriesList = initialState.countriesList
        state.countriesListError = action.error.message
      })
      .addCase(fetchEmbargoedCountries.fulfilled, (state, action) => {
        state.embargoedCountriesListStatus = RequestStatus.Success
        state.embargoedCountriesList = action.payload
        state.embargoedCountriesListError = undefined
      })
      .addCase(fetchEmbargoedCountries.pending, state => {
        state.embargoedCountriesListStatus = RequestStatus.Pending
      })
      .addCase(fetchEmbargoedCountries.rejected, (state, action) => {
        state.embargoedCountriesListStatus = RequestStatus.Error
        state.embargoedCountriesList = initialState.embargoedCountriesList
        state.embargoedCountriesListError = action.error.message
      })
  }
})

export const { setCountriesInitialState } = countriesSlice.actions
export const getCountriesList = (state: RootState): Country[] => state.countries.countriesList
export const getEmbargoedCountriesList = (state: RootState): Country[] => state.countries.embargoedCountriesList
export const getPredefinedCountriesList = (state: RootState): Country[] =>
  state.countries.countriesList.filter(({ country_abbreviation }) =>
    PREDEFINED_COUNTRIES.includes(country_abbreviation)
  ) // eslint-disable-line @typescript-eslint/naming-convention
export const getCountriesWithoutPredefinedList = (state: RootState): Country[] =>
  state.countries.countriesList.filter(
    ({ country_abbreviation }) => !PREDEFINED_COUNTRIES.includes(country_abbreviation)
  ) // eslint-disable-line @typescript-eslint/naming-convention
export const getPredefinedEmbargoedCountriesList = (state: RootState): Country[] =>
  state.countries.embargoedCountriesList.filter(({ country_abbreviation }) =>
    PREDEFINED_COUNTRIES.includes(country_abbreviation)
  ) // eslint-disable-line @typescript-eslint/naming-convention
export const getEmbargoedCountriesWithoutPredefinedList = (state: RootState): Country[] =>
  state.countries.embargoedCountriesList.filter(
    ({ country_abbreviation }) => !PREDEFINED_COUNTRIES.includes(country_abbreviation)
  ) // eslint-disable-line @typescript-eslint/naming-convention

export default countriesSlice.reducer
