import styles from './ExtendedNavigationLinkSkeleton.module.scss'

export default function ExtendedNavigationLinkSkeleton(): JSX.Element {
  return (
    <li className={styles.listItem}>
      <div className={styles.icon} />
      <div className={styles.link} />
    </li>
  )
}
