import EcmLogoSmall from 'assets/ecm_logo_small.svg'
import ExtendedNavigationLinkSkeleton from './ExtendedNavigationLinkSkeleton'
import styles from './ExtendedNavigationSkeleton.module.scss'

export default function ExtendedNavigationSkeleton(): JSX.Element {
  return (
    <nav className={styles.navigation} aria-busy='true'>
      <div className={styles.top}>
        <img src={EcmLogoSmall} className={styles.logo} alt='logo' />
        <div className={styles.accountSwitcherWrapper}>
          <div className={styles.ecmNumber}>ECM Number</div>
          <div className={styles.accountSwitcher} />
        </div>
      </div>
      <div className={styles.wrapper}>
        <ul className={styles.list}>
          {[...Array(4)].map((x, i) => (
            <ExtendedNavigationLinkSkeleton key={i} />
          ))}
        </ul>
        <ul className={styles.list}>
          <ExtendedNavigationLinkSkeleton />
          <hr className={styles.divider} />
          {[...Array(2)].map((x, i) => (
            <ExtendedNavigationLinkSkeleton key={i} />
          ))}
        </ul>
      </div>
    </nav>
  )
}
