import WorkSansExtraLight from 'fonts/Work_Sans/static/WorkSans-ExtraLight.ttf'
import WorkSansLight from 'fonts/Work_Sans/static/WorkSans-Light.ttf'
import WorkSansRegular from 'fonts/Work_Sans/static/WorkSans-Regular.ttf'
import WorkSansMedium from 'fonts/Work_Sans/static/WorkSans-Medium.ttf'
import WorkSansSemiBold from 'fonts/Work_Sans/static/WorkSans-SemiBold.ttf'
import WorkSansBold from 'fonts/Work_Sans/static/WorkSans-Bold.ttf'
import WorkSansExtraBold from 'fonts/Work_Sans/static/WorkSans-ExtraBold.ttf'
import WorkSansBlack from 'fonts/Work_Sans/static/WorkSans-Black.ttf'
import WorkSansExtraLightItalic from 'fonts/Work_Sans/static/WorkSans-ExtraLightItalic.ttf'
import WorkSansLightItalic from 'fonts/Work_Sans/static/WorkSans-LightItalic.ttf'
import WorkSansItalic from 'fonts/Work_Sans/static/WorkSans-Italic.ttf'
import WorkSansMediumItalic from 'fonts/Work_Sans/static/WorkSans-MediumItalic.ttf'
import WorkSansSemiBoldItalic from 'fonts/Work_Sans/static/WorkSans-SemiBoldItalic.ttf'
import WorkSansBoldItalic from 'fonts/Work_Sans/static/WorkSans-BoldItalic.ttf'
import WorkSansExtraBoldItalic from 'fonts/Work_Sans/static/WorkSans-ExtraBoldItalic.ttf'
import WorkSansBlackItalic from 'fonts/Work_Sans/static/WorkSans-BlackItalic.ttf'

const fonts: string = `
  // font-style: normal

  @font-face {
    font-family: 'Work Sans';
    font-weight: 200;
    font-style: normal;
    src: local('WorkSansExtraLight'), url(${String(WorkSansExtraLight)}) format('truetype');
    font-display: swap
  }
  @font-face {
    font-family: 'Work Sans';
    font-weight: 300;
    font-style: normal;
    src: local('WorkSansLight'), url(${String(WorkSansLight)}) format('truetype');
    font-display: swap
  }
  @font-face {
    font-family: 'Work Sans';
    font-weight: 400;
    font-style: normal;
    src: local('WorkSansRegular'), url(${String(WorkSansRegular)}) format('truetype');
    font-display: swap
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 500;
    font-style: normal;
    src: local('WorkSansMedium'), url(${String(WorkSansMedium)}) format('truetype');
    font-display: swap
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 600;
    font-style: normal;
    src: local('WorkSansSemiBold'), url(${String(WorkSansSemiBold)}) format('truetype');
    font-display: swap
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 700;
    font-style: normal;
    src: local('WorkSansBold'), url(${String(WorkSansBold)}) format('truetype');
    font-display: swap
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 800;
    font-style: normal;
    src: local('WorkSansExtraBold'), url(${String(WorkSansExtraBold)}) format('truetype');
    font-display: swap
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 900;
    font-style: normal;
    src: local('WorkSansBlack'), url(${String(WorkSansBlack)}) format('truetype');
    font-display: swap
  }

  // font-style: italic
  
  @font-face {
    font-family: 'Work Sans';
    font-weight: 200;
    font-style: italic;
    src: local('WorkSansExtraLightItalic'), url(${String(WorkSansExtraLightItalic)}) format('truetype');
    font-display: swap
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 300;
    font-style: italic;
    src: local('WorkSansLightItalic'), url(${String(WorkSansLightItalic)}) format('truetype');
    font-display: swap
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 400;
    font-style: italic;
    src: local('WorkSansItalic'), url(${String(WorkSansItalic)}) format('truetype');
    font-display: swap
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 500;
    font-style: italic;
    src: local('WorkSansMediumItalic'), url(${String(WorkSansMediumItalic)}) format('truetype');
    font-display: swap
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 600;
    font-style: italic;
    src: local('WorkSansSemiBoldItalic'), url(${String(WorkSansSemiBoldItalic)}) format('truetype');
    font-display: swap
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 700;
    font-style: italic;
    src: local('WorkSansBoldItalic'), url(${String(WorkSansBoldItalic)}) format('truetype');
    font-display: swap
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 800;
    font-style: italic;
    src: local('WorkSansExtraBoldItalic'), url(${String(WorkSansExtraBoldItalic)}) format('truetype');
    font-display: swap
  }

  @font-face {
    font-family: 'Work Sans';
    font-weight: 900;
    font-style: italic;
    src: local('WorkSansBlackItalic'), url(${String(WorkSansBlackItalic)}) format('truetype');
    font-display: swap
  }
  `

export default fonts
