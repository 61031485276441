import { createContext, useContext } from 'react'
import { User } from 'interfaces/user.interface'
import { InboxWithFlags } from 'interfaces/inbox.interface'

interface UserContextInterface {
  currentUser: User
  currentInbox: InboxWithFlags
}

export const UserContext = createContext<UserContextInterface | null>(null)

const useUserContext = (): UserContextInterface => {
  const context = useContext(UserContext)

  if (context === null) {
    throw new Error('Missing <UserContext.Provider />')
  }

  return context
}

export default useUserContext
