import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { omit } from 'lodash'
import { RootState } from 'store/store'
import { VerificationMediaDocumentType } from 'shared_components/ApplicantVerification/interfaces/automated_verification.interface'

interface AutomatedVerificationImages {
  [VerificationMediaDocumentType.Face]: string
  [VerificationMediaDocumentType.PrimaryFront]: string
  [VerificationMediaDocumentType.PrimaryBack]: string
  [VerificationMediaDocumentType.SecondaryFront]: string
}

export interface AutomatedVerificationState {
  images: AutomatedVerificationImages
  verificationCheckId: string
}

const initialState: AutomatedVerificationState = {
  images: {
    [VerificationMediaDocumentType.Face]: '',
    [VerificationMediaDocumentType.PrimaryFront]: '',
    [VerificationMediaDocumentType.PrimaryBack]: '',
    [VerificationMediaDocumentType.SecondaryFront]: ''
  },
  verificationCheckId: ''
}

export const automatedVerificationSlice = createSlice({
  name: 'automatedVerification',
  initialState,
  reducers: {
    setImage(state, action: PayloadAction<{ context: VerificationMediaDocumentType; content: string }>) {
      state.images[action.payload.context] = action.payload.content
    },
    clearImage(state, action: PayloadAction<{ context: VerificationMediaDocumentType }>) {
      state.images[action.payload.context] = ''
    },
    clearImages(state) {
      state.images = initialState.images
    }
  }
})

export const { setImage, clearImage, clearImages } = automatedVerificationSlice.actions

export const getImages = (state: RootState): AutomatedVerificationImages => state.automatedVerification.images
export const getImage =
  (imageType: VerificationMediaDocumentType) =>
  (state: RootState): string =>
    state.automatedVerification.images[imageType]
export const getVerificationCheckId = (state: RootState): string => state.automatedVerification.verificationCheckId
export const getIdentityVerificationImages = (
  state: RootState
): Omit<AutomatedVerificationImages, VerificationMediaDocumentType.SecondaryFront> =>
  omit(state.automatedVerification.images, [VerificationMediaDocumentType.SecondaryFront])

export default automatedVerificationSlice.reducer
