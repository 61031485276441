import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { PiecesList } from 'interfaces/piece.interface'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'

interface NewScansState {
  newScansCount: number
  status: RequestStatus
  error: RequestError | undefined
}

const initialState: NewScansState = {
  newScansCount: 0,
  status: RequestStatus.Pending,
  error: undefined
}

export const fetchNewScans = createAsyncThunk('newScans/fetchNewScans', async (inboxId: InboxId) => {
  const response: { data: PiecesList } = await apiClient.get(
    `/inboxes/${inboxId}/pieces?attributes=scanned,notviewed&filters=in:all&page=1&payload_type=3&per_page=25&sort_by=received_at&sort_direction=1&view_type=mailroom`
  )
  return response.data.total
})

export const newScansSlice = createSlice({
  name: 'newScans',
  initialState,
  reducers: {
    resetNewScansState: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchNewScans.fulfilled, (state, action) => {
      state.status = RequestStatus.Success
      state.newScansCount = action.payload
      state.error = undefined
    })
  }
})

export default newScansSlice.reducer

export const { resetNewScansState } = newScansSlice.actions
export const getNewScansCount = (state: RootState): number => state.dashboardApp.newScans.newScansCount
export const isNewScansReady = (state: RootState): boolean =>
  state.dashboardApp.newScans.status === RequestStatus.Success
