import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { AccountId } from 'interfaces/account.interface'
import { Statement } from 'apps/DashboardApp/interfaces/statement.interface'

interface StatementState {
  statement: Statement | null
  status: RequestStatus
  error: RequestError | undefined
}

const initialState: StatementState = {
  statement: null,
  status: RequestStatus.Pending,
  error: undefined
}

export const fetchStatement = createAsyncThunk('statements/fetchStatement', async (accountId: AccountId) => {
  const response: { data: Statement } = await apiClient.get(`accounts/${String(accountId)}/statement-components`)
  return response.data
})

export const statementSlice = createSlice({
  name: 'statements',
  initialState,
  reducers: {
    resetStatementState: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchStatement.fulfilled, (state, action) => {
      state.statement = action.payload
      state.status = RequestStatus.Success
      state.error = undefined
    })
  }
})

export default statementSlice.reducer

export const { resetStatementState } = statementSlice.actions
export const getStatement = (state: RootState): Statement | null => state.dashboardApp.statement.statement
export const isStatementsReady = (state: RootState): boolean =>
  state.dashboardApp.statement.status === RequestStatus.Success
