import { PaginatedList } from './common.interface'

export interface ShipmentItem {
  has_complete_scan: boolean
  piece_id: number
}

export interface ShipmentCustomItem {
  description: string
  declared_value_in_cents: number
  hs_tariff_code: string
}

interface Rate {
  carrier_service: string // "UPSSaver"
  carrier_type?: string // "UPS - U P S Saver"
  created_at: string // "2021-05-24T12:25:56Z"
  expired_at: string // "2021-05-27T12:25:56Z"
  id: string // "c4590f92-d4c7-47c5-ad3c-afd112b576a1"
  price_in_cents: number
  tracking_available: boolean
}

interface ReturnAddress {
  address_line_1: string //  "9450 SW Gemini Dr"
  address_line_2: string | null
  addressee: string // "Freda Lind"
  country: string // "United States"
  country_abbreviation: string // "US"
  locality: string //  "Beaverton"
  postal_code: string // "97008-7105"
  region: string //  "Oregon"
  region_abbreviation: string // "OR"
}

export interface ShippingAddress {
  address_line_1: string // "122 East Houston Street"
  address_line_2: string // ""
  addressee: string // "Scaleworks"
  company: string | null
  country: string // "United Kingdom"
  country_abbreviation: string // "GB"
  created_at: string //  "2021-04-20T08:45:46Z"
  delivery_confidence_percent: number | null
  id: number
  inbox_id: number
  is_authorized?: boolean | null
  locality: string // "San Antonio"
  phone: string //  "A123C142B465"
  postal_code: string // "78205"
  region: string //  "TX"
  region_abbreviation: string | null
  tags: string[] // ['international']
  updated_at: string // "2021-04-20T08:45:46Z"
  requires_hs_tariff_codes?: boolean
  requires_itn?: boolean
}

export interface ShippingAddressRecommendation
  extends Omit<ShippingAddress, 'id' | 'inbox_id' | 'created_at' | 'updated_at'> {}

export interface ShippingAddressWithRecommendations extends ShippingAddress {
  recommendations: ShippingAddressRecommendation[]
}

export interface Shipment {
  aes_itn: string | null
  contents_type: string // "ReturnedGoods"
  created_at: string // "2019-08-01T21:18:24Z"
  customs_description: string
  declared_value_in_cents: number | null // 0.05
  estimated_shipped_at: string // "2019-08-01T21:18:24Z"
  id: number // 16093262
  is_insured: boolean
  is_shared: boolean
  items: ShipmentItem[]
  operation_action: string // 'ship'
  operation_status: string // 'complete'
  owner_user_id: string
  rate: Rate | null
  rate_id: string | null
  return_address: ReturnAddress
  ship_after: string // "2019-08-01T21:18:24Z"
  shipping_address: ShippingAddress
  shipping_address_id: number
  shipping_comment: string | null
  signature_on_delivery: boolean
  tags: string[] // ['scanned', 'payment-detected', 'inbox', 'viewed', 'stored', 'physical-mail']
  tracking_number: string | null
  updated_at: string // '2019-08-01T21:18:24Z'
}

export interface AddressToAuthorize {
  address: ShippingAddress | ShippingAddressWithRecommendations
  recommendation: boolean | null
}

export enum ShipmentStatus {
  Pending = 'pending',
  InProgress = 'in_progress',
  Complete = 'complete',
  All = 'in_progress,pending,complete'
}

export interface ShipmentsSort {
  sortBy: string
  sortDirection: string
}

export interface ShipmentsList extends PaginatedList<Shipment> {}
