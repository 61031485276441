import { combineReducers } from 'redux'
import { AnyAction, Reducer } from '@reduxjs/toolkit'
import shipmentDrawerReducer from './shipment_drawer'
import ratesReducer from './rates'
import blackoutDatesSlice from './blackout_dates'

export type MailroomState = ReturnType<typeof combinedReducer>

export const SHIPMENT_DRAWER_RESET_APP_ACTION = 'mailRoom/shipmentDrawer/reset'

const combinedReducer = combineReducers({
  shipmentDrawer: shipmentDrawerReducer,
  rates: ratesReducer,
  blackoutDates: blackoutDatesSlice
})

const mailroomReducer: Reducer = (state: MailroomState, action: AnyAction): MailroomState => {
  // consider moving shipmentDrawer, rates, blackoutDates reducers to separate shipment_drawer store
  if (action.type === SHIPMENT_DRAWER_RESET_APP_ACTION) {
    return combinedReducer(undefined, action)
  }
  return combinedReducer(state, action)
}

export default mailroomReducer
