import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sortBy } from 'lodash'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'
import {
  AutomationDepositCheck,
  CheckDetectedIndividualRecipientSettings
} from 'interfaces/account_automation.interface'
import { DepositAccount } from 'apps/SettingsApp/interfaces/deposit_account.interface'

interface CheckDetectedIndividualRecipientSettingsResponse {
  recipient_settings: CheckDetectedIndividualRecipientSettings[]
  next_page_token: string
}

interface AccountAutomationIndividualCheckDetectedInitialState {
  individualRecipientSettings: CheckDetectedIndividualRecipientSettings[]
  individualRecipientSettingsStatus: RequestStatus
  individualRecipientSettingsInitialLoading: boolean
}

const initialState: AccountAutomationIndividualCheckDetectedInitialState = {
  individualRecipientSettings: [],
  individualRecipientSettingsStatus: RequestStatus.Pending,
  individualRecipientSettingsInitialLoading: true
}

export interface CheckDetectedIndividualRecipientSettingsRow {
  name: string
  recipient_id: string
  deposit_check?: AutomationDepositCheck
}

export const fetchIndividualRecipientSettings = createAsyncThunk(
  'individualCheckDetected/fetchIndividualRecipientSettings',
  async (inboxId: InboxId) => {
    const response: { data: CheckDetectedIndividualRecipientSettingsResponse } = await apiClient.get(
      `/inboxes/${inboxId}/automation-settings/check-detected/recipients`
    )
    if (response.data.next_page_token == null) return response.data
    const fullResponse = { ...response.data }
    let nextPageToken = response.data.next_page_token
    while (nextPageToken != null) {
      const nextPageResponse: { data: CheckDetectedIndividualRecipientSettingsResponse } = await apiClient.get(
        `/inboxes/${inboxId}/automation-settings/check-detected/recipients`,
        { params: { page_token: nextPageToken } }
      )
      nextPageToken = nextPageResponse.data.next_page_token
      fullResponse.recipient_settings = [
        ...fullResponse.recipient_settings,
        ...nextPageResponse.data.recipient_settings
      ]
    }
    return fullResponse
  }
)

export const individualCheckDetectedSlice = createSlice({
  name: 'individualCheckDetected',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchIndividualRecipientSettings.fulfilled, (state, action) => {
        state.individualRecipientSettings = action.payload.recipient_settings
        state.individualRecipientSettingsStatus = RequestStatus.Success
        state.individualRecipientSettingsInitialLoading = false
      })
      .addCase(fetchIndividualRecipientSettings.pending, state => {
        state.individualRecipientSettingsStatus = RequestStatus.Pending
      })
      .addCase(fetchIndividualRecipientSettings.rejected, state => {
        state.individualRecipientSettings = initialState.individualRecipientSettings
        state.individualRecipientSettingsStatus = RequestStatus.Error
      })
  }
})

export const getIndividualRecipientSettings =
  (id: number) =>
  (state: RootState): CheckDetectedIndividualRecipientSettings | undefined =>
    state.settingsApp.accountAutomation.individualCheckDetected.individualRecipientSettings.find(
      ({ recipient_id }) => recipient_id === String(id)
    ) // eslint-disable-line @typescript-eslint/naming-convention
export const getCheckDetectedIndividualRecipientSettingsViewReady = (state: RootState): boolean => {
  const {
    account: { recipientsListInitialLoading, checkDepositBankAccountsListInitialLoading },
    individualCheckDetected: { individualRecipientSettingsInitialLoading }
  } = state.settingsApp.accountAutomation
  return (
    !individualRecipientSettingsInitialLoading &&
    !recipientsListInitialLoading &&
    !checkDepositBankAccountsListInitialLoading
  )
}
export const getIndividualAccount =
  (accountId: string | undefined) =>
  (state: RootState): DepositAccount | undefined =>
    state.settingsApp.accountAutomation.account.checkDepositBankAccountsList.find(
      ({ id }) => accountId === id.toString()
    )
export const getRecipientSettingsList = (state: RootState): CheckDetectedIndividualRecipientSettingsRow[] => {
  const { recipientsList } = state.settingsApp.accountAutomation.account

  const recipientRow = (
    settings: CheckDetectedIndividualRecipientSettings
  ): CheckDetectedIndividualRecipientSettingsRow => {
    const recipient = recipientsList.find(({ id }) => String(id) === settings.recipient_id)
    return {
      recipient_id: settings.recipient_id,
      name: recipient?.name ?? '',
      deposit_check: settings.settings.check_detected.deposit_check
    }
  }
  const recipientSettings =
    state.settingsApp.accountAutomation.individualCheckDetected.individualRecipientSettings.map(recipientRow)
  return sortBy(recipientSettings, ['name']).filter(({ name }) => name !== '')
}

export default individualCheckDetectedSlice.reducer
