import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sortBy } from 'lodash'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'
import {
  AutomationFilter,
  DeliveredActionType,
  DeliveredIndividualRecipientSettings,
  DeliveredSettingsScan
} from 'interfaces/account_automation.interface'

interface DeliveredIndividualRecipientSettingsResponse {
  recipient_settings: DeliveredIndividualRecipientSettings[]
  next_page_token: string
}

interface AccountAutomationIndividualDeliveredInitialState {
  individualRecipientSettings: DeliveredIndividualRecipientSettings[]
  individualRecipientSettingsStatus: RequestStatus
  individualRecipientSettingsInitialLoading: boolean
}

export interface DeliveredIndividualRecipientSettingsRow {
  name: string
  recipient_id: string
  action: DeliveredActionType
  scan: DeliveredSettingsScan | null
  filters: AutomationFilter[]
}

const initialState: AccountAutomationIndividualDeliveredInitialState = {
  individualRecipientSettings: [],
  individualRecipientSettingsStatus: RequestStatus.Pending,
  individualRecipientSettingsInitialLoading: true
}

export const fetchIndividualRecipientSettings = createAsyncThunk(
  'individualDelivered/fetchIndividualRecipientSettings',
  async (inboxId: InboxId) => {
    const response: { data: DeliveredIndividualRecipientSettingsResponse } = await apiClient.get(
      `/inboxes/${inboxId}/automation-settings/delivered/recipients`
    )
    if (response.data.next_page_token == null) return response.data
    const fullResponse = { ...response.data }
    let nextPageToken = response.data.next_page_token
    while (nextPageToken != null) {
      const nextPageResponse: { data: DeliveredIndividualRecipientSettingsResponse } = await apiClient.get(
        `/inboxes/${inboxId}/automation-settings/delivered/recipients`,
        { params: { page_token: nextPageToken } }
      )
      nextPageToken = nextPageResponse.data.next_page_token
      fullResponse.recipient_settings = [
        ...fullResponse.recipient_settings,
        ...nextPageResponse.data.recipient_settings
      ]
    }
    return fullResponse
  }
)

export const individualDeliveredSlice = createSlice({
  name: 'individualDelivered',
  initialState,
  reducers: {
    clearRecipientSettings(state) {
      state.individualRecipientSettings = initialState.individualRecipientSettings
      state.individualRecipientSettingsStatus = initialState.individualRecipientSettingsStatus
      state.individualRecipientSettingsInitialLoading = initialState.individualRecipientSettingsInitialLoading
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchIndividualRecipientSettings.fulfilled, (state, action) => {
        state.individualRecipientSettings = action.payload.recipient_settings
        state.individualRecipientSettingsStatus = RequestStatus.Success
        state.individualRecipientSettingsInitialLoading = false
      })
      .addCase(fetchIndividualRecipientSettings.pending, state => {
        state.individualRecipientSettingsStatus = RequestStatus.Pending
      })
      .addCase(fetchIndividualRecipientSettings.rejected, state => {
        state.individualRecipientSettings = initialState.individualRecipientSettings
        state.individualRecipientSettingsStatus = RequestStatus.Error
      })
  }
})

export const { clearRecipientSettings } = individualDeliveredSlice.actions

export const getIndividualRecipientSettings =
  (id: number) =>
  (state: RootState): DeliveredIndividualRecipientSettings | undefined =>
    state.settingsApp.accountAutomation.individualDelivered.individualRecipientSettings.find(
      ({ recipient_id }) => recipient_id === String(id)
    ) // eslint-disable-line @typescript-eslint/naming-convention
export const getIndividualRecipientSettingsViewReady = (state: RootState): boolean => {
  const {
    account: { recipientsListInitialLoading },
    individualDelivered: { individualRecipientSettingsInitialLoading }
  } = state.settingsApp.accountAutomation
  return !individualRecipientSettingsInitialLoading && !recipientsListInitialLoading
}
export const getRecipientSettingsList = (state: RootState): DeliveredIndividualRecipientSettingsRow[] => {
  const { recipientsList } = state.settingsApp.accountAutomation.account

  const recipientRow = (settings: DeliveredIndividualRecipientSettings): DeliveredIndividualRecipientSettingsRow => {
    const recipient = recipientsList.find(({ id }) => String(id) === settings.recipient_id)
    return {
      recipient_id: settings.recipient_id,
      name: recipient?.name ?? '',
      action: settings.settings.delivered.action,
      scan: settings.settings.delivered.scan,
      filters: settings.settings.delivered.filters
    }
  }
  const recipientSettings =
    state.settingsApp.accountAutomation.individualDelivered.individualRecipientSettings.map(recipientRow)
  return sortBy(recipientSettings, ['name']).filter(({ name }) => name !== '')
}

export default individualDeliveredSlice.reducer
