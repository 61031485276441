import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { sortBy } from 'lodash'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { NewBusinessRecipientState, NewPersonalRecipientState } from 'hooks/useBusinessRecipient'
import { RecipientType } from 'interfaces/recipient.interface'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'
import { AddressesList, Address } from 'interfaces/address.interface'

interface RecipientState {
  getStartedRecipient: NewBusinessRecipientState | NewPersonalRecipientState
  addresses: AddressesState
}

interface AddressesState {
  addressesList: Address[]
  status: RequestStatus
  error: RequestError | undefined
}

export const initialState: RecipientState = {
  getStartedRecipient: {
    type: RecipientType.BUSINESS,
    business_details: {
      business_name: '',
      applicants_role: '',
      business_address: {
        address_line_1: '',
        address_line_2: '',
        city: '',
        country: '',
        state: '',
        zip_code: ''
      },
      business_address_id: '',
      business_phone: '',
      business_type: '',
      is_registered_business: true,
      registration_details: {
        country: 'US',
        state: '',
        county: ''
      },
      members: []
    }
  },
  addresses: {
    addressesList: [],
    status: RequestStatus.Pending,
    error: undefined
  }
}

export const fetchInboxAddresses = createAsyncThunk('recipient/fetchInboxAddresses', async (inboxId: InboxId) => {
  const response: { data: AddressesList } = await apiClient.get(`inboxes/${inboxId}/addresses`)
  return response.data.data
})

export const recipientSlice = createSlice({
  name: 'recipient',
  initialState,
  reducers: {
    clearGetStartedRecipient(state) {
      state.getStartedRecipient = initialState.getStartedRecipient
    },
    setGetStartedRecipient(state, action) {
      state.getStartedRecipient = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchInboxAddresses.fulfilled, (state, action) => {
        state.addresses.status = RequestStatus.Success
        state.addresses.addressesList = action.payload
        state.addresses.error = undefined
      })
      .addCase(fetchInboxAddresses.pending, state => {
        state.addresses.status = RequestStatus.Pending
      })
      .addCase(fetchInboxAddresses.rejected, (state, action) => {
        state.addresses.status = RequestStatus.Error
        state.addresses.addressesList = []
        state.addresses.error = action.error.message
      })
  }
})

export const { clearGetStartedRecipient, setGetStartedRecipient } = recipientSlice.actions

export const getGetStartedRecipient = (state: RootState): NewPersonalRecipientState | NewBusinessRecipientState =>
  state.getStartedApp.recipient.getStartedRecipient
export const getRecentAddresses = (state: RootState): Address[] =>
  sortBy(
    state.getStartedApp.recipient.addresses.addressesList.filter((address: Address) => address.deleted_at === null),
    ['locality']
  )

export default recipientSlice.reducer
