import { combineReducers } from 'redux'
import { AnyAction, Reducer } from '@reduxjs/toolkit'
import recipientSlice from './recipient'
import accountAutomationSlice from './account_automation'

export type GetStartedState = ReturnType<typeof combinedReducer>

export const GET_STARTED_RESET_APP_ACTION = 'getStarted/reset'

const combinedReducer = combineReducers({
  recipient: recipientSlice,
  accountAutomation: accountAutomationSlice
})

const getStartedReducer: Reducer = (state: GetStartedState, action: AnyAction): GetStartedState => {
  if (action.type === GET_STARTED_RESET_APP_ACTION) {
    return combinedReducer(undefined, action)
  }
  return combinedReducer(state, action)
}

export default getStartedReducer
