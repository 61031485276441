import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { PaginatedList, RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'
import { AccountId } from 'interfaces/account.interface'
import { Address, AvailableAddress } from 'interfaces/address.interface'
import { AddressesBillingInfo } from 'apps/SettingsApp/interfaces/addresses_billing_info.interface'

const PER_PAGE = 100

export interface AddressesList extends PaginatedList<Address> {}

export interface MyAddressesState {
  addressesList: AddressesList
  addressesListStatus: RequestStatus
  addressesListError: RequestError | undefined
  addressesListInitialLoading: boolean
  availableAddressesList: AvailableAddress[]
  availableAddressesListStatus: RequestStatus
  availableAddressesListError: RequestError | undefined
  availableAddressesListInitialLoading: boolean
  addressesBillingInfo: AddressesBillingInfo
  addressesBillingInfoStatus: RequestStatus
  addressesBillingInfoError: RequestError | undefined
}

const initialState: MyAddressesState = {
  addressesList: {
    current_page: 1,
    data: [],
    from: 0,
    last_page: 0,
    per_page: PER_PAGE,
    to: 0,
    total: 0
  },
  addressesListStatus: RequestStatus.Pending,
  addressesListError: undefined,
  addressesListInitialLoading: true,
  availableAddressesList: [],
  availableAddressesListStatus: RequestStatus.Pending,
  availableAddressesListError: undefined,
  availableAddressesListInitialLoading: true,
  addressesBillingInfo: {
    can_add_new_address: false,
    current_plan_included_addresses: 0,
    extra_billing_unit_price: 0
  },
  addressesBillingInfoStatus: RequestStatus.Pending,
  addressesBillingInfoError: undefined
}

export const fetchAddresses = createAsyncThunk('myAddresses/fetchAddresses', async (inboxId: InboxId) => {
  const response: { data: AddressesList } = await apiClient.get(`/inboxes/${inboxId}/addresses`)
  return response.data
})

export const fetchAvailableAddresses = createAsyncThunk(
  'myAddresses/fetchAvailableAddresses',
  async (inboxId: InboxId) => {
    const response: { data: { addresses: AvailableAddress[] } } = await apiClient.get(
      `/inboxes/${inboxId}/available-addresses`
    )
    return response.data.addresses
  }
)

export const fetchAddressesBillingInfo = createAsyncThunk(
  'myAddresses/fetchAddressesBillingInfo',
  async (accountId: AccountId) => {
    const response: { data: AddressesBillingInfo } = await apiClient.get(
      `/accounts/${accountId}/addresses-billing-info`
    )
    return response.data
  }
)

export const myAddressesSlice = createSlice({
  name: 'myAddresses',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAddresses.fulfilled, (state, action) => {
        state.addressesList = action.payload
        state.addressesListStatus = RequestStatus.Success
        state.addressesListInitialLoading = false
      })
      .addCase(fetchAddresses.pending, state => {
        state.addressesListStatus = RequestStatus.Pending
      })
      .addCase(fetchAddresses.rejected, (state, action) => {
        state.addressesList = initialState.addressesList
        state.addressesListStatus = RequestStatus.Error
        state.addressesListError = action.error.message
      })
      .addCase(fetchAvailableAddresses.fulfilled, (state, action) => {
        state.availableAddressesList = action.payload
        state.availableAddressesListStatus = RequestStatus.Success
        state.availableAddressesListInitialLoading = false
      })
      .addCase(fetchAvailableAddresses.pending, state => {
        state.availableAddressesListStatus = RequestStatus.Pending
      })
      .addCase(fetchAvailableAddresses.rejected, (state, action) => {
        state.availableAddressesList = initialState.availableAddressesList
        state.availableAddressesListStatus = RequestStatus.Error
        state.availableAddressesListError = action.error.message
      })
      .addCase(fetchAddressesBillingInfo.fulfilled, (state, action) => {
        state.addressesBillingInfo = action.payload
        state.addressesBillingInfoStatus = RequestStatus.Success
      })
      .addCase(fetchAddressesBillingInfo.pending, state => {
        state.addressesBillingInfoStatus = RequestStatus.Pending
      })
      .addCase(fetchAddressesBillingInfo.rejected, (state, action) => {
        state.addressesBillingInfo = initialState.addressesBillingInfo
        state.addressesBillingInfoStatus = RequestStatus.Error
        state.addressesBillingInfoError = action.error.message
      })
  }
})

export const getMyAddressesList = (state: RootState): Address[] => state.settingsApp.myAddresses.addressesList.data
export const getMyAddressesReady = (state: RootState): boolean =>
  !state.settingsApp.myAddresses.addressesListInitialLoading
export const getAvailableAddressesList = (state: RootState): AvailableAddress[] =>
  state.settingsApp.myAddresses.availableAddressesList
export const getAvailableAddressesListReady = (state: RootState): boolean =>
  !state.settingsApp.myAddresses.availableAddressesListInitialLoading
export const getCanAddNewAddress = (state: RootState): boolean =>
  state.settingsApp.myAddresses.addressesBillingInfo.can_add_new_address
export const getAddressBillingInfoReady = (state: RootState): boolean =>
  state.settingsApp.myAddresses.addressesBillingInfoStatus === RequestStatus.Success

export default myAddressesSlice.reducer
