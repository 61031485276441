export enum DeliveredSettingsScanValue {
  BiTonal300DPI = 'BiTonal300DPI',
  Color200DPI = 'Color200DPI'
}

export enum MailTypesFilter {
  Card = 'Card',
  Letter = 'Letter',
  LetterWithCheck = 'LetterWithCheck',
  Flat = 'Flat',
  Parcel = 'Parcel',
  OversizeParcel = 'OversizeParcel',
  Other = 'Other',
  DepositSummary = 'DepositSummary',
  Email = 'Email',
  File = 'File'
}

export enum MailClassesFilter {
  FirstClass = 'FirstClass',
  SecondClass = 'SecondClass',
  MediaClass = 'MediaClass',
  PriorityMail = 'PriorityMail',
  ExpressMail = 'ExpressMail',
  ParcelPost = 'ParcelPost',
  StandardMail = 'StandardMail',
  BoundPrinted = 'BoundPrinted',
  Periodical = 'Periodical',
  NonProfit = 'NonProfit',
  Other = 'Other'
}

export interface AutomationFilter {
  min_pages_count?: number
  max_pages_count?: number
  mail_types?: MailTypesFilter[]
  mail_classes?: MailClassesFilter[]
}

export interface DeliveredSettingsScan {
  scan_type: DeliveredSettingsScanValue
}

export enum DeliveredActionType {
  Scan = 'scan',
  Shred = 'shred',
  None = 'none'
}

export interface AutomationDeliveredEvent {
  action: DeliveredActionType
  scan: DeliveredSettingsScan | null
  filters: AutomationFilter[]
  recipient_ids: string[]
}

export interface AutomationIntegrationId {
  id: string
}

export enum AutomationScannedEventAction {
  Shred = 'shred',
  KeepItem = 'keepItem'
}

export interface AutomationScannedEvent {
  action: AutomationScannedEventAction
  integrations: AutomationIntegrationId[]
  filters: AutomationFilter[]
  recipient_ids: string[]
}

export interface AutomationDepositCheck {
  account_id: string
  is_expedited: boolean
  shred_after_deposited: boolean
}

export interface AutomationCheckedDetectedEvent {
  deposit_check?: AutomationDepositCheck
  recipient_ids: string[]
}

export interface AccountAutomationSettings {
  delivered: AutomationDeliveredEvent
  scanned: AutomationScannedEvent
  check_detected: AutomationCheckedDetectedEvent
}

export interface DeliveredIndividualRecipientSettings {
  recipient_id: string
  settings: {
    delivered: Omit<AutomationDeliveredEvent, 'recipient_ids'>
  }
}

export interface ScannedIndividualRecipientSettings {
  recipient_id: string
  settings: {
    scanned: Omit<AutomationScannedEvent, 'recipient_ids'>
  }
}

export interface CheckDetectedIndividualRecipientSettings {
  recipient_id: string
  settings: {
    check_detected: Omit<AutomationCheckedDetectedEvent, 'recipient_ids'>
  }
}

export interface AccountAutomationConnection {
  id: number
  name: string
  user_email: string
  provider: {
    service: string
    name: string
    display_name: string
    id: number
  }
}

export interface ScannedIndividualRecipientSettingsResponse {
  recipient_settings: ScannedIndividualRecipientSettings[]
  next_page_token: string
}

export interface DeliveredSettingsToEdit extends Omit<AutomationDeliveredEvent, 'recipient_ids'> {}
export interface ScannedSettingsToEdit extends Omit<AutomationScannedEvent, 'recipient_ids'> {}
export interface CheckDetectedSettingsToEdit extends Omit<AutomationCheckedDetectedEvent, 'recipient_ids'> {}
