import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RecipientsList, Recipient } from 'interfaces/recipient.interface'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'

interface RecipientsState {
  recipients: Recipient[]
  status: RequestStatus
  error: RequestError | undefined
}

const initialState: RecipientsState = {
  recipients: [],
  status: RequestStatus.Pending,
  error: undefined
}

export const fetchRecipients = createAsyncThunk('inboxes/fetchRecipients', async (inboxId: InboxId) => {
  const response: { data: RecipientsList } = await apiClient.get(`inboxes/${inboxId}/recipients`)
  return response.data.data
})

export const recipientsSlice = createSlice({
  name: 'recipients',
  initialState,
  reducers: {
    resetRecipientsState: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchRecipients.fulfilled, (state, action) => {
      state.status = RequestStatus.Success
      state.recipients = action.payload
      state.error = undefined
    })
  }
})

export default recipientsSlice.reducer

export const { resetRecipientsState } = recipientsSlice.actions
export const getRecentRecipients = (state: RootState): Recipient[] => state.dashboardApp.recipients.recipients
export const getRecipientsWithTypeRecipient = (state: RootState): Recipient[] =>
  state.dashboardApp.recipients.recipients.filter((recpient: Recipient) => recpient.type === 'recipient')
export const isRecipientsReady = (state: RootState): boolean =>
  state.dashboardApp.recipients.status === RequestStatus.Success
