import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosInstance } from 'axios'
import { compact } from 'lodash'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'
import {
  AutomationScannedEvent,
  ScannedIndividualRecipientSettings,
  ScannedIndividualRecipientSettingsResponse
} from 'interfaces/account_automation.interface'
import { Integration, IntegrationsList } from 'apps/SettingsApp/interfaces/integrations.interface'
import { RulesWithRecipient, RulesWithRecipientList } from 'apps/SettingsApp/interfaces/rules.interface'
import { DecoratedIntegration, decorateIntegration } from 'apps/SettingsApp/utils/integrations'

interface IntegrationsState {
  integrationsList: Integration[]
  integrationsStatus: RequestStatus
  integrationsError: RequestError | undefined
  rulesWithRecipientList: RulesWithRecipient[]
  rulesWithRecipientStatus: RequestStatus
  rulesWithRecipientError: RequestError | undefined
  accountAutomationScannedSettings: { scanned: AutomationScannedEvent } | null
  accountAutomationScannedSettingsStatus: RequestStatus
  accountAutomationScannedSettingsError: RequestError | undefined
  individualAutomationScannedSettings: ScannedIndividualRecipientSettings[]
  individualAutomationScannedSettingsStatus: RequestStatus
  individualAutomationScannedSettingsError: RequestError | undefined
}

const initialState: IntegrationsState = {
  integrationsList: [],
  integrationsStatus: RequestStatus.Pending,
  integrationsError: undefined,
  rulesWithRecipientList: [],
  rulesWithRecipientStatus: RequestStatus.Pending,
  rulesWithRecipientError: undefined,
  accountAutomationScannedSettings: null,
  accountAutomationScannedSettingsStatus: RequestStatus.Pending,
  accountAutomationScannedSettingsError: undefined,
  individualAutomationScannedSettings: [],
  individualAutomationScannedSettingsStatus: RequestStatus.Pending,
  individualAutomationScannedSettingsError: undefined
}

export const fetchIntegrations = createAsyncThunk(
  'integrations/fetchIntegrations',
  async (connectorApiClient: AxiosInstance) => {
    const response: { data: IntegrationsList } = await connectorApiClient.get('connector_providers')
    return response.data
  }
)

export const fetchRulesWithRecipients = createAsyncThunk(
  'integrations/fetchRulesWithRecipients',
  async (inboxId: InboxId) => {
    const response: { data: RulesWithRecipientList } = await apiClient.get(`/inboxes/${inboxId}/rules`)
    return response.data
  }
)

export const fetchAccountAutomationScannedSettings = createAsyncThunk(
  'integrations/fetchAccountAutomationScannedSettings',
  async (inboxId: InboxId) => {
    const response: { data: { scanned: AutomationScannedEvent } } = await apiClient.get(
      `/inboxes/${inboxId}/automation-settings/scanned`
    )
    return response.data
  }
)

export const fetchIndividualAutomationScannedSettings = createAsyncThunk(
  'integrations/fetchIndividualAutomationScannedSettings',
  async (inboxId: InboxId) => {
    const response: { data: ScannedIndividualRecipientSettingsResponse } = await apiClient.get(
      `/inboxes/${inboxId}/automation-settings/scanned/recipients`
    )
    if (response.data.next_page_token == null) return response.data
    const fullResponse = { ...response.data }
    let nextPageToken = response.data.next_page_token
    while (nextPageToken != null) {
      const nextPageResponse: { data: ScannedIndividualRecipientSettingsResponse } = await apiClient.get(
        `/inboxes/${inboxId}/automation-settings/scanned/recipients`,
        { params: { page_token: nextPageToken } }
      )
      nextPageToken = nextPageResponse.data.next_page_token
      fullResponse.recipient_settings = [
        ...fullResponse.recipient_settings,
        ...nextPageResponse.data.recipient_settings
      ]
    }
    return fullResponse
  }
)

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchIntegrations.fulfilled, (state, action) => {
        state.integrationsList = action.payload.data
        state.integrationsStatus = RequestStatus.Success
      })
      .addCase(fetchIntegrations.rejected, (state, action) => {
        state.integrationsList = []
        state.integrationsStatus = RequestStatus.Error
        state.integrationsError = action.error.message
      })
      .addCase(fetchRulesWithRecipients.pending, state => {
        state.rulesWithRecipientStatus = RequestStatus.Pending
      })
      .addCase(fetchRulesWithRecipients.fulfilled, (state, action) => {
        state.rulesWithRecipientList = action.payload.data
        state.rulesWithRecipientStatus = RequestStatus.Success
      })
      .addCase(fetchRulesWithRecipients.rejected, (state, action) => {
        state.rulesWithRecipientList = []
        state.rulesWithRecipientStatus = RequestStatus.Error
        state.rulesWithRecipientError = action.error.message
      })
      .addCase(fetchAccountAutomationScannedSettings.fulfilled, (state, action) => {
        state.accountAutomationScannedSettings = action.payload
        state.accountAutomationScannedSettingsStatus = RequestStatus.Success
      })
      .addCase(fetchAccountAutomationScannedSettings.pending, state => {
        state.accountAutomationScannedSettingsStatus = RequestStatus.Pending
      })
      .addCase(fetchAccountAutomationScannedSettings.rejected, (state, action) => {
        state.accountAutomationScannedSettings = null
        state.accountAutomationScannedSettingsStatus = RequestStatus.Error
        state.accountAutomationScannedSettingsError = action.error.message
      })
      .addCase(fetchIndividualAutomationScannedSettings.fulfilled, (state, action) => {
        state.individualAutomationScannedSettings = action.payload.recipient_settings
        state.individualAutomationScannedSettingsStatus = RequestStatus.Success
      })
      .addCase(fetchIndividualAutomationScannedSettings.pending, state => {
        state.individualAutomationScannedSettingsStatus = RequestStatus.Pending
      })
      .addCase(fetchIndividualAutomationScannedSettings.rejected, (state, action) => {
        state.individualAutomationScannedSettings = []
        state.individualAutomationScannedSettingsStatus = RequestStatus.Error
        state.individualAutomationScannedSettingsError = action.error.message
      })
  }
})

export const getIntegrationsListStatus = (state: RootState): RequestStatus =>
  state.settingsApp.integrations.integrationsStatus
export const getDecoratedCurrentIntegration =
  (integrationName: string) =>
  (state: RootState): DecoratedIntegration | null => {
    const integration = state.settingsApp.integrations.integrationsList.find(
      (integration: Integration) => integration.name === integrationName
    )
    if (integration == null) return null // because of differences with providers list on Dev and production
    return decorateIntegration(integration)
  }
export const getRulesForConnectionStatus = (state: RootState): RequestStatus =>
  state.settingsApp.integrations.rulesWithRecipientStatus
export const getRulesForConnection =
  (connectionId: number | undefined) =>
  (state: RootState): RulesWithRecipient[] => {
    if (connectionId == null || state.settingsApp.integrations.rulesWithRecipientList.length === 0) return []
    const rulesWithConnection = state.settingsApp.integrations.rulesWithRecipientList.map(({ recipient, rules }) => {
      if (rules.length === 0) return { recipient, rules: [] }
      const rulesForConnection = rules.filter(({ actions }) => {
        if (actions.length === 0) return false
        return actions.find(({ settings }) => {
          return settings.find(({ name, value }) => name === 'CloudStorageAccountID' && Number(value) === connectionId)
        })
      })
      return {
        recipient,
        rules: rulesForConnection
      }
    })
    return rulesWithConnection
  }
export const getAccountAutomationScannedSettingsStatus = (state: RootState): RequestStatus =>
  state.settingsApp.integrations.accountAutomationScannedSettingsStatus
export const getIndividualAutomationScannedSettingsStatus = (state: RootState): RequestStatus =>
  state.settingsApp.integrations.individualAutomationScannedSettingsStatus
export const getAccountAutomationForConnectionCount =
  (connectionId: number | undefined) =>
  (state: RootState): number => {
    if (connectionId == null) return 0
    const accountAutomationForConnection =
      state.settingsApp.integrations.accountAutomationScannedSettings?.scanned.integrations.find(
        ({ id }) => id === String(connectionId)
      )
    const individualAutomationForConnection = state.settingsApp.integrations.individualAutomationScannedSettings.filter(
      ({
        settings: {
          scanned: { integrations }
        }
      }) => integrations.find(({ id }) => id === String(connectionId))
    )
    return compact([accountAutomationForConnection, ...individualAutomationForConnection]).length
  }

export default integrationsSlice.reducer
