import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import { getCurrentUser } from 'store/current_user'
import { getCurrentInbox } from 'store/inboxes'
import useAmplitudeService from 'hooks/useAmplitudeService'
import { ZendeskWidget, ZendeskSettings } from 'interfaces/zendesk.interface'

declare global {
  interface Window {
    zE: ZendeskWidget
    zESettings: ZendeskSettings
  }
}

interface ZendeskServiceInterface {
  initZendesk: () => void
  openZendesk: () => void
  hideZendesk: () => void
}

const useZendeskService = (): ZendeskServiceInterface => {
  const currentUser = useSelector(getCurrentUser)
  const currentInbox = useSelector(getCurrentInbox)
  const { logEvent } = useAmplitudeService()
  const {
    palette: {
      lzNegroni: { brand, neutral2 }
    }
  } = useTheme()

  const initZendesk = (): void => {
    if (currentUser == null || currentInbox == null) return

    const ecmNumber = currentInbox.account.ecm_numbers[0]
    const webWidgetSettings: ZendeskSettings = {
      webWidget: {
        position: {
          horizontal: 'right',
          vertical: 'top'
        },
        offset: {
          horizontal: '-16px',
          vertical: '-10px',
          mobile: {
            horizontal: '-16px',
            vertical: '-12px'
          }
        },
        contactForm: {
          fields: [
            {
              id: '360000289407',
              prefill: { '*': ecmNumber }
            },
            {
              id: '360020262434',
              prefill: { '*': currentUser.username }
            }
          ]
        },
        color: {
          button: neutral2[900],
          resultLists: brand[700],
          header: neutral2[50]
        }
      }
    }
    window.zESettings = webWidgetSettings
    const script = document.createElement('script')
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=b1c474ee-4c56-417b-9a57-5b64bb66099c'
    script.id = 'ze-snippet'
    document.body.appendChild(script)
    script.addEventListener('load', () => {
      window.zE('webWidget', 'hide')

      window.zE('webWidget', 'prefill', {
        name: {
          value: currentUser.first_name,
          readOnly: false
        },
        email: {
          value: currentUser.email,
          readOnly: false
        }
      })

      window.zE('webWidget:on', 'open', () => {
        window.zE('webWidget', 'show')
        logEvent('Zendesk - Widget Opened')
      })

      window.zE('webWidget:on', 'close', () => {
        window.zE('webWidget', 'hide')
        logEvent('Zendesk - Widget Closed')
      })
    })
  }

  const openZendesk = (): void => {
    if (window.zE == null) return
    window.zE('webWidget', 'open')
  }

  const hideZendesk = (): void => {
    if (window.zE == null) return
    window.zE('webWidget', 'hide')
  }

  return {
    initZendesk,
    openZendesk,
    hideZendesk
  }
}

export default useZendeskService
