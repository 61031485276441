import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { sortBy } from 'lodash'
import { AxiosError } from 'axios'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { NewRecipientBusinessState } from 'hooks/useBusinessRecipient'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import {
  IndividualRecipient,
  BusinessRecipient,
  Recipients1583List,
  BusinessRecipientDetails,
  IndividualRecipientDetails,
  RecipientType,
  CurrentIndividualRecipient,
  CurrentBusinessRecipient,
  RecipientAuthorization
} from 'interfaces/recipient.interface'
import { AccountId } from 'interfaces/account.interface'
import { InboxId } from 'interfaces/inbox.interface'
import { Address, AddressesList } from 'interfaces/address.interface'
import { User } from 'interfaces/user.interface'

interface IndividualRecipientToConfirm extends Omit<IndividualRecipient, 'id'> {
  id?: string
  authorization?: RecipientAuthorization
}

interface BusinessRecipientToConfirm extends Omit<BusinessRecipient, 'id'> {
  id?: string
  authorization?: RecipientAuthorization
}

interface RemoveRecipientArgs {
  accountId: AccountId
  recipientId: string
  preview: boolean
  signature?: string
}

interface RecipientsState {
  recipientToConfirm: IndividualRecipientToConfirm | BusinessRecipientToConfirm | null
  recipientToConfirmStatus: RequestStatus
  recipientToConfirmError: RequestError | undefined
  recipientsList: Array<IndividualRecipient | BusinessRecipient>
  recipientsListStatus: RequestStatus
  recipientsListError: RequestError | undefined
  recipientsListInitialLoading: boolean
  currentRecipient: CurrentIndividualRecipient | CurrentBusinessRecipient | NewRecipientBusinessState | null
  currentRecipientStatus: RequestStatus
  currentRecipientError: RequestError | undefined
  currentRecipientInitialLoading: boolean
  searchedRecipientMatchPhrase: string
  inboxAddresses: Address[]
  inboxAddressesStatus: RequestStatus
  inboxAddressesError: RequestError | undefined
  usersList: User[]
  usersListStatus: RequestStatus
  usersListError: RequestError | undefined
}

export interface AddRecipientsBusinessDetailsArgs extends Omit<BusinessRecipientDetails, 'members'> {
  members: Array<{
    name: string
    id?: string
  }>
}
export interface AddRecipientsPersonalDetailsArgs extends Omit<IndividualRecipientDetails, 'minors'> {
  minors: Array<{
    name: string
    date_of_birth: string
    id?: string
  }>
}
export interface AddRecipientsRequestBody {
  name: string
  type: RecipientType
  business_details?: AddRecipientsBusinessDetailsArgs
  personal_details?: AddRecipientsPersonalDetailsArgs
}

interface AddRecipientsArgs {
  accountId: AccountId
  recipientRequestBody: AddRecipientsRequestBody
  preview: boolean
  signature?: string
}

interface UpdateRecipientsArgs extends AddRecipientsArgs {
  recipientId: string
}

interface FetchRecipientArgs {
  accountId: AccountId
  recipientId: string
}

const initialState: RecipientsState = {
  recipientToConfirm: null,
  recipientToConfirmStatus: RequestStatus.Pending,
  recipientToConfirmError: undefined,
  recipientsList: [],
  recipientsListStatus: RequestStatus.Pending,
  recipientsListError: undefined,
  recipientsListInitialLoading: true,
  currentRecipient: null,
  currentRecipientStatus: RequestStatus.Pending,
  currentRecipientError: undefined,
  currentRecipientInitialLoading: true,
  searchedRecipientMatchPhrase: '',
  inboxAddresses: [],
  inboxAddressesStatus: RequestStatus.Pending,
  inboxAddressesError: undefined,
  usersList: [],
  usersListStatus: RequestStatus.Pending,
  usersListError: undefined
}

export const fetchRecipientsList = createAsyncThunk(
  'recipients1583/fetchRecipientsList',
  async (accountId: AccountId) => {
    const response: { data: Recipients1583List } = await apiClient.get(
      `mail-authorization/accounts/${String(accountId)}/recipients`
    )
    if (response.data.next_page_token === '') return response.data
    const fullResponse = { ...response.data }
    let nextPageToken: string = response.data.next_page_token

    while (nextPageToken !== '') {
      const nextPageResponse: { data: Recipients1583List } = await apiClient.get(
        `mail-authorization/accounts/${String(accountId)}/recipients?page_token=${nextPageToken}`
      )
      fullResponse.recipients = [...fullResponse.recipients, ...nextPageResponse.data.recipients]
      nextPageToken = nextPageResponse.data.next_page_token
    }
    return fullResponse
  }
)

export const fetchRecipient = createAsyncThunk(
  'recipients1583/fetchRecipient',
  async ({ accountId, recipientId }: FetchRecipientArgs) => {
    const response: { data: CurrentIndividualRecipient | CurrentBusinessRecipient } = await apiClient.get(
      `mail-authorization/accounts/${String(accountId)}/recipients/${recipientId}`
    )
    return response.data
  }
)

export const addRecipient = createAsyncThunk(
  'recipients1583/addRecipient',
  async ({ accountId, recipientRequestBody, preview, signature }: AddRecipientsArgs, { rejectWithValue }) => {
    try {
      const response: { data: IndividualRecipientToConfirm | BusinessRecipientToConfirm } = await apiClient.post(
        `mail-authorization/accounts/${String(accountId)}/recipients`,
        { ...recipientRequestBody },
        {
          params: {
            preview,
            signature
          }
        }
      )
      return response.data
    } catch (e: any) {
      const error: AxiosError = { ...e }
      if (error.response == null) {
        throw e
      }
      return rejectWithValue(error.response?.data)
    }
  }
)

export const updateRecipient = createAsyncThunk(
  'recipients1583/updateRecipient',
  async (
    { accountId, recipientRequestBody, preview, recipientId, signature }: UpdateRecipientsArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: { data: IndividualRecipientToConfirm | BusinessRecipientToConfirm } = await apiClient.post(
        `mail-authorization/accounts/${String(accountId)}/recipients/${recipientId}`,
        { ...recipientRequestBody },
        {
          params: {
            preview,
            signature
          }
        }
      )
      return response.data
    } catch (e: any) {
      const error: AxiosError = { ...e }
      if (error.response == null) {
        throw e
      }
      return rejectWithValue(error.response?.data)
    }
  }
)

export const removeRecipient = createAsyncThunk(
  'recipients1583/removeRecipient',
  async ({ accountId, preview, signature, recipientId }: RemoveRecipientArgs) => {
    const response = await apiClient.delete(
      `mail-authorization/accounts/${String(accountId)}/recipients/${recipientId}`,
      { params: { preview, signature } }
    )
    return response.data
  }
)

export const fetchInboxAddresses = createAsyncThunk('recipients1583/fetchInboxAddresses', async (inboxId: InboxId) => {
  const response: { data: AddressesList } = await apiClient.get(`inboxes/${inboxId}/addresses`)
  return response.data.data
})

export const fetchUsersList = createAsyncThunk('recipients1583/fetchUsersList', async (accountId: AccountId) => {
  const response: { data: { data: [{ user: User }] } } = await apiClient.get(`accounts/${accountId}/users`, {})
  const flattenedResponse = response.data.data.map(({ user }: { user: User }) => user)
  return flattenedResponse
})

export const recipientsSlice = createSlice({
  name: 'recipients1583',
  initialState,
  reducers: {
    setSearchedRecipientMatchPhrase(state, action: PayloadAction<string>) {
      state.searchedRecipientMatchPhrase = action.payload
    },
    clearRecipientToConfirm(state) {
      state.recipientToConfirm = initialState.recipientToConfirm
    },
    clearCurrentRecipient(state) {
      state.currentRecipient = initialState.currentRecipient
      state.currentRecipientInitialLoading = initialState.currentRecipientInitialLoading
      state.currentRecipientStatus = initialState.currentRecipientStatus
    },
    clearRecipientsList(state) {
      state.recipientsList = initialState.recipientsList
      state.recipientsListInitialLoading = initialState.recipientsListInitialLoading
      state.recipientsListStatus = initialState.recipientsListStatus
    }
  },
  extraReducers: builder => {
    builder
      .addCase(addRecipient.fulfilled, (state, action) => {
        state.recipientToConfirmStatus = RequestStatus.Success
        state.recipientToConfirm = action.payload
        state.recipientToConfirmError = undefined
      })
      .addCase(addRecipient.pending, state => {
        state.recipientToConfirmStatus = RequestStatus.Pending
      })
      .addCase(addRecipient.rejected, (state, action) => {
        state.recipientToConfirmStatus = RequestStatus.Error
        state.recipientToConfirm = initialState.recipientToConfirm
        state.recipientToConfirmError = action.error.message
      })
      .addCase(updateRecipient.fulfilled, (state, action) => {
        state.recipientToConfirmStatus = RequestStatus.Success
        state.recipientToConfirm = action.payload
        state.recipientToConfirmError = undefined
      })
      .addCase(updateRecipient.pending, state => {
        state.recipientToConfirmStatus = RequestStatus.Pending
      })
      .addCase(updateRecipient.rejected, (state, action) => {
        state.recipientToConfirmStatus = RequestStatus.Error
        state.recipientToConfirm = initialState.recipientToConfirm
        state.recipientToConfirmError = action.error.message
      })
      .addCase(fetchRecipientsList.fulfilled, (state, action) => {
        state.recipientsListStatus = RequestStatus.Success
        state.recipientsListInitialLoading = false
        state.recipientsList = action.payload.recipients
        state.recipientsListError = undefined
      })
      .addCase(fetchRecipientsList.pending, state => {
        state.recipientsListStatus = RequestStatus.Pending
      })
      .addCase(fetchRecipientsList.rejected, (state, action) => {
        state.recipientsListStatus = RequestStatus.Error
        state.recipientsList = []
        state.recipientsListError = action.error.message
      })
      .addCase(fetchRecipient.fulfilled, (state, action) => {
        state.currentRecipient = action.payload
        state.currentRecipientInitialLoading = false
        state.currentRecipientStatus = RequestStatus.Success
      })
      .addCase(fetchRecipient.pending, state => {
        state.currentRecipientStatus = RequestStatus.Pending
      })
      .addCase(fetchRecipient.rejected, state => {
        state.currentRecipient = initialState.currentRecipient
        state.currentRecipientStatus = RequestStatus.Error
      })
      .addCase(removeRecipient.fulfilled, (state, action) => {
        state.recipientToConfirmStatus = RequestStatus.Success
        state.recipientToConfirm = action.payload
        state.recipientToConfirmError = undefined
      })
      .addCase(removeRecipient.pending, state => {
        state.recipientToConfirmStatus = RequestStatus.Pending
      })
      .addCase(removeRecipient.rejected, (state, action) => {
        state.recipientToConfirmStatus = RequestStatus.Error
        state.recipientToConfirm = initialState.recipientToConfirm
        state.recipientToConfirmError = action.error.message
      })
      .addCase(fetchInboxAddresses.fulfilled, (state, action) => {
        state.inboxAddressesStatus = RequestStatus.Success
        state.inboxAddresses = action.payload
        state.inboxAddressesError = undefined
      })
      .addCase(fetchInboxAddresses.pending, state => {
        state.inboxAddressesStatus = RequestStatus.Pending
      })
      .addCase(fetchInboxAddresses.rejected, (state, action) => {
        state.inboxAddressesStatus = RequestStatus.Error
        state.inboxAddresses = initialState.inboxAddresses
        state.inboxAddressesError = action.error.message
      })
      .addCase(fetchUsersList.fulfilled, (state, action) => {
        state.usersListStatus = RequestStatus.Success
        state.usersList = action.payload
        state.usersListError = undefined
      })
      .addCase(fetchUsersList.pending, state => {
        state.usersListStatus = RequestStatus.Pending
      })
      .addCase(fetchUsersList.rejected, (state, action) => {
        state.usersListStatus = RequestStatus.Error
        state.usersList = initialState.usersList
        state.usersListError = action.error.message
      })
  }
})

export const { setSearchedRecipientMatchPhrase, clearRecipientToConfirm, clearCurrentRecipient, clearRecipientsList } =
  recipientsSlice.actions

export const getRecipientToConfirm = (
  state: RootState
): IndividualRecipientToConfirm | BusinessRecipientToConfirm | null =>
  state.settingsApp.recipients1583.recipientToConfirm
export const getRecipientToConfirmReady = (state: RootState): boolean =>
  state.settingsApp.recipients1583.recipientToConfirmStatus === RequestStatus.Success
export const getIsRecipientsListInitialLoading = (state: RootState): boolean =>
  state.settingsApp.recipients1583.recipientsListInitialLoading
export const getSearchedRecipientMatchPhrase = (state: RootState): string =>
  state.settingsApp.recipients1583.searchedRecipientMatchPhrase
export const getRecipientsList = (state: RootState): Array<IndividualRecipient | BusinessRecipient> =>
  state.settingsApp.recipients1583.recipientsList
export const getFilteredRecipientsList = (state: RootState): Array<IndividualRecipient | BusinessRecipient> =>
  state.settingsApp.recipients1583.recipientsList.filter(({ name }) =>
    name.toLowerCase().includes(getSearchedRecipientMatchPhrase(state).toLowerCase())
  )
export const getCurrentRecipient = (
  state: RootState
): CurrentIndividualRecipient | CurrentBusinessRecipient | NewRecipientBusinessState | null =>
  state.settingsApp.recipients1583.currentRecipient
export const getCurrentRecipientReady = (state: RootState): boolean =>
  state.settingsApp.recipients1583.currentRecipientStatus === RequestStatus.Success
export const getIsCurrentRecipientInitialLoading = (state: RootState): boolean =>
  state.settingsApp.recipients1583.currentRecipientInitialLoading
export const getRecentAddresses = (state: RootState): Address[] =>
  sortBy(
    state.settingsApp.recipients1583.inboxAddresses.filter((address: Address) => address.deleted_at === null),
    ['locality']
  )
export const getAdminUsersList = (state: RootState): User[] =>
  state.settingsApp.recipients1583.usersList.filter(user => user.is_admin)
export const getAdminUsersListReady = (state: RootState): boolean =>
  state.settingsApp.recipients1583.usersListStatus === RequestStatus.Success
export const getManagingAdmin =
  (userId: string) =>
  (state: RootState): User | undefined =>
    state.settingsApp.recipients1583.usersList.find(user => String(user.id) === userId)

export default recipientsSlice.reducer
