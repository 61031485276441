import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { AccountId } from 'interfaces/account.interface'
import { BillingInfo } from 'apps/DashboardApp/interfaces/billing_info.interface'

interface BillingInfoState {
  billingInfo: BillingInfo | null
  status: RequestStatus
  error: RequestError | undefined
}

const initialState: BillingInfoState = {
  status: RequestStatus.Pending,
  billingInfo: null,
  error: undefined
}

export const fetchAccountBillingInfo = createAsyncThunk(
  'billingInfo/fetchAccountBillingInfo',
  async (accountId: AccountId) => {
    const response: { data: BillingInfo } = await apiClient.get(`accounts/${String(accountId)}/plan-billing-info`)
    return response.data
  }
)

export const billingInfoSlice = createSlice({
  name: 'billingInfo',
  initialState,
  reducers: {
    resetBillingInfoState: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchAccountBillingInfo.fulfilled, (state, action) => {
      state.billingInfo = action.payload
      state.status = RequestStatus.Success
      state.error = undefined
    })
  }
})

export default billingInfoSlice.reducer

export const { resetBillingInfoState } = billingInfoSlice.actions
export const getBillingInfo = (state: RootState): BillingInfo | null => state.dashboardApp.billingInfo.billingInfo
export const isBillingInfoReady = (state: RootState): boolean =>
  state.dashboardApp.billingInfo.status === RequestStatus.Success
