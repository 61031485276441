import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { AccountId } from 'interfaces/account.interface'
import { CancellationType } from 'interfaces/account_cancellation.interface'

interface LZBillingData {
  lz_account_confirmed_at: string | null
  lz_address_id: string | null
  lz_business_name: string | null
}

interface SubscriptionFeeSummary {
  plan_fee_in_cents: number
  components_fee_in_cents: number
  total_price_in_cents: number
  current_billing_month: string
}

interface CancellationSummary {
  current_billing_period_ends_at: string
  signup_cancellation: boolean
  lz_account_confirmed_at: string | null
  lz_address_id: string | null
  lz_business_name: string | null
  subscription_fee_summary: SubscriptionFeeSummary
}

export interface CancellationReason {
  code: string
  title: string
}

export interface AccountCancellationState {
  cancellationSummary: CancellationSummary
  cancellationSummaryStatus: RequestStatus
  cancellationSummaryError?: RequestError
  cancellationReasons: CancellationReason[]
  cancellationReasonsStatus: RequestStatus
  cancellationReasonsError?: RequestError
}

const initialState: AccountCancellationState = {
  cancellationSummary: {
    current_billing_period_ends_at: '',
    signup_cancellation: false,
    lz_account_confirmed_at: null,
    lz_address_id: null,
    lz_business_name: null,
    subscription_fee_summary: {
      plan_fee_in_cents: 0,
      components_fee_in_cents: 0,
      total_price_in_cents: 0,
      current_billing_month: ''
    }
  },
  cancellationSummaryStatus: RequestStatus.Pending,
  cancellationReasons: [],
  cancellationReasonsStatus: RequestStatus.Pending
}

interface FetchCancellationSummary {
  accountId: AccountId
  cancellationType: CancellationType
}

export const fetchCancellationSummary = createAsyncThunk(
  'accountCancellation/fetchCancellationSummary',
  async ({ accountId, cancellationType }: FetchCancellationSummary) => {
    const response: { data: CancellationSummary } = await apiClient.get(
      `/accounts/${accountId}/billing/subscription/cancel/summary/${cancellationType}`
    )
    return response.data
  }
)

export const fetchCancellationReasons = createAsyncThunk(
  'accountCancellation/fetchCancellationReasons',
  async ({ accountId }: { accountId: AccountId }) => {
    const response: { data: CancellationReason[] } = await apiClient.get(
      `accounts/${accountId}/billing/subscription/cancel/reasons`
    )
    return response.data
  }
)

export const accountCancellationSlice = createSlice({
  name: 'accountCancellation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCancellationSummary.fulfilled, (state, action) => {
        state.cancellationSummaryStatus = RequestStatus.Success
        state.cancellationSummary = action.payload
        state.cancellationSummaryError = undefined
      })
      .addCase(fetchCancellationSummary.pending, state => {
        state.cancellationSummaryStatus = RequestStatus.Pending
      })
      .addCase(fetchCancellationSummary.rejected, (state, action) => {
        state.cancellationSummaryStatus = RequestStatus.Error
        state.cancellationSummary = initialState.cancellationSummary
        state.cancellationSummaryError = action.error.message
      })
      .addCase(fetchCancellationReasons.fulfilled, (state, action) => {
        state.cancellationReasonsStatus = RequestStatus.Success
        state.cancellationReasons = action.payload
        state.cancellationReasonsError = undefined
      })
      .addCase(fetchCancellationReasons.pending, state => {
        state.cancellationReasonsStatus = RequestStatus.Pending
      })
      .addCase(fetchCancellationReasons.rejected, (state, action) => {
        state.cancellationReasonsStatus = RequestStatus.Error
        state.cancellationReasons = initialState.cancellationReasons
        state.cancellationReasonsError = action.error.message
      })
  }
})

export const getCancellationSummaryStatus = (state: RootState): RequestStatus =>
  state.accountCancellation.cancellationSummaryStatus
export const getSubscriptionFeeSummary = (state: RootState): SubscriptionFeeSummary =>
  state.accountCancellation.cancellationSummary.subscription_fee_summary
export const getCurrentBillingEndDate = (state: RootState): string =>
  state.accountCancellation.cancellationSummary.current_billing_period_ends_at
export const getLegalZoomBillingData = (state: RootState): LZBillingData => ({
  lz_account_confirmed_at: state.accountCancellation.cancellationSummary.lz_account_confirmed_at,
  lz_address_id: state.accountCancellation.cancellationSummary.lz_address_id,
  lz_business_name: state.accountCancellation.cancellationSummary.lz_business_name
})
export const getIsLegalZoomAddressUsed = (state: RootState): boolean =>
  state.accountCancellation.cancellationSummary.lz_account_confirmed_at != null &&
  state.accountCancellation.cancellationSummary.lz_address_id != null
export const getCancellationReasonsStatus = (state: RootState): RequestStatus =>
  state.accountCancellation.cancellationReasonsStatus
export const getCancellationReasons = (state: RootState): CancellationReason[] =>
  state.accountCancellation.cancellationReasons
export const getIsSignupCancellation = (state: RootState): boolean =>
  state.accountCancellation.cancellationSummary.signup_cancellation

export default accountCancellationSlice.reducer
