import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiClient } from 'utils/api'
import { InboxesList, Inbox } from 'interfaces/inbox.interface'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { UserId } from 'interfaces/user.interface'
import { AccountStatus } from 'interfaces/account.interface'
import { RootState } from './store'

const initialPaginationState = (): InboxesList => ({
  data: [],
  current_page: 1,
  from: 1,
  last_page: 1,
  per_page: 100,
  to: 1,
  total: 0
})

export interface InboxesState {
  currentInbox: Inbox | null
  currentInboxStatus: RequestStatus
  currentInboxError: RequestError | null
  inboxesList: InboxesList
  activeInboxesList: Inbox[]
  inboxesListStatus: RequestStatus
  inboxesListError: RequestError | null
}

const initialState: InboxesState = {
  currentInbox: null,
  currentInboxStatus: RequestStatus.Pending,
  currentInboxError: null,
  inboxesList: initialPaginationState(),
  activeInboxesList: [],
  inboxesListStatus: RequestStatus.Pending,
  inboxesListError: null
}

export const fetchCurrentInbox = createAsyncThunk('inboxes/fetchCurrentInbox', async (inboxId: string) => {
  const response: { data: Inbox } = await apiClient.get(`/inboxes/${inboxId}`)
  return response.data
})

export const fetchInboxesList = createAsyncThunk('inboxes/fetchInboxesList', async (userId: UserId) => {
  const response: { data: InboxesList } = await apiClient.get(`/users/${userId}/inboxes`)
  if (response.data.total <= response.data.data.length) return response.data
  const fullResponse = { ...response.data }
  let page = 1
  while (fullResponse.data.length < fullResponse.total) {
    page += 1
    const nextPageResponse: { data: InboxesList } = await apiClient.get(`/users/${userId}/inboxes?page=${page}`)
    fullResponse.data = [...fullResponse.data, ...nextPageResponse.data.data]
  }
  return fullResponse
})

export const inboxesSlice = createSlice({
  name: 'inboxes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCurrentInbox.fulfilled, (state, action) => {
        state.currentInbox = action.payload
        state.currentInboxStatus = RequestStatus.Success
        state.currentInboxError = null
      })
      .addCase(fetchInboxesList.fulfilled, (state, action) => {
        state.inboxesList = action.payload
        state.inboxesList.data = action.payload.data.sort((a, b) => (a.account.name > b.account.name ? 1 : -1))
        state.activeInboxesList = state.inboxesList.data.filter(({ account }) =>
          [AccountStatus.Active, AccountStatus.Uncommitted].includes(account.account_status)
        )
        state.inboxesListStatus = RequestStatus.Success
        state.inboxesListError = null
      })
  }
})

export default inboxesSlice.reducer

export const getCurrentInbox = (state: RootState): Inbox | null => state.inboxes.currentInbox
export const isCurrentInboxReady = (state: RootState): boolean =>
  state.inboxes.currentInboxStatus === RequestStatus.Success
export const getInboxesList = (state: RootState): Inbox[] => state.inboxes.inboxesList.data
export const isInboxesListReady = (state: RootState): boolean =>
  state.inboxes.inboxesListStatus === RequestStatus.Success
export const getActiveInboxes = (state: RootState): Inbox[] => state.inboxes.activeInboxesList
export const withAdminAccess = (state: RootState): boolean => {
  const currentUser = state.currentUser.currentUser
  const currentInbox = state.inboxes.currentInbox
  if (currentUser == null || currentInbox?.account == null) return false
  const adminAccountIds = currentUser?.admin_account_ids
  return adminAccountIds != null && adminAccountIds.length > 0 && adminAccountIds.includes(currentInbox.account.id)
}
