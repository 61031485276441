import { AnyAction, Reducer } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import accountCommonSlice from './account'
import dialogCommonSlice from './dialog'
import individualDeliveredSlice from './individual_delivered'
import individualScannedSlice from './individual_scanned'
import individualCheckDetectedSlice from './individual_check_detected'

export type AccountAutomationState = ReturnType<typeof combinedReducer>

export const SETTINGS_ACCOUNT_AUTOMATION_RESET_APP_ACTION = 'settings/account_automation/reset'

const combinedReducer = combineReducers({
  account: accountCommonSlice,
  dialog: dialogCommonSlice,
  individualDelivered: individualDeliveredSlice,
  individualScanned: individualScannedSlice,
  individualCheckDetected: individualCheckDetectedSlice
})

const accountAutomationSlice: Reducer = (state: AccountAutomationState, action: AnyAction): AccountAutomationState => {
  if (action.type === SETTINGS_ACCOUNT_AUTOMATION_RESET_APP_ACTION) {
    return combinedReducer(undefined, action)
  }
  return combinedReducer(state, action)
}

export default accountAutomationSlice
