import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'

interface UncompletedScansData {
  count: number
}
interface UncompletedScansState {
  uncompletedScans: number
  status: RequestStatus
  error: RequestError | undefined
}

const initialState: UncompletedScansState = {
  uncompletedScans: 0,
  status: RequestStatus.Pending,
  error: undefined
}

export const fetchUncompletedScans = createAsyncThunk(
  'uncompletedScans/fetchUncompletedScans',
  async (inboxId: InboxId) => {
    const response: { data: UncompletedScansData } = await apiClient.get(
      `/inboxes/${inboxId}/dashboard/count-uncompleted-scans`
    )
    return response.data.count
  }
)

export const uncompletedScansSlice = createSlice({
  name: 'uncompletedScans',
  initialState,
  reducers: {
    resetUncompletedScansState: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchUncompletedScans.fulfilled, (state, action) => {
      state.status = RequestStatus.Success
      state.uncompletedScans = action.payload
      state.error = undefined
    })
  }
})

export default uncompletedScansSlice.reducer

export const { resetUncompletedScansState } = uncompletedScansSlice.actions
export const getUncompletedScansCount = (state: RootState): number =>
  state.dashboardApp.uncompletedScans.uncompletedScans
export const isUncompletedScansReady = (state: RootState): boolean =>
  state.dashboardApp.uncompletedScans.status === RequestStatus.Success
