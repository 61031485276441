import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosInstance } from 'axios'
import { sortBy } from 'lodash'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestStatus } from 'interfaces/common.interface'
import {
  ScannedSettingsToEdit,
  DeliveredSettingsToEdit,
  CheckDetectedSettingsToEdit
} from 'interfaces/account_automation.interface'
import { Recipient, RecipientsList } from 'interfaces/recipient.interface'
import { InboxId } from 'interfaces/inbox.interface'
import { Integration, IntegrationsList } from 'apps/SettingsApp/interfaces/integrations.interface'

interface AccountAutomationDialogInitialState {
  recipientsList: Recipient[]
  recipientsListStatus: RequestStatus
  integrationsList: Integration[]
  integrationsListStatus: RequestStatus
  searchedRecipientMatchPhrase: string
  settingsToEdit: ScannedSettingsToEdit | DeliveredSettingsToEdit | CheckDetectedSettingsToEdit | null
}

const initialState: AccountAutomationDialogInitialState = {
  recipientsList: [],
  recipientsListStatus: RequestStatus.Pending,
  integrationsList: [],
  integrationsListStatus: RequestStatus.Pending,
  searchedRecipientMatchPhrase: '',
  settingsToEdit: null
}

export const fetchRecipientsList = createAsyncThunk('dialog/fetchRecipientsList', async (inboxId: InboxId) => {
  const response: { data: RecipientsList } = await apiClient.get(`/inboxes/${inboxId}/recipients`)
  if (response.data.total <= response.data.data.length) return response.data
  const fullResponse = { ...response.data }
  let page = 1
  while (fullResponse.data.length < fullResponse.total) {
    page += 1
    const nextPageResponse: { data: RecipientsList } = await apiClient.get(
      `/inboxes/${inboxId}/recipients?page=${page}`
    )
    fullResponse.data = [...fullResponse.data, ...nextPageResponse.data.data]
  }
  return fullResponse
})

export const fetchIntegrationsList = createAsyncThunk(
  'dialog/fetchIntegrationsList',
  async (connectorApiClient: AxiosInstance) => {
    const response: { data: IntegrationsList } = await connectorApiClient.get('connector_providers')
    return response.data
  }
)

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setSearchedRecipientMatchPhrase(state, action: PayloadAction<string>) {
      state.searchedRecipientMatchPhrase = action.payload
    },
    setSettingsToEdit(
      state,
      action: PayloadAction<ScannedSettingsToEdit | DeliveredSettingsToEdit | CheckDetectedSettingsToEdit>
    ) {
      state.settingsToEdit = action.payload
    },
    clearDialog(state) {
      state.searchedRecipientMatchPhrase = initialState.searchedRecipientMatchPhrase
      state.settingsToEdit = initialState.settingsToEdit
      state.recipientsListStatus = initialState.recipientsListStatus
      state.integrationsListStatus = initialState.integrationsListStatus
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRecipientsList.fulfilled, (state, action) => {
        state.recipientsList = action.payload.data
        state.recipientsListStatus = RequestStatus.Success
      })
      .addCase(fetchRecipientsList.pending, state => {
        state.recipientsListStatus = RequestStatus.Pending
      })
      .addCase(fetchRecipientsList.rejected, state => {
        state.recipientsList = initialState.recipientsList
        state.recipientsListStatus = RequestStatus.Error
      })
      .addCase(fetchIntegrationsList.fulfilled, (state, action) => {
        state.integrationsList = action.payload.data
        state.integrationsListStatus = RequestStatus.Success
      })
      .addCase(fetchIntegrationsList.pending, state => {
        state.integrationsListStatus = RequestStatus.Pending
      })
      .addCase(fetchIntegrationsList.rejected, state => {
        state.integrationsList = initialState.integrationsList
        state.integrationsListStatus = RequestStatus.Error
      })
  }
})

export const { setSettingsToEdit, setSearchedRecipientMatchPhrase, clearDialog } = dialogSlice.actions

export const getSortedRecipientsList = (state: RootState): Recipient[] =>
  sortBy(state.settingsApp.accountAutomation.dialog.recipientsList, recipient => recipient.name.toLowerCase()).filter(
    ({ name }) => name.toLowerCase().includes(getSearchedRecipientMatchPhrase(state).toLowerCase())
  )
export const getRecipientsListReady = (state: RootState): boolean =>
  state.settingsApp.accountAutomation.dialog.recipientsListStatus === RequestStatus.Success
export const getIntegrationsList = (state: RootState): Integration[] =>
  state.settingsApp.accountAutomation.dialog.integrationsList
export const getIntegrationsListReady = (state: RootState): boolean =>
  state.settingsApp.accountAutomation.dialog.integrationsListStatus === RequestStatus.Success
export const getSearchedRecipientMatchPhrase = (state: RootState): string =>
  state.settingsApp.accountAutomation.dialog.searchedRecipientMatchPhrase
export const getSettingsToEdit = (
  state: RootState
): ScannedSettingsToEdit | DeliveredSettingsToEdit | CheckDetectedSettingsToEdit | null =>
  state.settingsApp.accountAutomation.dialog.settingsToEdit

export default dialogSlice.reducer
