import { omit } from 'lodash'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { isAxiosError } from 'axios'
import { RootState } from 'store/store'
import { appCreateAsyncThunk } from 'store/app_create_async_thunk'
import { apiClient } from 'utils/api'
import getApiErrorSerializer from 'utils/get_api_error_serializer'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { ApplicantDocuments, BaseApplicant } from 'interfaces/applicant.interface'
import {
  ApplicantDraftStatus,
  ApplicantDraft,
  ApplicantDraftIndividualRecipientResolution
} from 'interfaces/applicant_draft.interface'
import { CourtOrder } from 'shared_components/CourtOrderedProtectedIndividualInput/CourtOrderedProtectedIndividualInput'

export interface ApplicantDraftState {
  applicantDraft?: ApplicantDraft
  applicantDraftRequestStatus?: RequestStatus
  applicantDraftError?: RequestError
  applicantDraftDocuments?: ApplicantDocuments[]
  applicantDraftDocumentsRequestStatus?: RequestStatus
  applicantDraftDocumentsError?: RequestError
  updateApplicantDraftRequestStatus?: RequestStatus
  updateApplicantDraftErrorMessage?: string
  updateIndividualRecipientResolutionRequestStatus?: RequestStatus
  updateIndividualRecipientResolutionErrorMessage?: string
}

const initialState: ApplicantDraftState = {}

interface FetchApplicantDraftParams {
  accountId: number
}

export const fetchApplicantDraft = createAsyncThunk(
  'applicantDraft/fetchApplicantDraft',
  async ({ accountId }: FetchApplicantDraftParams) => {
    try {
      const { data } = await apiClient.get<ApplicantDraft>(`mail-authorization/accounts/${accountId}/applicant/draft`)
      return data
    } catch (error: unknown) {
      if (isAxiosError(error) && error?.response?.status === 404) {
        return initialState.applicantDraft
      }
      throw error
    }
  }
)

export const fetchApplicantDraftDocuments = createAsyncThunk(
  'applicantDraft/fetchApplicantDraftDocuments',
  async ({ accountId }: FetchApplicantDraftParams) => {
    const response: { data: ApplicantDocuments[] } = await apiClient.get(
      `mail-authorization/accounts/${accountId}/applicant/draft/documents`
    )
    return response.data
  }
)

export interface UpdateApplicantDraftParams {
  accountId: number
  recipientId?: string
  applicant: BaseApplicant
  courtOrder: CourtOrder
}

export const updateApplicantDraft = appCreateAsyncThunk(
  'applicantDraft/updateApplicantDraft',
  async ({ accountId, recipientId, applicant, courtOrder }: UpdateApplicantDraftParams, { getState }) => {
    const [previousCourtOrder] = getApplicantDraftDocuments(getState())
    const { data: newApplicantDraftData } = await apiClient.post<ApplicantDraft>(
      `/mail-authorization/accounts/${accountId}/applicant/draft`,
      {
        first_name: applicant.first_name,
        last_name: applicant.last_name,
        middle_initial: applicant.middle_initial,
        has_ssn: applicant.has_ssn,
        primary_id_document: {
          type: applicant.primary_id_document?.type,
          id_number: applicant.primary_id_document?.id_number,
          issuing_state: applicant.primary_id_document?.issuing_state,
          issuing_country: applicant.primary_id_document?.issuing_country,
          expiration_date: applicant.primary_id_document?.expiration_date
        },
        secondary_id_document: { type: applicant.secondary_id_document?.type },
        home_address: {
          address_line_1: applicant.home_address.address_line_1,
          address_line_2: applicant.home_address.address_line_2,
          city: applicant.home_address.city,
          state: applicant.home_address.state,
          zip_code: applicant.home_address.zip_code,
          country: applicant.home_address.country
        },
        phone_number: applicant.phone_number,
        recipient_id: recipientId || null
      }
    )

    if (previousCourtOrder?.id && (courtOrder.content || !courtOrder.id)) {
      await apiClient.delete(
        `mail-authorization/accounts/${accountId}/applicant/draft/documents/${previousCourtOrder.id}`
      )
    }
    if (courtOrder.content) {
      const { data: newCourtOrderData } = await apiClient.post<ApplicantDocuments>(
        `mail-authorization/accounts/${accountId}/applicant/draft/documents`,
        {
          type: 'court_ordered_protection',
          document: {
            name: courtOrder.name,
            content: courtOrder.content
          }
        }
      )

      return { applicantDraft: newApplicantDraftData, documents: [newCourtOrderData] }
    }

    return { applicantDraft: newApplicantDraftData, documents: [] }
  },
  {
    serializeError: getApiErrorSerializer({
      defaultMessage: 'An error occurred while updating the applicant. Please try again later or contact support.'
    })
  }
)

export interface UpdateIndividualRecipientResolutionParams {
  accountId: number
  resolution: ApplicantDraftIndividualRecipientResolution
}

export const updateIndividualRecipientResolution = createAsyncThunk(
  'applicantDraft/updateIndividualRecipientResolution',
  async ({ accountId, resolution }: UpdateIndividualRecipientResolutionParams) => {
    const { data } = await apiClient.post<ApplicantDraft>(
      `mail-authorization/accounts/${accountId}/applicant/draft/individual_recipient_resolution`,
      { resolution }
    )

    return data
  },
  {
    serializeError: getApiErrorSerializer({
      defaultMessage: 'An error occurred while saving your answer. Please try again later or contact support.'
    })
  }
)

export const applicantDraftSlice = createSlice({
  name: 'applicantDraft',
  initialState,
  reducers: {
    resetApplicantDraft: () => initialState,
    resetUpdateStatus: prevState => ({
      ...prevState,
      updateApplicantDraftRequestStatus: undefined
    })
  },
  extraReducers: builder => {
    builder
      .addCase(fetchApplicantDraft.fulfilled, (state, { payload }) => {
        state.applicantDraftRequestStatus = RequestStatus.Success
        // Omitting the issuing_entity field since it's not used anymore in the UI
        state.applicantDraft = payload ? omit(payload, 'primary_id_document.issuing_entity') : payload
        state.applicantDraftError = undefined
      })
      .addCase(fetchApplicantDraft.pending, state => {
        state.applicantDraftRequestStatus = RequestStatus.Pending
      })
      .addCase(fetchApplicantDraft.rejected, (state, action) => {
        state.applicantDraftRequestStatus = RequestStatus.Error
        state.applicantDraft = initialState.applicantDraft
        state.applicantDraftError = action.error.message
      })
      .addCase(fetchApplicantDraftDocuments.fulfilled, (state, action) => {
        state.applicantDraftDocuments = action.payload
        state.applicantDraftDocumentsRequestStatus = RequestStatus.Success
        state.applicantDraftDocumentsError = undefined
      })
      .addCase(fetchApplicantDraftDocuments.rejected, (state, action) => {
        state.applicantDraftDocuments = []
        state.applicantDraftRequestStatus = RequestStatus.Error
        state.applicantDraftError = action.error.message
      })
      .addCase(updateApplicantDraft.pending, state => {
        state.updateApplicantDraftRequestStatus = RequestStatus.Pending
      })
      .addCase(updateApplicantDraft.fulfilled, (state, { payload }) => {
        const { applicantDraft, documents } = payload
        state.updateApplicantDraftRequestStatus = RequestStatus.Success
        state.applicantDraft = omit(applicantDraft, 'primary_id_document.issuing_entity')
        state.applicantDraftDocuments = documents
        state.updateApplicantDraftErrorMessage = undefined
      })
      .addCase(updateApplicantDraft.rejected, (state, action) => {
        state.updateApplicantDraftRequestStatus = RequestStatus.Error
        state.updateApplicantDraftErrorMessage = action.error.message
      })
      .addCase(updateIndividualRecipientResolution.pending, state => {
        state.updateIndividualRecipientResolutionRequestStatus = RequestStatus.Pending
      })
      .addCase(updateIndividualRecipientResolution.fulfilled, (state, { payload }) => {
        state.updateIndividualRecipientResolutionRequestStatus = RequestStatus.Success
        state.updateIndividualRecipientResolutionErrorMessage = undefined
        state.applicantDraft = payload
      })
      .addCase(updateIndividualRecipientResolution.rejected, (state, action) => {
        state.updateIndividualRecipientResolutionRequestStatus = RequestStatus.Error
        state.updateIndividualRecipientResolutionErrorMessage = action.error.message
      })
  }
})

export const { resetApplicantDraft, resetUpdateStatus } = applicantDraftSlice.actions

export const getApplicantDraft = (state: RootState): ApplicantDraft | undefined => state.applicantDraft.applicantDraft
export const isApplicantDraftReady = (state: RootState): boolean =>
  state.applicantDraft.applicantDraftRequestStatus === RequestStatus.Success
export const getApplicantDraftDocuments = (state: RootState): ApplicantDocuments[] =>
  state.applicantDraft.applicantDraftDocuments || []
export const areApplicantDraftDocumentsReady = (state: RootState): boolean =>
  state.applicantDraft.applicantDraftRequestStatus === RequestStatus.Success
export const isApplicantDraftUpdateInProgress = (state: RootState): boolean =>
  state.applicantDraft.updateApplicantDraftRequestStatus === RequestStatus.Pending
export const isApplicantDraftUpdateSuccessful = (state: RootState): boolean =>
  state.applicantDraft.updateApplicantDraftRequestStatus === RequestStatus.Success
export const getApplicantDraftUpdateErrorMessage = (state: RootState): string | undefined =>
  state.applicantDraft.updateApplicantDraftErrorMessage
export const getApplicantDraftStatus = (state: RootState): ApplicantDraftStatus | undefined =>
  getApplicantDraft(state)?.draft_status
export const hasApplicantDraftIndividualRecipientNameMismatch = (state: RootState): boolean =>
  getApplicantDraft(state)?.individual_recipient_name_mismatch ?? false
export const getApplicantDraftIndividualRecipientResolution = (
  state: RootState
): ApplicantDraftIndividualRecipientResolution | undefined => getApplicantDraft(state)?.individual_recipient_resolution
export const isApplicantDraftIndividualRecipientResolutionUpdateSuccessful = (state: RootState): boolean =>
  state.applicantDraft.updateIndividualRecipientResolutionRequestStatus === RequestStatus.Success
export const getApplicantDraftIndividualRecipientResolutionUpdateErrorMessage = (
  state: RootState
): string | undefined => state.applicantDraft.updateIndividualRecipientResolutionErrorMessage

export default applicantDraftSlice.reducer
