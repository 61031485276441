import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { getCurrentUser } from 'store/current_user'

const useLaunchDarklyInit = (): void => {
  const ldClient = useLDClient()
  const currentUser = useSelector(getCurrentUser)

  useEffect(() => {
    if (ldClient == null) {
      return
    }

    // if a user is not logged in, switch context to anonymous
    if (!currentUser) {
      if (!ldClient.getContext().anonymous) {
        ldClient.identify({
          kind: 'user',
          anonymous: true
        })
      }
      return
    }

    const { id, first_name, last_name, email, username, uuid } = currentUser
    ldClient.identify({
      kind: 'user',
      key: id.toString(),
      name: `${first_name} ${last_name}`,
      email,
      username,
      uuid
    })
  }, [ldClient, currentUser])
}

export default useLaunchDarklyInit
