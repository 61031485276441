import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'

interface ChcecksCountData {
  count: number
}

interface ChecksState {
  checksAvailableForDepositCount: number
  checksAvailableForDepositStatus: RequestStatus
  checksAvailableForDepositError: RequestError | undefined
  checksInDepositCount: number
  checksInDepositStatus: RequestStatus
  checksInDepositError: RequestError | undefined
}
const initialState: ChecksState = {
  checksAvailableForDepositCount: 0,
  checksAvailableForDepositStatus: RequestStatus.Pending,
  checksAvailableForDepositError: undefined,
  checksInDepositCount: 0,
  checksInDepositStatus: RequestStatus.Pending,
  checksInDepositError: undefined
}

export const fetchChecksAvailableForDeposit = createAsyncThunk(
  'checks/fetchChecksAvailableForDeposit',
  async (inboxId: InboxId) => {
    const response: { data: ChcecksCountData } = await apiClient.get(
      `/inboxes/${inboxId}/dashboard/count-pieces-available-for-check-deposit`
    )
    return response.data.count
  }
)

export const fetchChecksInDeposit = createAsyncThunk('checks/fetchChecksInDeposit', async (inboxId: InboxId) => {
  const response: { data: ChcecksCountData } = await apiClient.get(
    `/inboxes/${inboxId}/dashboard/count-uncompleted-deposits`
  )
  return response.data.count
})

export const checksSlice = createSlice({
  name: 'checks',
  initialState,
  reducers: {
    resetChecksState: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(fetchChecksAvailableForDeposit.fulfilled, (state, action) => {
        state.checksAvailableForDepositCount = action.payload
        state.checksAvailableForDepositStatus = RequestStatus.Success
        state.checksAvailableForDepositError = undefined
      })
      .addCase(fetchChecksInDeposit.fulfilled, (state, action) => {
        state.checksInDepositCount = action.payload
        state.checksInDepositStatus = RequestStatus.Success
        state.checksInDepositError = undefined
      })
  }
})

export default checksSlice.reducer

export const { resetChecksState } = checksSlice.actions
export const getChecksAvailableForDepositCount = (state: RootState): number =>
  state.dashboardApp.checks.checksAvailableForDepositCount
export const getChecksInDepositCount = (state: RootState): number => state.dashboardApp.checks.checksInDepositCount
export const isChecksInDepositReady = (state: RootState): boolean =>
  state.dashboardApp.checks.checksInDepositStatus === RequestStatus.Success
export const isChecksAvailableForDepositReady = (state: RootState): boolean =>
  state.dashboardApp.checks.checksAvailableForDepositStatus === RequestStatus.Success
