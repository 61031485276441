import EcmLogoSmall from 'assets/ecm_logo_small.svg'
import SkeletonNarrowNavigationIcon from './NarrowNavigationIconSkeleton'
import styles from './NarrowNavigationSkeleton.module.scss'

export default function NarrowNavigationSkeleton(): JSX.Element {
  return (
    <nav className={styles.navigation} aria-busy='true'>
      <div className={styles.logoWrapper}>
        <img src={EcmLogoSmall} className={styles.logo} alt='logo' />
      </div>
      <div className={styles.wrapper}>
        <ul className={styles.list}>
          {[...Array(4)].map((x, i) => (
            <SkeletonNarrowNavigationIcon key={i} />
          ))}
        </ul>
        <ul className={styles.list}>
          <SkeletonNarrowNavigationIcon />
          <hr className={styles.divider} />
          {[...Array(2)].map((x, i) => (
            <SkeletonNarrowNavigationIcon key={i} />
          ))}
        </ul>
      </div>
    </nav>
  )
}
