import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { CreditCard, PaymentMethod } from 'interfaces/payment_details.interface'

interface PaymentDetailsState {
  creditCard: CreditCard
  creditCardStatus: RequestStatus
  creditCardError: RequestError | undefined
}

const initialState: PaymentDetailsState = {
  creditCard: {
    name: '',
    bank_name: '',
    type: PaymentMethod.Card,
    brand: '',
    network: '',
    last_four: '',
    exp_month: 0,
    exp_year: 0,
    expired: false,
    country: ''
  },
  creditCardStatus: RequestStatus.Pending,
  creditCardError: undefined
}

export const fetchCardDetails = createAsyncThunk('paymentDetails/fetchCardDetails', async (accountId: string) => {
  const response: { data: { payment_method: CreditCard } } = await apiClient.get(
    `accounts/${accountId}/billing/default-payment-method`
  )
  return response.data
})

export const paymentDetailsSlice = createSlice({
  name: 'paymentDetails',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCardDetails.fulfilled, (state, action) => {
        state.creditCard = action.payload.payment_method
        state.creditCardStatus = RequestStatus.Success
      })
      .addCase(fetchCardDetails.pending, state => {
        state.creditCardStatus = RequestStatus.Pending
      })
      .addCase(fetchCardDetails.rejected, (state, action) => {
        state.creditCard = initialState.creditCard
        state.creditCardStatus = RequestStatus.Error
        state.creditCardError = action.error.message
      })
  }
})

export const getCreditCard = (state: RootState): CreditCard => state.settingsApp.paymentDetails.creditCard
export const getCreditCardReady = (state: RootState): boolean =>
  state.settingsApp.paymentDetails.creditCardStatus === RequestStatus.Success

export default paymentDetailsSlice.reducer
