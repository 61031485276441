import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { PaginatedList, RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'
import { Tag } from 'apps/SettingsApp/interfaces/tags.interface'

const PER_PAGE = 50

export interface TagsList extends PaginatedList<Tag> {}

interface TagsState {
  tagsPaginatedList: TagsList
  searchedMatchPhrase: string
  shownMatchPhrase: string
  initialTagsTotal: number
  status: RequestStatus
  error: RequestError | undefined
}

interface FetchTagsArgs {
  inboxId: InboxId
  page?: number
}

const initialState: TagsState = {
  tagsPaginatedList: {
    current_page: 1,
    data: [],
    from: 0,
    last_page: 0,
    per_page: PER_PAGE,
    to: 0,
    total: 0
  },
  searchedMatchPhrase: '',
  shownMatchPhrase: '',
  initialTagsTotal: 0,
  status: RequestStatus.Pending,
  error: undefined
}

export const fetchTagsList = createAsyncThunk(
  'tags/fetchTagsList',
  async ({ inboxId, page }: FetchTagsArgs, { getState }) => {
    const state = getState() as RootState
    const currentPage = state.settingsApp.tags.tagsPaginatedList.current_page
    const searchedMatchPhrase = state.settingsApp.tags.searchedMatchPhrase
    const response: { data: TagsList } = await apiClient.get(`/inboxes/${inboxId}/tags`, {
      params: {
        page: page ?? currentPage,
        per_page: PER_PAGE,
        match_phrase: searchedMatchPhrase
      }
    })
    return response.data
  }
)

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTagsSearchedMatchPhrase(state, action: PayloadAction<string>) {
      state.searchedMatchPhrase = action.payload
    },
    resetTagsStore: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTagsList.fulfilled, (state, action) => {
        state.status = RequestStatus.Success
        state.tagsPaginatedList = action.payload
        if (state.searchedMatchPhrase === '' && action.payload.current_page === 1)
          state.initialTagsTotal = action.payload.total
        state.shownMatchPhrase = state.searchedMatchPhrase
      })
      .addCase(fetchTagsList.rejected, (state, action) => {
        state.status = RequestStatus.Error
        state.tagsPaginatedList = initialState.tagsPaginatedList
        state.error = action.error.message
      })
  }
})

export const { setTagsSearchedMatchPhrase, resetTagsStore } = tagsSlice.actions
export const getTagsList = (state: RootState): Tag[] => state.settingsApp.tags.tagsPaginatedList.data
export const getTagsCurrentPage = (state: RootState): number => state.settingsApp.tags.tagsPaginatedList.current_page
export const getTagsLastPage = (state: RootState): number => state.settingsApp.tags.tagsPaginatedList.last_page
export const getTagsTotal = (state: RootState): number => state.settingsApp.tags.tagsPaginatedList.total
export const getTagsShownMatchPhrase = (state: RootState): string => state.settingsApp.tags.shownMatchPhrase
export const getTagsSearchedMatchPhrase = (state: RootState): string => state.settingsApp.tags.searchedMatchPhrase
export const getTagsStatus = (state: RootState): RequestStatus => state.settingsApp.tags.status
export const getHasTagsPerfectMatch = (state: RootState): boolean =>
  state.settingsApp.tags.tagsPaginatedList.data.some(
    ({ label }: Tag) => label.toLowerCase() === state.settingsApp.tags.shownMatchPhrase.toLowerCase()
  )
export const getHasTags = (state: RootState): boolean => state.settingsApp.tags.initialTagsTotal > 0
export const getHasOneTagOnLastPage = (state: RootState): boolean =>
  state.settingsApp.tags.tagsPaginatedList.from === state.settingsApp.tags.tagsPaginatedList.to

export default tagsSlice.reducer
