import { Snackbar } from '@mui/material'
import { styled } from '@mui/material/styles'

export default styled(Snackbar)(({ theme }) => ({
  '& .alert': {
    boxShadow: '0 0 10px 2px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    backgroundColor: theme.palette.blackSqueeze['light-4']
  },
  '& .alert__success': {
    borderLeft: `5px solid ${theme.palette.greenHaze.light}`,
    borderRight: `5px solid ${theme.palette.greenHaze.light}`
  },
  '& .alert__error': {
    borderLeft: `5px solid ${theme.palette.cinnabar.light}`,
    borderRight: `5px solid ${theme.palette.cinnabar.light}`
  },
  '& .alertMessage': {
    padding: '15px 30px'
  }
}))
