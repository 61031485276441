export enum PaymentMethod {
  Card = 'card',
  BankAccount = 'us_bank_account',
  NoPaymentMethod = 'no_payment_method'
}

export interface CreditCard {
  name: string
  bank_name: string | null
  type: PaymentMethod
  brand: string
  network: string | null
  last_four: string
  exp_month: number
  exp_year: number
  country: string
  expired: boolean
}

export interface PaymentData {
  balance_in_cents: number
  company_name: string | null
  payment_method: CreditCard
  subscription_suspended_at: string | Date
  subscription_canceled_at: string | Date
  can_reactivate_until_date: string | Date
}
