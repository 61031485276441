import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { PaginatedList, RequestError, RequestStatus } from 'interfaces/common.interface'

const PER_PAGE = 100

type BlackoutDate = string

export interface BlackoutDatesList extends PaginatedList<BlackoutDate> {}

interface BlackoutDatesState {
  blackoutDatesList: BlackoutDatesList
  status: RequestStatus
  error: RequestError | undefined
}

const initialState: BlackoutDatesState = {
  blackoutDatesList: {
    current_page: 1,
    data: [],
    from: 0,
    last_page: 0,
    per_page: PER_PAGE,
    to: 0,
    total: 0
  },
  status: RequestStatus.Pending,
  error: undefined
}

export const fetchBlackoutDatesList = createAsyncThunk(
  'blackoutDates/fetchBlackoutDatesList',
  async (facility: string | undefined) => {
    if (facility === undefined) return initialState.blackoutDatesList
    const response: { data: BlackoutDatesList } = await apiClient.get(`/blackout-dates/${facility}`, {
      params: {
        operation_type: 'ship',
        per_page: PER_PAGE
      }
    })
    return response.data
  }
)

export const blackoutDatesSlice = createSlice({
  name: 'blackoutDates',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchBlackoutDatesList.fulfilled, (state, action) => {
        state.status = RequestStatus.Success
        state.blackoutDatesList = action.payload
        state.error = undefined
      })
      .addCase(fetchBlackoutDatesList.pending, state => {
        state.status = RequestStatus.Pending
      })
      .addCase(fetchBlackoutDatesList.rejected, (state, action) => {
        state.status = RequestStatus.Error
        state.blackoutDatesList = initialState.blackoutDatesList
        state.error = action.error.message
      })
  }
})

export const getBlackoutDatesList = (state: RootState): BlackoutDate[] =>
  state.mailroomApp.blackoutDates.blackoutDatesList.data
export const getBlackoutDatesReady = (state: RootState): boolean =>
  state.mailroomApp.blackoutDates.status === RequestStatus.Success

export default blackoutDatesSlice.reducer
