import { Inbox } from 'interfaces/inbox.interface'
import { User } from 'interfaces/user.interface'

const CATCH_KEY = 'earthclassmail.preferences'

type PreferenceType = object | string | undefined

interface Preferences extends Record<string, PreferenceType> {
  current_user?: User
  current_inbox?: Inbox
  preferredRoute?: string
}
const setPreferences = (preferences: string): void => {
  localStorage.setItem(CATCH_KEY, preferences)
}
const getPreferences = (): Preferences => {
  const currentPreferences = localStorage.getItem(CATCH_KEY)
  if (currentPreferences == null) return {}
  return JSON.parse(currentPreferences)
}

export const clearCurrentInboxAndCurrentUserData = (): void => {
  const preferences = getPreferences()
  delete preferences.current_inbox
  delete preferences.current_user
  setPreferences(JSON.stringify(preferences))
}

export const setPreference = (key: keyof Preferences, value: object | string): void => {
  const preferences = getPreferences()
  preferences[key] = value
  setPreferences(JSON.stringify(preferences))
}
