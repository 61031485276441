import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiClient, apiForLoginUsers } from 'utils/api'
import { User } from 'interfaces/user.interface'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { RootState } from './store'

export interface CurrentUserState {
  currentUser: User | null
  status: RequestStatus
  error: RequestError | null
}

const initialState: CurrentUserState = {
  status: RequestStatus.Pending,
  currentUser: null,
  error: null
}

export const fetchCurrentUser = createAsyncThunk('currentUser/fetchCurrentUser', async () => {
  const response: { data: User } = await apiClient.get('/user')
  return response.data
})
// should be used only when logging in users because it contains redirect to marketing
// if user didn't complete checkout
export const fetchCurrentUserOnLoggingIn = createAsyncThunk('currentUser/fetchCurrentUserOnLoggingIn', async () => {
  const response: { data: User } = await apiForLoginUsers.get('/user')
  return response.data
})

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCurrentUser.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload
        state.status = RequestStatus.Success
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.currentUser = null
        state.status = RequestStatus.Error
        state.error = action.error?.message ?? null
      })
      .addCase(fetchCurrentUserOnLoggingIn.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(fetchCurrentUserOnLoggingIn.fulfilled, (state, action) => {
        state.currentUser = action.payload
        state.status = RequestStatus.Success
      })
      .addCase(fetchCurrentUserOnLoggingIn.rejected, (state, action) => {
        state.currentUser = null
        state.status = RequestStatus.Error
        state.error = action.error?.message ?? null
      })
  }
})

export const getCurrentUser = (state: RootState): User | null => state.currentUser.currentUser

export default currentUserSlice.reducer
