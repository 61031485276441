import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { AccountId } from 'interfaces/account.interface'

export interface RecipientsBillingInfo {
  current_plan_included_recipients: number
  current_recipient_usage: number
  extra_billing_unit_price: number
  next_recipient_extra_billing: boolean
}

export interface RecipientsBillingInfoState {
  recipientsBillingInfo: RecipientsBillingInfo
  recipientsBillingInfoStatus: RequestStatus
  recipientsBillingInfoError: RequestError | undefined
  recipientsBillingInfoInitialLoading: boolean
}

const initialState: RecipientsBillingInfoState = {
  recipientsBillingInfo: {
    current_plan_included_recipients: 0,
    current_recipient_usage: 0,
    extra_billing_unit_price: 0,
    next_recipient_extra_billing: false
  },
  recipientsBillingInfoStatus: RequestStatus.Pending,
  recipientsBillingInfoError: undefined,
  recipientsBillingInfoInitialLoading: true
}

export const fetchRecipientsBillingInfo = createAsyncThunk(
  'recipientsBillingInfo/fetchRecipientsBillingInfo',
  async (accountId: AccountId) => {
    const response: { data: RecipientsBillingInfo } = await apiClient.get(
      `accounts/${accountId}/recipients-billing-info`
    )
    return response.data
  }
)

export const recipientsBillingInfoSlice = createSlice({
  name: 'recipientsBillingInfo',
  initialState,
  reducers: {
    setRecipientsBillingInfoInitialState: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRecipientsBillingInfo.fulfilled, (state, action) => {
        state.recipientsBillingInfo = action.payload
        state.recipientsBillingInfoStatus = RequestStatus.Success
        state.recipientsBillingInfoError = undefined
        state.recipientsBillingInfoInitialLoading = false
      })
      .addCase(fetchRecipientsBillingInfo.pending, state => {
        state.recipientsBillingInfoStatus = RequestStatus.Pending
      })
      .addCase(fetchRecipientsBillingInfo.rejected, (state, action) => {
        state.recipientsBillingInfoStatus = RequestStatus.Error
        state.recipientsBillingInfo = initialState.recipientsBillingInfo
        state.recipientsBillingInfoError = action.error.message
      })
  }
})

export const { setRecipientsBillingInfoInitialState } = recipientsBillingInfoSlice.actions
export const getRecipientsBillingInfo = (state: RootState): RecipientsBillingInfo =>
  state.recipientsBillingInfo.recipientsBillingInfo
export const getRecipientsBillingInfoInitialLoading = (state: RootState): boolean =>
  state.recipientsBillingInfo.recipientsBillingInfoInitialLoading
export const getShowRecipientsOverPlanBanner =
  (membersNumber: number) =>
  (state: RootState): boolean => {
    const { current_plan_included_recipients } = state.recipientsBillingInfo.recipientsBillingInfo // eslint-disable-line @typescript-eslint/naming-convention
    return current_plan_included_recipients > 0 && current_plan_included_recipients < membersNumber
  }

export default recipientsBillingInfoSlice.reducer
