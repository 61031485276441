import { createContext, useContext } from 'react'
import { AlertValue } from 'interfaces/common.interface'

interface AlertContextInterface {
  alertValue: AlertValue
  setAlert: (alertValue: AlertValue) => void
}

export const AlertContext = createContext<AlertContextInterface | null>(null)

const useAlertContext = (): AlertContextInterface => {
  const context = useContext(AlertContext)

  if (context === null) {
    throw new Error('Missing <AlertContext.Provider />')
  }

  return context
}

export default useAlertContext
