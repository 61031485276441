import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { AccountId } from 'interfaces/account.interface'
import { AddressAuthorizationDocuments } from 'interfaces/authorization_document.interface'

interface AddressDocumentsState {
  addressDocuments: AddressAuthorizationDocuments
  status: RequestStatus
  error: RequestError | undefined
}

const initialState: AddressDocumentsState = {
  status: RequestStatus.Pending,
  addressDocuments: {},
  error: undefined
}

export const fetchAddressDocuments = createAsyncThunk(
  'addressDocuments/fetchAddressDocuments',
  async ({ accountId }: { accountId: AccountId }) => {
    const response: { data: AddressAuthorizationDocuments } = await apiClient.get(
      `mail-authorization/accounts/${accountId}/address_documents`
    )
    return response.data
  }
)

export const addressDocumentsSlice = createSlice({
  name: 'addressDocuments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAddressDocuments.fulfilled, (state, action) => {
        state.addressDocuments = action.payload
        state.status = RequestStatus.Success
        state.error = undefined
      })
      .addCase(fetchAddressDocuments.pending, state => {
        state.status = RequestStatus.Pending
      })
      .addCase(fetchAddressDocuments.rejected, (state, action) => {
        state.addressDocuments = initialState.addressDocuments
        state.status = RequestStatus.Error
        state.error = action.error.message
      })
  }
})

export default addressDocumentsSlice.reducer

export const getAddressDocuments =
  () =>
  (state: RootState): AddressAuthorizationDocuments =>
    state.dashboardApp.addressDocuments.addressDocuments
