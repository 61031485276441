import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { sortBy, uniq } from 'lodash'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'
import {
  RecipientScanPreferences,
  ScanPreferences,
  ScanPreferencesDisplayed
} from 'apps/SettingsApp/interfaces/scan_preferences.interface'

interface FetchRecipientsList {
  inboxId: InboxId
  recipientIds: number[]
}

interface ScanPreferencesState {
  scanPreferencesList: RecipientScanPreferences
  scanPreferencesError: RequestError | undefined
  scanPreferencesStatus: RequestStatus
  scanPreferencesInitialLoading: boolean
  recipientsList: Array<{ id: string; name: string }>
  recipientsError: RequestError | undefined
  recipientsStatus: RequestStatus
  recipientsListInitialLoading: boolean
}

const initialState: ScanPreferencesState = {
  scanPreferencesList: {
    recipient_scan_preferences: []
  },
  scanPreferencesError: undefined,
  scanPreferencesStatus: RequestStatus.Pending,
  scanPreferencesInitialLoading: true,
  recipientsList: [],
  recipientsError: undefined,
  recipientsStatus: RequestStatus.Pending,
  recipientsListInitialLoading: true
}

export const fetchScanPreferencesList = createAsyncThunk(
  'scanPreferences/fetchScanPreferencesList',
  async ({ inboxId }: { inboxId: InboxId }) => {
    const response: { data: RecipientScanPreferences } = await apiClient.get(`/inboxes/${inboxId}/scan-preferences`)
    return response.data
  }
)

export const fetchRecipientsList = createAsyncThunk(
  'scanPreferences/fetchRecipientsList',
  async ({ inboxId, recipientIds }: FetchRecipientsList) => {
    if (recipientIds.length === 0) return []
    const response: { data: Array<{ id: string; name: string }> } = await apiClient.get(
      `/inboxes/${inboxId}/recipient-names`,
      {
        params: {
          recipient_ids: uniq(recipientIds).join()
        }
      }
    )
    return response.data
  }
)

export const scanPreferencesSlice = createSlice({
  name: 'scanPreferences',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchScanPreferencesList.fulfilled, (state, action) => {
        state.scanPreferencesStatus = RequestStatus.Success
        state.scanPreferencesList = action.payload
        state.scanPreferencesInitialLoading = false
      })
      .addCase(fetchScanPreferencesList.rejected, (state, action) => {
        state.scanPreferencesStatus = RequestStatus.Error
        state.scanPreferencesList = { recipient_scan_preferences: [] }
        state.scanPreferencesError = action.error.message
      })
      .addCase(fetchScanPreferencesList.pending, state => {
        state.scanPreferencesStatus = RequestStatus.Pending
      })
      .addCase(fetchRecipientsList.fulfilled, (state, action) => {
        state.recipientsStatus = RequestStatus.Success
        state.recipientsList = action.payload
        state.recipientsListInitialLoading = false
      })
      .addCase(fetchRecipientsList.rejected, (state, action) => {
        state.recipientsStatus = RequestStatus.Error
        state.recipientsList = []
        state.recipientsError = action.error.message
      })
      .addCase(fetchRecipientsList.pending, state => {
        state.recipientsStatus = RequestStatus.Pending
      })
  }
})

export const getScanPreferencesList = (state: RootState): ScanPreferences[] =>
  state.settingsApp.scanPreferences.scanPreferencesList.recipient_scan_preferences
export const getScanPreferencesDisplayedList = (state: RootState): ScanPreferencesDisplayed[] => {
  const {
    scanPreferencesList: { recipient_scan_preferences },
    recipientsList
  } = state.settingsApp.scanPreferences // eslint-disable-line @typescript-eslint/naming-convention
  const scanPreferencesList = recipient_scan_preferences.filter(
    ({ include_envelope_images, include_item_details }) => include_envelope_images || include_item_details
  ) // eslint-disable-line @typescript-eslint/naming-convention
  const scanPreferencesDisplayed = scanPreferencesList.map(scanPreferences => {
    const recipientSelected = recipientsList.find(({ id }) => id === String(scanPreferences.recipient_id))
    return { ...scanPreferences, recipient_name: recipientSelected?.name ?? 'Unknown Recipient' }
  })
  return sortBy(scanPreferencesDisplayed, ({ recipient_name }) => recipient_name.toLowerCase()) // eslint-disable-line @typescript-eslint/naming-convention
}
export const getRecipientsList = (state: RootState): Array<{ id: string; name: string }> =>
  sortBy(state.settingsApp.scanPreferences.recipientsList, ({ name }) => name.toLowerCase())
export const getScanPreferencesReady = (state: RootState): boolean => {
  const { scanPreferencesInitialLoading, recipientsListInitialLoading } = state.settingsApp.scanPreferences
  return !scanPreferencesInitialLoading && !recipientsListInitialLoading
}

export default scanPreferencesSlice.reducer
