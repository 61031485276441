import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'
import {
  EmailNotifications,
  EmailNotificationTypes,
  NotificationSettings,
  StorageStatusNotificationsTypes,
  StorageNotificationSettings
} from 'apps/SettingsApp/interfaces/notifications.interface'

interface EmailNotificationsState {
  emailNotifications: EmailNotifications | undefined
  emailNotificationsStatus: RequestStatus
  emailNotificationsError: RequestError | undefined
}

const initialState: EmailNotificationsState = {
  emailNotifications: undefined,
  emailNotificationsStatus: RequestStatus.Pending,
  emailNotificationsError: undefined
}

export const fetchEmailNotifications = createAsyncThunk(
  'notifications/fetchEmailNotifications',
  async (inboxId: InboxId) => {
    const response: { data: EmailNotifications } = await apiClient.get(
      `/inboxes/${inboxId}/email-notification-settings`
    )
    return response.data
  }
)

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchEmailNotifications.fulfilled, (state, action) => {
        state.emailNotificationsStatus = RequestStatus.Success
        state.emailNotifications = action.payload
      })
      .addCase(fetchEmailNotifications.rejected, (state, action) => {
        state.emailNotificationsStatus = RequestStatus.Error
        state.emailNotifications = undefined
        state.emailNotificationsError = action.error.message
      })
  }
})

export const getEmailNotifications = (state: RootState): EmailNotifications | undefined =>
  state.settingsApp.notifications.emailNotifications
export const getEmailNotificationsReady = (state: RootState): boolean =>
  state.settingsApp.notifications.emailNotificationsStatus === RequestStatus.Success
export const getEmailNotificationValue =
  (emailNotificationKey: EmailNotificationTypes | null) =>
  (state: RootState): NotificationSettings => {
    const defaultSettings = {
      instant: { enabled: false },
      daily: { enabled: false },
      weekly: { enabled: false },
      monthly: { enabled: false }
    }
    if (emailNotificationKey == null || state.settingsApp.notifications.emailNotifications == null)
      return defaultSettings
    return state.settingsApp.notifications.emailNotifications[emailNotificationKey] ?? defaultSettings
  }

export const getStorageStatusNotificationsValue =
  (emailNotificationKey: StorageStatusNotificationsTypes | null) =>
  (state: RootState): StorageNotificationSettings => {
    const defaultSettings = { enabled: false, days_in_advance: 5 }
    if (emailNotificationKey == null || state.settingsApp.notifications.emailNotifications == null)
      return defaultSettings
    return state.settingsApp.notifications.emailNotifications[emailNotificationKey] ?? defaultSettings
  }

export default notificationsSlice.reducer
