import { combineReducers } from 'redux'
import { AnyAction, Reducer } from '@reduxjs/toolkit'
import shipmentsSlice from './shipments'

export type ShipmentsState = ReturnType<typeof combinedReducer>

const combinedReducer = combineReducers({
  shipments: shipmentsSlice
})

const shipmentsReducer: Reducer = (state: ShipmentsState, action: AnyAction): ShipmentsState => {
  return combinedReducer(state, action)
}

export default shipmentsReducer
