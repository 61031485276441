import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import {
  ScannedSettingsToEdit,
  DeliveredSettingsToEdit,
  DeliveredSettingsScanValue,
  AutomationScannedEventAction,
  MailClassesFilter,
  DeliveredActionType
} from 'interfaces/account_automation.interface'

export enum GetStartedAccountAutomationRules {
  ScanStore = 'ScanStore',
  ScanShred = 'ScanShred',
  Store = 'Store'
}

export const DELIVERED_DEFAULT_VALUE = {
  action: DeliveredActionType.Scan,
  scan: { scan_type: DeliveredSettingsScanValue.Color200DPI },
  filters: [
    { min_pages_count: 21 },
    {
      mail_classes: [
        MailClassesFilter.SecondClass,
        MailClassesFilter.MediaClass,
        MailClassesFilter.StandardMail,
        MailClassesFilter.BoundPrinted,
        MailClassesFilter.Periodical,
        MailClassesFilter.NonProfit
      ]
    }
  ]
}

interface GetStartedAccountAutomationState {
  rule: GetStartedAccountAutomationRules
  delivered: DeliveredSettingsToEdit
  scanned: ScannedSettingsToEdit
}

const initialState: GetStartedAccountAutomationState = {
  rule: GetStartedAccountAutomationRules.ScanStore,
  delivered: DELIVERED_DEFAULT_VALUE,
  scanned: { action: AutomationScannedEventAction.KeepItem, filters: [], integrations: [] }
}

export const accountAutomationSlice = createSlice({
  name: 'accountAutomation',
  initialState,
  reducers: {
    setSettingsToEdit(state, action: PayloadAction<GetStartedAccountAutomationState>) {
      state.rule = action.payload.rule
      state.delivered = action.payload.delivered
      state.scanned = action.payload.scanned
    }
  }
})

export const { setSettingsToEdit } = accountAutomationSlice.actions

export const getSettingsToEdit = (state: RootState): GetStartedAccountAutomationState =>
  state.getStartedApp.accountAutomation

export default accountAutomationSlice.reducer
