import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosInstance } from 'axios'
import { sortBy, uniq } from 'lodash'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import {
  AccountAutomationConnection,
  AccountAutomationSettings,
  AutomationCheckedDetectedEvent,
  AutomationDeliveredEvent,
  AutomationScannedEvent,
  AutomationScannedEventAction,
  DeliveredActionType
} from 'interfaces/account_automation.interface'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'
import { Recipient } from 'interfaces/recipient.interface'
import { DepositAccount, DepositAccountsList } from 'apps/SettingsApp/interfaces/deposit_account.interface'

interface FetchRecipientsList {
  inboxId: InboxId
  recipientIds: string[]
}

interface FetchIntegrationsListArgs {
  connectorApiClient: AxiosInstance | undefined
  connectionIds: string[]
}

interface FetchDepositAccountArgs {
  inboxId: InboxId
  depositAccountId?: string
}

interface AccountAutomationInitialState {
  accountAutomationSettings: AccountAutomationSettings
  accountAutomationSettingsStatus: RequestStatus
  accountAutomationSettingsError: RequestError | undefined
  accountAutomationSettingsInitialLoading: boolean
  integrationsList: AccountAutomationConnection[]
  integrationsListStatus: RequestStatus
  integrationsListInitialLoading: boolean
  depositAccount: DepositAccount | null
  depositAccountStatus: RequestStatus
  depositAccountInitialLoading: boolean
  recipientsList: Array<Pick<Recipient, 'name' | 'id'>>
  recipientsListStatus: RequestStatus
  recipientsListInitialLoading: boolean
  checkDepositBankAccountsList: DepositAccount[]
  checkDepositBankAccountsListStatus: RequestStatus
  checkDepositBankAccountsListInitialLoading: boolean
}

const initialState: AccountAutomationInitialState = {
  accountAutomationSettings: {
    delivered: {
      action: DeliveredActionType.None,
      scan: null,
      filters: [],
      recipient_ids: []
    },
    scanned: {
      action: AutomationScannedEventAction.KeepItem,
      integrations: [],
      filters: [],
      recipient_ids: []
    },
    check_detected: {
      recipient_ids: []
    }
  },
  accountAutomationSettingsStatus: RequestStatus.Pending,
  accountAutomationSettingsError: undefined,
  accountAutomationSettingsInitialLoading: true,
  integrationsList: [],
  integrationsListStatus: RequestStatus.Pending,
  integrationsListInitialLoading: true,
  depositAccount: null,
  depositAccountStatus: RequestStatus.Pending,
  depositAccountInitialLoading: true,
  recipientsList: [],
  recipientsListStatus: RequestStatus.Pending,
  recipientsListInitialLoading: true,
  checkDepositBankAccountsList: [],
  checkDepositBankAccountsListStatus: RequestStatus.Pending,
  checkDepositBankAccountsListInitialLoading: true
}

export const fetchAccountAutomationSettings = createAsyncThunk(
  'account/fetchAccountAutomationSettings',
  async (inboxId: InboxId) => {
    const response: { data: AccountAutomationSettings } = await apiClient.get(`/inboxes/${inboxId}/automation-settings`)
    return response.data
  }
)

export const fetchIntegrationsList = createAsyncThunk(
  'account/fetchIntegrationsList',
  async ({ connectorApiClient, connectionIds }: FetchIntegrationsListArgs) => {
    if (connectorApiClient == null || connectionIds.length === 0) return []
    const response: { data: AccountAutomationConnection[] } = await connectorApiClient.get('connector_connections', {
      params: {
        connection_ids: connectionIds.join()
      }
    })
    return response.data
  }
)

export const fetchRecipientsList = createAsyncThunk(
  'account/fetchIndividualRecipientsList',
  async ({ inboxId, recipientIds }: FetchRecipientsList) => {
    if (recipientIds.length === 0) return []
    const response: { data: Array<Pick<Recipient, 'name' | 'id'>> } = await apiClient.get(
      `/inboxes/${inboxId}/recipient-names`,
      {
        params: {
          recipient_ids: uniq(recipientIds).join()
        }
      }
    )
    return response.data
  }
)

export const fetchDepositAccount = createAsyncThunk(
  'account/fetchDepositAccount',
  async ({ inboxId, depositAccountId }: FetchDepositAccountArgs) => {
    if (depositAccountId == null) return null
    const response: { data: DepositAccount[] } = await apiClient.get(
      `/inboxes/${inboxId}/check-deposit-bank-accounts/list-selected`,
      {
        params: {
          account_ids: depositAccountId
        }
      }
    )
    return response.data[0]
  }
)

export const fetchCheckDepositBankAccountsList = createAsyncThunk(
  'account/fetchCheckDepositBankAccountsList',
  async (inboxId: InboxId) => {
    const response: { data: DepositAccountsList } = await apiClient.get(
      `/inboxes/${inboxId}/check-deposit-bank-accounts`
    )
    if (response.data.total <= response.data.data.length) return response.data
    const fullResponse = { ...response.data }
    let page = 1
    while (fullResponse.data.length < fullResponse.total) {
      page += 1
      const nextPageResponse: { data: DepositAccountsList } = await apiClient.get(
        `/inboxes/${inboxId}/check-deposit-bank-accounts?page=${page}`
      )
      fullResponse.data = [...fullResponse.data, ...nextPageResponse.data.data]
    }
    return fullResponse
  }
)

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearRecipientsList(state) {
      state.recipientsList = initialState.recipientsList
      state.recipientsListStatus = initialState.recipientsListStatus
      state.recipientsListInitialLoading = initialState.recipientsListInitialLoading
    },
    clearIntegrationsList(state) {
      state.integrationsList = initialState.integrationsList
      state.integrationsListStatus = initialState.integrationsListStatus
      state.integrationsListInitialLoading = initialState.integrationsListInitialLoading
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAccountAutomationSettings.fulfilled, (state, action) => {
        state.accountAutomationSettings = action.payload
        state.accountAutomationSettingsInitialLoading = false
        state.accountAutomationSettingsStatus = RequestStatus.Success
      })
      .addCase(fetchAccountAutomationSettings.pending, state => {
        state.accountAutomationSettingsStatus = RequestStatus.Pending
      })
      .addCase(fetchAccountAutomationSettings.rejected, (state, action) => {
        state.accountAutomationSettings = initialState.accountAutomationSettings
        state.accountAutomationSettingsStatus = RequestStatus.Error
        state.accountAutomationSettingsError = action.error.message
      })
      .addCase(fetchIntegrationsList.fulfilled, (state, action) => {
        state.integrationsList = action.payload
        state.integrationsListStatus = RequestStatus.Success
        state.integrationsListInitialLoading = false
      })
      .addCase(fetchIntegrationsList.pending, state => {
        state.integrationsListStatus = RequestStatus.Pending
      })
      .addCase(fetchIntegrationsList.rejected, state => {
        state.integrationsList = initialState.integrationsList
        state.integrationsListStatus = RequestStatus.Error
      })
      .addCase(fetchDepositAccount.fulfilled, (state, action) => {
        state.depositAccount = action.payload
        state.depositAccountStatus = RequestStatus.Success
        state.depositAccountInitialLoading = false
      })
      .addCase(fetchDepositAccount.pending, state => {
        state.depositAccountStatus = RequestStatus.Pending
      })
      .addCase(fetchDepositAccount.rejected, state => {
        state.depositAccount = initialState.depositAccount
        state.depositAccountStatus = RequestStatus.Error
      })
      .addCase(fetchRecipientsList.fulfilled, (state, action) => {
        state.recipientsList = action.payload
        state.recipientsListStatus = RequestStatus.Success
        state.recipientsListInitialLoading = false
      })
      .addCase(fetchRecipientsList.pending, state => {
        state.recipientsListStatus = RequestStatus.Pending
      })
      .addCase(fetchRecipientsList.rejected, state => {
        state.recipientsList = initialState.recipientsList
        state.recipientsListStatus = RequestStatus.Error
      })
      .addCase(fetchCheckDepositBankAccountsList.fulfilled, (state, action) => {
        state.checkDepositBankAccountsList = action.payload.data
        state.checkDepositBankAccountsListStatus = RequestStatus.Success
        state.checkDepositBankAccountsListInitialLoading = false
      })
      .addCase(fetchCheckDepositBankAccountsList.pending, state => {
        state.checkDepositBankAccountsListStatus = RequestStatus.Pending
      })
      .addCase(fetchCheckDepositBankAccountsList.rejected, state => {
        state.checkDepositBankAccountsList = initialState.checkDepositBankAccountsList
        state.checkDepositBankAccountsListStatus = RequestStatus.Error
      })
  }
})

export const { clearRecipientsList, clearIntegrationsList } = accountSlice.actions

export const getDeliveredSettings = (state: RootState): AutomationDeliveredEvent =>
  state.settingsApp.accountAutomation.account.accountAutomationSettings.delivered
export const getScannedSettings = (state: RootState): AutomationScannedEvent =>
  state.settingsApp.accountAutomation.account.accountAutomationSettings.scanned
export const getCheckDetectedSettings = (state: RootState): AutomationCheckedDetectedEvent =>
  state.settingsApp.accountAutomation.account.accountAutomationSettings.check_detected
export const getIntegrationsList = (state: RootState): AccountAutomationConnection[] =>
  state.settingsApp.accountAutomation.account.integrationsList
export const getIntegrationsListStatus = (state: RootState): RequestStatus =>
  state.settingsApp.accountAutomation.account.integrationsListStatus
export const getDepositAccount = (state: RootState): DepositAccount | null =>
  state.settingsApp.accountAutomation.account.depositAccount
export const getDepositAccountStatus = (state: RootState): RequestStatus =>
  state.settingsApp.accountAutomation.account.depositAccountStatus
export const getCheckDepositBankAccountsList = (state: RootState): DepositAccount[] =>
  sortBy(state.settingsApp.accountAutomation.account.checkDepositBankAccountsList, bankAccount =>
    (bankAccount.account_display_name ?? '').trim().toLowerCase()
  )
export const getCheckDepositBankAccountsListReady = (state: RootState): boolean =>
  state.settingsApp.accountAutomation.account.checkDepositBankAccountsListStatus === RequestStatus.Success
export const getRecipientsList = (state: RootState): Array<Pick<Recipient, 'name' | 'id'>> =>
  sortBy(state.settingsApp.accountAutomation.account.recipientsList, ['name'])
export const getRecipientsListReady = (state: RootState): boolean =>
  state.settingsApp.accountAutomation.account.recipientsListStatus === RequestStatus.Success
export const getRecipientIds = (state: RootState): string[] =>
  state.settingsApp.accountAutomation.account.recipientsList.map(({ id }) => String(id))
export const getAccountAutomationReady = (state: RootState): boolean => {
  const {
    accountAutomationSettingsInitialLoading,
    integrationsListInitialLoading,
    depositAccountInitialLoading,
    recipientsListInitialLoading,
    checkDepositBankAccountsListInitialLoading
  } = state.settingsApp.accountAutomation.account
  return (
    !checkDepositBankAccountsListInitialLoading &&
    !accountAutomationSettingsInitialLoading &&
    !recipientsListInitialLoading &&
    !integrationsListInitialLoading &&
    !depositAccountInitialLoading
  )
}

export default accountSlice.reducer
