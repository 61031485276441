export const amplitudeEvents = [
  'Sidemenu - Clicked Dashboard',
  'Sidemenu - Clicked Mailroom',
  'Sidemenu - Clicked Shipments',
  'Sidemenu - Clicked Deposits',
  'Sidemenu - Clicked Support',
  'Sidemenu - Clicked Settings',
  'Sidemenu - Clicked Get Started',
  'Sidemenu - Clicked Re-authorize',
  'Dashboard - Clicked View All items in Mailroom',
  'Dashboard - Clicked Mailroom Item',
  'Dashboard - Clicked Activity Item',
  'Dashboard - SS Scan Menu - View All Scanned Items',
  'Dashboard - SS Scan Menu - View All Unscanned Items',
  'Dashboard - SS Deposit Menu - View Items With Checks',
  'Dashboard - SS Deposit Menu - View Check Deposits',
  'Dashboard - SS Ship Menu - View All Shipments',
  'Dashboard - SS Store Menu - View All Stored Items',
  'Dashboard - SS Store Menu - View All Items Incurring Storage Fees',
  'Dashboard - SS Store Menu - Manage Reserved Storage',
  'Dashboard - SS Store Menu - Storage Policy',
  'Dashboard - Account Summary - Clicked Plan details',
  'Dashboard - Account Summary - Clicked Usage Over Time',
  'Dashboard - Account Summary - Clicked Statements',
  'Dashboard - Addresses - Copied to Clipboard',
  'Dashboard - Page Displayed',
  'Dashboard - Get Started - Continue Clicked',
  'Zendesk - Widget Opened',
  'Zendesk - Widget Closed',
  'Settings - Sidemenu - Clicked Account Management',
  'Settings - Sidemenu - Clicked Payment Details',
  'Settings - Sidemenu - Clicked My Addresses',
  'Settings - Sidemenu - Clicked Automation Rules',
  'Settings - Sidemenu - Clicked Account Automation',
  'Settings - Sidemenu - Clicked Deposit Accounts',
  'Settings - Sidemenu - Clicked Forward Rules',
  'Settings - Sidemenu - Clicked Manage Users',
  'Settings - Sidemenu - Clicked Integrations',
  'Settings - Sidemenu - Clicked Recipients',
  'Settings - Sidemenu - Clicked Scan Preferences',
  'Settings - Sidemenu - Clicked Shipping Addresses',
  'Settings - Sidemenu - Clicked Tags',
  'Settings - Sidemenu - Clicked Applicant Profile',
  'Settings - Sidemenu - Clicked Profile',
  'Settings - Sidemenu - Clicked Notifications',
  'Settings - Sidemenu - Clicked Security',
  'Settings - Security - Initialized MFA Method Setup',
  'Settings - Security - Copied Backup Code',
  'Settings - Security - Enabled MFA Method',
  'Settings - Security - Clicked Backup Code Action',
  'Settings - Security - Disabled MFA Method',
  'Settings - Security - Clicked Regenerate Backup Code',
  'Settings - Security - Regenerated Backup Code',
  'Settings - Security - Clicked Learn More Link',
  'Settings - Security - Clicked Manage Trusted Devices Link',
  'Settings - Security - Clicked Remove Trusted Device Link',
  'Settings - Integrations - Dashboard - Page Displayed',
  'Settings - Integrations - Dashboard - Clicked Go To Details',
  'Settings - Integrations - Dashboard - Clicked Generate Key Link',
  'Settings - Integrations - Details Page - Page Displayed',
  'Settings - Integrations - Details Page - Clicked Connect Account Link',
  'Settings - Integrations - Details Page - Clicked Rename Link',
  'Settings - Integrations - Details Page - Clicked Manage Link',
  'Settings - Integrations - Details Page - Clicked Disconnect Link',
  'Settings - Integrations - Details Page - Account Disconnected',
  'Settings - Integrations - Details Page - Clicked Learn More Link',
  'Settings - Notifications - Page Displayed',
  'Settings - Notifications - Clicked Update Notification',
  'Settings - Notifications - Notification Updated',
  'Settings - Tags - Page Displayed',
  'Settings - Tags - Used Search',
  'Settings - Tags - Deleted Tag',
  'Settings - Tags - Created Tag',
  'Settings - Recipients - Page Displayed',
  'Settings - Recipients - Added Recipient',
  'Settings - Recipients - Updated Recipient',
  'Settings - Recipients - Deleted Recipient',
  'Settings - Recipients - Added Alias',
  'Settings - Recipients - Deleted Alias',
  'Settings - Recipients1583 - Page Displayed',
  'Settings - Recipients1583 - Recipient Added',
  'Settings - Recipients1583 - Updated Recipient',
  'Settings - Recipients1583 - Personal Recipient Details - Page Displayed',
  'Settings - Recipients1583 - Recipient Deleted',
  'Settings - Shipping Addresses - Page Displayed',
  'Settings - Shipping Address - Deleted Address',
  'Settings - Shipping Addresses - Used Search',
  'Settings - Shipping Address - Added Address',
  'Mailroom - Shipment Drawer - Opened Drawer',
  'Mailroom - Shipping Drawer - New Address Created',
  'Mailroom - Shipping Drawer - Original Address Chosen',
  'Mailroom - Shipping Drawer - Recommended Address Chosen',
  'Mailroom - Shipping Drawer - Cancel Address Creation',
  'Mailroom - Shipping Drawer - Shipment Created',
  'Mailroom - Shipping Drawer - Shipment Updated',
  'Settings - Deposit Accounts - Page Displayed',
  'Settings - Deposit Accounts - Account Renamed',
  'Settings - Deposit Accounts - Account Deleted',
  'Settings - Deposit Accounts - Account Added',
  'Settings - Account Automation - Page Displayed',
  'Settings - Account Automation - Settings Created',
  'Settings - Account Automation - Settings Updated',
  'Settings - Account Automation - Delivered - Page Displayed',
  'Settings - Account Automation - Delivered - Deleted Recipient Settings',
  'Settings - Account Automation - Check detected - Page Displayed',
  'Settings - Account Automation - Check detected - Deleted Recipient Settings',
  'Settings - Account Automation - Scanned - Page Displayed',
  'Settings - Account Automation - Scanned - Deleted Recipient Settings',
  'Settings - Payment Details - Credit Card Updated',
  'Settings - Forward Rules - Page Displayed',
  'Settings - Forward Rules - Settings Changed',
  'Shipments - Page Displayed',
  'Shipments - Shipment Canceled',
  'Settings - Recipients - Recipient Added',
  'Settings - Scan Preferences - Page Displayed',
  'Settings - Scan Preferences - Preferences Added',
  'Settings - Scan Preferences - Preferences Deleted',
  'Settings - Scan Preferences - Preferences Updated',
  'Get Started - Authorize - Authorize Us Page Displayed',
  'Get Started - Authorize - Recipient Page Displayed',
  'Get Started - Authorize - Recipient Created',
  'Get Started - Authorize - Recipient Updated',
  'Get Started - Authorize - Recipient Page Continue Error',
  'Get Started - Authorize - Recipient Page Back Button Clicked',
  'Get Started - Authorize - Applicant Details Page Displayed',
  'Get Started - Authorize - Applicant Details Page Continue With Error',
  'Get Started - Authorize - Applicant Details Page Back Button Clicked',
  'Get Started - Authorize - Applicant Created',
  'Get Started - Authorize - Continue Clicked', // deprecated
  'Get Started - Authorize - Verification Method Page Back Button Clicked',
  'Get Started - Authorize - Verification Method Page Displayed',
  'Get Started - Authorize - Verification Method Selected',
  'Get Started - Automated Verification - Page Displayed',
  'Get Started - Automated Verification - Cancel Clicked',
  'Get Started - Automated Verification - No camera connected Page Displayed',
  'Get Started - Identity Verification - Capture Primary ID Front - Page Displayed',
  'Get Started - Identity Verification - ID Failed - Page Displayed',
  'Get Started - Identity Verification - ID Failed - Try Again Clicked',
  'Get Started - Identity Verification - Capture Primary ID Back - Page Displayed',
  'Get Started - Identity Verification - Capture Selfie - Page Displayed',
  'Get Started - Identity Verification - Review Selfie - Page Displayed',
  'Get Started - Identity Verification - Review Selfie - Retake Clicked',
  'Get Started - POA - Capture Secondary ID Front - Page Displayed',
  'Get Started - Sign 1583 - Page Displayed',
  'Get Started - Sign 1583 - Sign Form Clicked',
  'Get Started - Sign 1583 - Sign Form Clicked With Error',
  'Get Started - Connect with Notarize - Page Displayed',
  'Get Started - Connect with Notarize - Back Button Clicked',
  'Get Started - Authorize - Connect Clicked', // deprecated
  'Get Started - Connect with Notarize - Connect Clicked',
  'Get Started - Account Automation - Continue Clicked',
  'Get Started - Account Automation - Skip Clicked',
  'Get Started - Notify your contacts - Flow Done Clicked',
  'Settings - My Addresses - Page Displayed',
  'Settings - My Addresses - Added Address',
  'Settings - My Addresses - Deleted Address',
  'Get Started - Identity Verification - Capture Primary ID Front - Shutter Clicked',
  'Get Started - Identity Verification - Capture Primary ID Front - Upload Clicked',
  'Get Started - Identity Verification - Review Primary ID Front - Page Displayed',
  'Get Started - Identity Verification - Review Primary ID Front - Retake Clicked',
  'Get Started - Identity Verification - Capture Primary ID Back - Shutter Clicked',
  'Get Started - Identity Verification - Capture Primary ID Back - Upload Clicked',
  'Get Started - Identity Verification - Review Primary ID Back - Page Displayed',
  'Get Started - Identity Verification - Review Primary ID Back - Retake Clicked',
  'Get Started - Identity Verification - Capture Selfie - Shutter Clicked',
  'Get Started - Identity Verification Successful - Page Displayed',
  'Get Started - Identity Verification Successful - Secondary ID Changed',
  'Get Started - Identity Verification Unsuccessful - Page Displayed',
  'Get Started - Identity Verification Unsuccessful - Try Again Clicked',
  'Get Started - Identity Verification Unsuccessful - Connect with Notarize.com Clicked',
  'Get Started - Identity Verification Unsuccessful - Primary ID Changed',
  'Get Started - POA - Capture Secondary ID Front - Shutter Clicked',
  'Get Started - POA - Capture Secondary ID Front - Upload Clicked',
  'Get Started - POA - Review Secondary ID Front - Page Displayed',
  'Get Started - POA - Review Secondary ID Front - Retake Clicked',
  'Get Started - POA Successful - Page Displayed',
  'Get Started - POA Unsuccessful - Page Displayed',
  'Get Started - POA Unsuccessful - Try Again Clicked',
  'Get Started - POA Unsuccessful - Connect with Notarize.com Clicked',
  'Get Started - POA Unsuccessful - Use This Address Clicked',
  'Get Started - POA Unsuccessful - The same document was used',
  'Get Started - POA Unsuccessful - Secondary ID Changed & Try Again',
  'Account Reactivation - Page Displayed',
  'Account Reactivation - Clicked Update and Pay Button',
  'Account Reactivation - Clicked Statement',
  'Cancel Subscription - Cancel My Account Clicked',
  'Cancel Subscription - First modal - Continue Clicked',
  'Cancel Subscription - First modal - I changed my mind Clicked',
  'Cancel Subscription - Reason - Continue Clicked',
  'Cancel Subscription - Reason - I changed my mind Clicked',
  'Cancel Subscription - Cancellation Type - Continue Clicked',
  'Cancel Subscription - Cancellation Type - I changed my mind Clicked',
  'Cancel Subscription - Confirm Cancellation - Confirm Clicked',
  'Cancel Subscription - Confirm Cancellation - I changed my mind Clicked',
  'Cancel Subscription - Learn more Clicked',
  'Settings - Recipients1583 - Authorize - Verification Method Page Back Button Clicked',
  'Settings - Recipients1583 - Authorize - Verification Method Page Displayed',
  'Settings - Recipients1583 - Authorize - Verification Method Selected',
  'Settings - Recipients1583 - Automated Verification - Page Displayed',
  'Settings - Recipients1583 - Automated Verification - Cancel Clicked',
  'Settings - Recipients1583 - Automated Verification - No camera connected Page Displayed',
  'Settings - Recipients1583 - Identity Verification - Capture Primary ID Front - Page Displayed',
  'Settings - Recipients1583 - Identity Verification - ID Failed - Page Displayed',
  'Settings - Recipients1583 - Identity Verification - ID Failed - Try Again Clicked',
  'Settings - Recipients1583 - Identity Verification - Capture Primary ID Back - Page Displayed',
  'Settings - Recipients1583 - Identity Verification - Capture Selfie - Page Displayed',
  'Settings - Recipients1583 - Identity Verification - Review Selfie - Page Displayed',
  'Settings - Recipients1583 - Identity Verification - Review Selfie - Retake Clicked',
  'Settings - Recipients1583 - POA - Capture Secondary ID Front - Page Displayed',
  'Settings - Recipients1583 - Sign 1583 - Page Displayed',
  'Settings - Recipients1583 - Sign 1583 - Sign Form Clicked',
  'Settings - Recipients1583 - Sign 1583 - Sign Form Clicked With Error',
  'Settings - Recipients1583 - Connect with Notarize - Page Displayed',
  'Settings - Recipients1583 - Connect with Notarize - Back Button Clicked',
  'Settings - Recipients1583 - Connect with Notarize - Connect Clicked',
  'Settings - Recipients1583 - Identity Verification - Capture Primary ID Front - Shutter Clicked',
  'Settings - Recipients1583 - Identity Verification - Capture Primary ID Front - Upload Clicked',
  'Settings - Recipients1583 - Identity Verification - Review Primary ID Front - Page Displayed',
  'Settings - Recipients1583 - Identity Verification - Review Primary ID Front - Retake Clicked',
  'Settings - Recipients1583 - Identity Verification - Capture Primary ID Back - Shutter Clicked',
  'Settings - Recipients1583 - Identity Verification - Capture Primary ID Back - Upload Clicked',
  'Settings - Recipients1583 - Identity Verification - Review Primary ID Back - Page Displayed',
  'Settings - Recipients1583 - Identity Verification - Review Primary ID Back - Retake Clicked',
  'Settings - Recipients1583 - Identity Verification - Capture Selfie - Shutter Clicked',
  'Settings - Recipients1583 - Identity Verification Successful - Page Displayed',
  'Settings - Recipients1583 - Identity Verification Successful - Secondary ID Changed',
  'Settings - Recipients1583 - Identity Verification Unsuccessful - Page Displayed',
  'Settings - Recipients1583 - Identity Verification Unsuccessful - Try Again Clicked',
  'Settings - Recipients1583 - Identity Verification Unsuccessful - Connect with Notarize.com Clicked',
  'Settings - Recipients1583 - Identity Verification Unsuccessful - Primary ID Changed',
  'Settings - Recipients1583 - POA - Capture Secondary ID Front - Shutter Clicked',
  'Settings - Recipients1583 - POA - Capture Secondary ID Front - Upload Clicked',
  'Settings - Recipients1583 - POA - Review Secondary ID Front - Page Displayed',
  'Settings - Recipients1583 - POA - Review Secondary ID Front - Retake Clicked',
  'Settings - Recipients1583 - POA Successful - Page Displayed',
  'Settings - Recipients1583 - POA Unsuccessful - Page Displayed',
  'Settings - Recipients1583 - POA Unsuccessful - Try Again Clicked',
  'Settings - Recipients1583 - POA Unsuccessful - Connect with Notarize.com Clicked',
  'Settings - Recipients1583 - POA Unsuccessful - Use This Address Clicked',
  'Settings - Recipients1583 - POA Unsuccessful - The same document was used',
  'Settings - Recipients1583 - POA Unsuccessful - Secondary ID Changed & Try Again',
  'Re-authorization - Recipients - Opened',
  'Re-authorization - Recipient Type Page - Opened',
  'Re-authorization - Aliases Page - Opened',
  'Re-authorization - Recipient structure - Confirmed',
  'Re-authorization - Recipient Details - Opened',
  'Re-authorization - Applicant Details - Opened',
  'Re-authorization - Sign Form 1583 with Notary - Opened'
] as const

export type AmplitudeEvent = (typeof amplitudeEvents)[number]
