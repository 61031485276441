import { SyntheticEvent, memo } from 'react'
import { SnackbarCloseReason } from '@mui/material/Snackbar'
import { Grid, Slide, SlideProps } from '@mui/material'
import classNames from 'classnames'
import { AlertValue } from 'interfaces/common.interface'
import typography from 'styles/modules/typography.module.scss'
import StyledAlert from './Alert.components'
import styles from './Alert.module.scss'

export const ALERT_INITIAL_STATE: AlertValue = { message: '', show: false, type: 'success', isSubnavigation: false }

export const GENERAL_ERROR_ALERT: AlertValue = {
  isSubnavigation: false,
  show: true,
  message: 'Something went wrong, please try later or contact support.',
  type: 'error'
}

interface AlertProps extends AlertValue {
  setAlert: (value: AlertValue) => void
}

const Alert = ({ show, message, type, setAlert, isSubnavigation = false }: AlertProps): JSX.Element => {
  if (!show) return <></>

  const alertClassNames = classNames('alert', `alert__${type}`)
  const snackbarStyles = classNames(styles.snackbar, { [styles['snackbar--subnavigation']]: isSubnavigation })

  const clearAlert = (): void => setAlert(ALERT_INITIAL_STATE)

  const handleClose = (_event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason): void => {
    if (reason === 'clickaway') {
      return
    }
    clearAlert()
  }

  const TransitionDown = (props: SlideProps): JSX.Element => {
    return <Slide {...props} direction='down' />
  }

  return (
    <StyledAlert
      TransitionComponent={TransitionDown}
      open={show}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      className={snackbarStyles}
      data-test-alert
    >
      <Grid className={`${alertClassNames} alertMessage`} container justifyContent='center' alignItems='center'>
        <h4 className={classNames(typography.subheading2, styles.message)} data-test-alert-message>
          {message}
        </h4>
      </Grid>
    </StyledAlert>
  )
}

export default memo(Alert)
