import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { PaginatedList, RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'
import { DepositAccount } from 'apps/SettingsApp/interfaces/deposit_account.interface'

const PER_PAGE = 10

export interface DepositAccountsList extends PaginatedList<DepositAccount> {}

interface DepositAccountsState {
  depositAccountsPaginatedList: DepositAccountsList
  status: RequestStatus
  error: RequestError | undefined
}

interface FetchDepositAccountsArgs {
  inboxId: InboxId
  page?: number
}

const initialState: DepositAccountsState = {
  depositAccountsPaginatedList: {
    current_page: 1,
    data: [],
    from: 0,
    last_page: 0,
    per_page: PER_PAGE,
    to: 0,
    total: 0
  },
  status: RequestStatus.Pending,
  error: undefined
}

export const fetchDepositAccountsList = createAsyncThunk(
  'depositAccounts/fetchDepositAccountsList',
  async ({ inboxId, page }: FetchDepositAccountsArgs, { getState }) => {
    const state = getState() as RootState
    const currentPage = state.settingsApp.depositAccounts.depositAccountsPaginatedList.current_page
    const response: { data: DepositAccountsList } = await apiClient.get(
      `/inboxes/${inboxId}/check-deposit-bank-accounts-beta`,
      {
        params: {
          per_page: PER_PAGE,
          page: page ?? currentPage
        }
      }
    )
    return response.data
  }
)

export const depositAccountsSlice = createSlice({
  name: 'depositAccounts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDepositAccountsList.fulfilled, (state, action) => {
        state.status = RequestStatus.Success
        state.depositAccountsPaginatedList = action.payload
        state.error = undefined
      })
      .addCase(fetchDepositAccountsList.rejected, (state, action) => {
        state.status = RequestStatus.Error
        state.depositAccountsPaginatedList = initialState.depositAccountsPaginatedList
        state.error = action.error.message
      })
  }
})

export const getDepositAccountsList = (state: RootState): DepositAccount[] =>
  state.settingsApp.depositAccounts.depositAccountsPaginatedList.data
export const getDepositAccountsTotal = (state: RootState): number =>
  state.settingsApp.depositAccounts.depositAccountsPaginatedList.total
export const getDepositAccountsLastPage = (state: RootState): number =>
  state.settingsApp.depositAccounts.depositAccountsPaginatedList.last_page
export const getDepositAccountsCurrentPage = (state: RootState): number =>
  state.settingsApp.depositAccounts.depositAccountsPaginatedList.current_page
export const getDepositAccountsStatus = (state: RootState): RequestStatus => state.settingsApp.depositAccounts.status
export const getHasOneDepositAccountOnLastPage = (state: RootState): boolean =>
  state.settingsApp.depositAccounts.depositAccountsPaginatedList.from ===
  state.settingsApp.depositAccounts.depositAccountsPaginatedList.to

export default depositAccountsSlice.reducer
