import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { AccountId } from 'interfaces/account.interface'
import { Applicant } from 'interfaces/applicant.interface'

export interface ApplicantsState {
  applicants: Applicant[]
  status: RequestStatus
  error: RequestError | undefined
}

const initialState: ApplicantsState = {
  applicants: [],
  status: RequestStatus.Pending,
  error: undefined
}

export const fetchApplicantsList = createAsyncThunk('applicants/fetchApplicantsList', async (accountId: AccountId) => {
  const response: { data: Applicant[] } = await apiClient.get(`mail-authorization/accounts/${accountId}/applicants`)
  return response.data
})

export const applicantsSlice = createSlice({
  name: 'applicants',
  initialState,
  reducers: {
    resetApplicantsState: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(fetchApplicantsList.fulfilled, (state, action) => {
        state.status = RequestStatus.Success
        state.applicants = action.payload
        state.error = undefined
      })
      .addCase(fetchApplicantsList.pending, state => {
        state.status = RequestStatus.Pending
      })
      .addCase(fetchApplicantsList.rejected, (state, action) => {
        state.status = RequestStatus.Error
        state.applicants = initialState.applicants
        state.error = action.error.message
      })
  }
})

export default applicantsSlice.reducer

export const { resetApplicantsState } = applicantsSlice.actions
export const getApplicantsList = (state: RootState): Applicant[] => state.applicants.applicants
export const getApplicantsListReady = (state: RootState): boolean => state.applicants.status === RequestStatus.Success
export const findApplicant =
  (email: string) =>
  (state: RootState): Applicant | undefined =>
    state.applicants.applicants.find(({ user_email }) => user_email === email) // eslint-disable-line @typescript-eslint/naming-convention
