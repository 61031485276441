import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { MediaList, Media } from 'interfaces/media.interface'
import { RequestError, RequestStatus } from 'interfaces/common.interface'

interface MediaListState {
  mediaList: Media[]
  status: RequestStatus
  error: RequestError | undefined
}

const initialState: MediaListState = {
  mediaList: [],
  status: RequestStatus.Pending,
  error: undefined
}

export const fetchMediaList = createAsyncThunk('mediaList/fetchMediaList', async (pieceIds: number[]) => {
  const pieceIdsString: string = pieceIds.join(',')
  const response: { data: MediaList } = await apiClient.get(`/media?piece_id=${pieceIdsString}`)
  return response.data.data
})

export const mediaListSlice = createSlice({
  name: 'mediaList',
  initialState,
  reducers: {
    resetPiecesMediaState: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchMediaList.fulfilled, (state, action) => {
      state.status = RequestStatus.Success
      state.mediaList = action.payload
      state.error = undefined
    })
  }
})

export default mediaListSlice.reducer

export const { resetPiecesMediaState } = mediaListSlice.actions
export const getPiecesMediaList = (state: RootState): Media[] => state.dashboardApp.mediaList.mediaList
export const isMediaReady = (state: RootState): boolean => state.dashboardApp.mediaList.status === RequestStatus.Success
