import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { ShipmentsList, Shipment } from 'interfaces/shipment.interface'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'

interface ShipmentsState {
  shipmentsInProgress: ShipmentsList
  shipmentsInProgressStatus: RequestStatus
  shipmentsInProgressError: RequestError | undefined
}

const initialState: ShipmentsState = {
  shipmentsInProgress: {
    data: [],
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 25,
    to: 1,
    total: 0
  },
  shipmentsInProgressStatus: RequestStatus.Pending,
  shipmentsInProgressError: undefined
}

export const fetchShipmentsInProgress = createAsyncThunk(
  'shipments/fetchShipmentsInProgress',
  async (inboxId: InboxId) => {
    const response: { data: ShipmentsList } = await apiClient.get(
      `/inboxes/${inboxId}/shipments?operation_status=in_progress,pending&page=1&per_page=25&sort_by=ship_after&sort_direction=desc`
    )
    return response.data
  }
)

export const shipmentsSlice = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    resetShipmentState: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchShipmentsInProgress.fulfilled, (state, action) => {
      state.shipmentsInProgress = action.payload
      state.shipmentsInProgressStatus = RequestStatus.Success
      state.shipmentsInProgressError = undefined
    })
  }
})

export default shipmentsSlice.reducer

export const { resetShipmentState } = shipmentsSlice.actions
export const getShipmentsInProgressCount = (state: RootState): number =>
  state.dashboardApp.shipments.shipmentsInProgress.total
export const getShipmentsInProgress = (state: RootState): Shipment[] =>
  state.dashboardApp.shipments.shipmentsInProgress.data
export const isShipmentsReady = (state: RootState): boolean =>
  state.dashboardApp.shipments.shipmentsInProgressStatus === RequestStatus.Success
