import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { IndividualRecipient, BusinessRecipient, Recipients1583List } from 'interfaces/recipient.interface'
import { AccountId } from 'interfaces/account.interface'

export interface RecipientsState {
  recipientsList: Array<IndividualRecipient | BusinessRecipient>
  recipientsListStatus: RequestStatus
  recipientsListInitialLoading: boolean
  recipientsListError: RequestError | undefined
}

const initialState: RecipientsState = {
  recipientsList: [],
  recipientsListStatus: RequestStatus.Pending,
  recipientsListInitialLoading: true,
  recipientsListError: undefined
}

export const fetchRecipientsList = createAsyncThunk('recipients/fetchRecipientsList', async (accountId: AccountId) => {
  const response: { data: Recipients1583List } = await apiClient.get(
    `mail-authorization/accounts/${String(accountId)}/recipients`
  )
  return response.data
})

export const recipientsSlice = createSlice({
  name: 'recipients',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchRecipientsList.fulfilled, (state, action) => {
        state.recipientsListStatus = RequestStatus.Success
        state.recipientsList = action.payload.recipients
        state.recipientsListInitialLoading = false
        state.recipientsListError = undefined
      })
      .addCase(fetchRecipientsList.pending, state => {
        state.recipientsListStatus = RequestStatus.Pending
      })
      .addCase(fetchRecipientsList.rejected, (state, action) => {
        state.recipientsListStatus = RequestStatus.Error
        state.recipientsList = []
        state.recipientsListError = action.error.message
      })
  }
})

export const getRecipientsList = (state: RootState): Array<IndividualRecipient | BusinessRecipient> =>
  state.recipients.recipientsList
export const getHasRecipients = (state: RootState): boolean =>
  state.recipients.recipientsList.filter(({ flow_version }) => flow_version === 'v2').length > 0
export const getRecipientsListReady = (state: RootState): boolean =>
  state.recipients.recipientsListStatus === RequestStatus.Success
export const getIsRecipientsListInitialLoading = (state: RootState): boolean =>
  state.recipients.recipientsListInitialLoading

export default recipientsSlice.reducer
