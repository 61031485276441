import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import getApiErrorSerializer from 'utils/get_api_error_serializer'
import { RequestStatus } from 'interfaces/common.interface'
import { AuthorizationDocument } from 'interfaces/authorization_document.interface'

interface AuthorizeRecipientResponse {
  documents: AuthorizationDocument[]
  signature: string
}

interface ESignState {
  requestStatus: RequestStatus
  requestError?: string
  response?: AuthorizeRecipientResponse
}

const initialState: ESignState = {
  requestStatus: RequestStatus.Pending
}

interface AuthorizeWithESignParams {
  accountId: number
  applicantUpdateMode?: boolean
  recipientId?: string
  preview: boolean
  signature?: string
}

export const authorizeWithESign = createAsyncThunk(
  'eSign/authorizeWithESign',
  async ({ accountId, recipientId, applicantUpdateMode, ...rest }: AuthorizeWithESignParams) => {
    const params = applicantUpdateMode && recipientId ? { ...rest, recipient_id: recipientId } : rest
    const queryParams = new URLSearchParams(Object.entries(params).map(([key, value]) => [key, String(value)]))
    const urlRoot = `mail-authorization/accounts/${accountId}`
    const url = applicantUpdateMode
      ? `${urlRoot}/applicant/draft/authorization?${queryParams.toString()}`
      : `${urlRoot}/recipients/${recipientId}/authorization?${queryParams.toString()}`

    const { data } = await apiClient.post<AuthorizeRecipientResponse>(url)

    return data
  },
  {
    serializeError: getApiErrorSerializer({
      defaultMessage: 'An unexpected error occurred. Please try again later or contact support.'
    })
  }
)

export const eSignSlice = createSlice({
  name: 'eSign',
  initialState,
  reducers: {
    resetESign: () => initialState
  },
  extraReducers: builder =>
    builder
      .addCase(authorizeWithESign.pending, state => ({
        ...state,
        requestError: undefined,
        requestStatus: RequestStatus.Loading
      }))
      .addCase(authorizeWithESign.fulfilled, (state, action) => ({
        ...state,
        requestStatus: RequestStatus.Success,
        response: action.payload
      }))
      .addCase(authorizeWithESign.rejected, (_, action) => ({
        requestStatus: RequestStatus.Error,
        requestError: action.error.message
      }))
})

export const { resetESign } = eSignSlice.actions

export const getIsESignLoading = (state: RootState): boolean =>
  state.settingsApp.eSign.requestStatus === RequestStatus.Loading
export const getESignResponse = (state: RootState): AuthorizeRecipientResponse | undefined =>
  state.settingsApp.eSign.response
export const getIsESigned = (state: RootState): boolean => !!state.settingsApp.eSign.response?.signature
export const getESignErrorMessage = (state: RootState): string | undefined => state.settingsApp.eSign.requestError

export default eSignSlice.reducer
