export interface PaginatedList<T> {
  data: T[]
  current_page: number
  from: number
  last_page: number
  per_page: number
  to: number
  total: number
}

export enum RequestStatus {
  Pending = 'pending',
  Loading = 'loading',
  Success = 'success',
  Error = 'error'
}

export type RequestError = string

export interface AlertValue {
  isSubnavigation?: boolean
  message: string
  show: boolean
  type: 'error' | 'success'
}

export enum IdpProviders {
  Google = 'Google',
  Apple = 'Apple',
  LegalZoom = 'LegalZoom'
}
