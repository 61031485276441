import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { PaginatedList, RequestError, RequestStatus } from 'interfaces/common.interface'
import { Recipient } from 'interfaces/recipient.interface'
import { InboxId } from 'interfaces/inbox.interface'

const PER_PAGE = 100

export interface RecipientsList extends PaginatedList<Recipient> {}

interface RecipientsState {
  recipientsPaginatedList: RecipientsList
  status: RequestStatus
  error: RequestError | undefined
  allRecipientsList: Recipient[]
  allRecipientsStatus: RequestStatus
  allRecipientsError: RequestError | undefined
}

interface FetchRecipientsArgs {
  inboxId: InboxId
  page?: number
}

const initialState: RecipientsState = {
  recipientsPaginatedList: {
    current_page: 1,
    data: [],
    from: 0,
    last_page: 0,
    per_page: PER_PAGE,
    to: 0,
    total: 0
  },
  status: RequestStatus.Pending,
  error: undefined,
  allRecipientsList: [],
  allRecipientsStatus: RequestStatus.Pending,
  allRecipientsError: undefined
}

async function getRecipients(inboxId: number, params: { per_page: number; page: number }): Promise<RecipientsList> {
  const { data } = await apiClient.get(`/inboxes/${inboxId}/recipients`, {
    params: {
      payload_type: 1,
      ...params
    }
  })
  return data
}

export const fetchRecipientsList = createAsyncThunk(
  'recipients/fetchRecipientsList',
  async ({ inboxId, page }: FetchRecipientsArgs, { getState }) => {
    const state = getState() as RootState
    const currentPage = state.settingsApp.recipients.recipientsPaginatedList.current_page
    return getRecipients(inboxId, { per_page: PER_PAGE, page: page ?? currentPage })
  }
)

export const fetchAllRecipientsList = createAsyncThunk(
  'recipients/fetchAllRecipientsList',
  async ({ inboxId }: { inboxId: InboxId }) => {
    async function fetchNext(previousRecipients: Recipient[], pageNumber: number): Promise<Recipient[]> {
      const list = await getRecipients(inboxId, { per_page: PER_PAGE, page: pageNumber })
      const recipients = [previousRecipients, list.data].flat()

      if (list.current_page < list.last_page) {
        return fetchNext(recipients, list.current_page + 1)
      }

      return recipients
    }

    return fetchNext([], 1)
  }
)

export const recipientsSlice = createSlice({
  name: 'recipients',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchRecipientsList.fulfilled, (state, action) => {
        state.status = RequestStatus.Success
        state.recipientsPaginatedList = action.payload
        state.error = undefined
      })
      .addCase(fetchRecipientsList.pending, state => {
        state.status = RequestStatus.Pending
      })
      .addCase(fetchRecipientsList.rejected, (state, action) => {
        state.status = RequestStatus.Error
        state.recipientsPaginatedList = initialState.recipientsPaginatedList
        state.error = action.error.message
      })
      .addCase(fetchAllRecipientsList.fulfilled, (state, action) => {
        state.allRecipientsStatus = RequestStatus.Success
        state.allRecipientsList = action.payload
        state.allRecipientsError = undefined
      })
      .addCase(fetchAllRecipientsList.pending, state => {
        state.allRecipientsStatus = RequestStatus.Pending
      })
      .addCase(fetchAllRecipientsList.rejected, (state, action) => {
        state.allRecipientsStatus = RequestStatus.Error
        state.allRecipientsList = initialState.allRecipientsList
        state.allRecipientsError = action.error.message
      })
  }
})

export const getRecipientsList = (state: RootState): Recipient[] =>
  state.settingsApp.recipients.recipientsPaginatedList.data
export const getRecipientsTotal = (state: RootState): number =>
  state.settingsApp.recipients.recipientsPaginatedList.total
export const getRecipientsLastPage = (state: RootState): number =>
  state.settingsApp.recipients.recipientsPaginatedList.last_page
export const getRecipientsCurrentPage = (state: RootState): number =>
  state.settingsApp.recipients.recipientsPaginatedList.current_page
export const getRecipientsStatus = (state: RootState): RequestStatus => state.settingsApp.recipients.status
export const getAllRecipients = (state: RootState): Recipient[] => state.settingsApp.recipients.allRecipientsList
export const getAllRecipientsStatus = (state: RootState): RequestStatus =>
  state.settingsApp.recipients.allRecipientsStatus

export default recipientsSlice.reducer
