import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { pick } from 'lodash'
import { format } from 'date-fns'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import {
  ShipmentsList,
  Shipment,
  ShipmentItem,
  ShippingAddress,
  ShipmentCustomItem
} from 'interfaces/shipment.interface'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'
import { Piece } from 'interfaces/piece.interface'
import { Media, MediaList } from 'interfaces/media.interface'
import { RateRequestArgs } from 'apps/MailroomApp/interfaces/rates.interface'

const PER_PAGE = 100
const ITN_THRESHOLD_VALUE_IN_CENTS = 250_000
export const DECLARED_MIN_VALUE_IN_CENTS = 100
export const DECLARED_MAX_VALUE_IN_CENTS = 5_000_000

export interface DrawerShipmentItem extends Omit<ShipmentItem, 'has_complete_scan'> {}

export interface DrawerShipment
  extends Pick<
    Shipment,
    | 'aes_itn'
    | 'contents_type'
    | 'customs_description'
    | 'declared_value_in_cents'
    | 'is_shared'
    | 'is_insured'
    | 'signature_on_delivery'
    | 'rate_id'
    | 'ship_after'
    | 'shipping_comment'
  > {
  delete_scans?: boolean
  id?: number
  items: DrawerShipmentItem[]
  custom_items: ShipmentCustomItem[]
  shipping_address: ShippingAddress | null
}

interface ShipmentDrawerState extends Record<string, any> {
  pendingShipments: ShipmentsList
  pendingShipmentsStatus: RequestStatus
  pendingShipmentsError: RequestError | undefined
  selectedPieceIds: number[]
  piecesList: Piece[]
  piecesListStatus: RequestStatus
  media: Media[]
  mediaStatus: RequestStatus
  shipment: DrawerShipment
}

interface PendingShipmentsParams {
  inboxId: InboxId
  page?: number
}

interface PiecesPromise {
  data: Piece
}

interface PreparedShipmentData
  extends Omit<
    DrawerShipment,
    'shipping_address' | 'is_insured' | 'signature_on_delivery' | 'contents_type' | 'customs_description'
  > {
  shipping_address_id: number
  contents_type: string | null
}

const SHIPMENT_INITIAL_STATE: DrawerShipment = {
  aes_itn: null,
  contents_type: '',
  customs_description: '',
  declared_value_in_cents: null,
  delete_scans: false,
  is_shared: false,
  is_insured: false,
  signature_on_delivery: false,
  items: [],
  custom_items: [],
  rate_id: '',
  ship_after: new Date().toISOString(),
  shipping_address: null,
  shipping_comment: null
}

const initialState: ShipmentDrawerState = {
  pendingShipments: {
    data: [],
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: PER_PAGE,
    to: 1,
    total: 0
  },
  pendingShipmentsStatus: RequestStatus.Pending,
  pendingShipmentsError: undefined,
  selectedPieceIds: [],
  piecesList: [],
  piecesListStatus: RequestStatus.Pending,
  media: [],
  mediaStatus: RequestStatus.Pending,
  shipment: SHIPMENT_INITIAL_STATE
}

export const fetchPendingShipments = createAsyncThunk(
  'shipmentDrawer/fetchPendingShipments',
  async ({ inboxId, page }: PendingShipmentsParams, { getState }) => {
    const state = getState() as RootState
    const currentPagePendingShipments = state.mailroomApp.shipmentDrawer.pendingShipments.current_page
    const response: { data: ShipmentsList } = await apiClient.get(`/inboxes/${inboxId}/shipments`, {
      params: {
        operation_status: 'pending',
        page: page ?? currentPagePendingShipments,
        per_page: PER_PAGE
      }
    })
    return response.data
  }
)

export const fetchPieces = createAsyncThunk('shipmentDrawer/fetchPieces', async (_, { getState }) => {
  const piecesList: Piece[] = []
  const promises: Array<Promise<PiecesPromise>> = []
  const state = getState() as RootState
  const piecesIds = state.mailroomApp.shipmentDrawer.shipment.items.map(({ piece_id }) => piece_id) // eslint-disable-line @typescript-eslint/naming-convention
  piecesIds.forEach(id => {
    promises.push(apiClient.get(`/pieces/${id}`))
  })
  await Promise.all(promises).then(responses => {
    responses.forEach(response => piecesList.push(response.data))
  })
  return piecesList
})

export const fetchMediaList = createAsyncThunk('shipmentDrawer/fetchMediaList', async (_, { getState }) => {
  const state = getState() as RootState
  const piecesIds = state.mailroomApp.shipmentDrawer.shipment.items.map(({ piece_id }) => piece_id).join(',') // eslint-disable-line @typescript-eslint/naming-convention
  const response: { data: MediaList } = await apiClient.get(`/media?piece_id=${piecesIds}`)
  return response.data.data
})

export const shipmentsSlice = createSlice({
  name: 'shipmentDrawer',
  initialState,
  reducers: {
    setSelectedPieceIds(state, action: PayloadAction<number[]>) {
      state.selectedPieceIds = action.payload
    },
    setShipmentInitialState(state) {
      state.shipment = {
        ...SHIPMENT_INITIAL_STATE,
        items: state.selectedPieceIds.map(pieceId => ({ piece_id: pieceId }))
      }
    },
    updateShipment(state, action: PayloadAction<Partial<DrawerShipment>>) {
      const valuesToUpdate = pick(action.payload, [
        'aes_itn',
        'contents_type',
        'customs_description',
        'declared_value_in_cents',
        'delete_scans',
        'id',
        'is_shared',
        'is_insured',
        'rate_id',
        'signature_on_delivery',
        'ship_after',
        'shipping_address_id',
        'shipping_address',
        'shipping_comment'
      ])
      const updatedCustomItems = action.payload.custom_items?.map(
        (
          { description, declared_value_in_cents, hs_tariff_code } // eslint-disable-line @typescript-eslint/naming-convention
        ) => ({
          description,
          declared_value_in_cents,
          hs_tariff_code
        })
      ) ?? [...state.shipment.custom_items]
      const updatedItems =
        action.payload.items?.map(
          (
            { piece_id } // eslint-disable-line @typescript-eslint/naming-convention
          ) => ({
            piece_id
          })
        ) ?? []
      const updatedItemsIds = updatedItems.map(({ piece_id }) => piece_id)
      const notUpdatedItems = state.shipment.items.filter(({ piece_id }) => !updatedItemsIds.includes(piece_id)) // eslint-disable-line @typescript-eslint/naming-convention
      state.shipment = {
        ...state.shipment,
        ...valuesToUpdate,
        custom_items: [...updatedCustomItems],
        items: [...notUpdatedItems, ...updatedItems]
      }
    },
    clearPiecesList(state) {
      state.piecesList = []
    },
    clearMediaList(state) {
      state.media = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPendingShipments.pending, state => {
        state.pendingShipmentsStatus = RequestStatus.Pending
      })
      .addCase(fetchPendingShipments.fulfilled, (state, action) => {
        state.pendingShipments = action.payload
        state.pendingShipmentsStatus = RequestStatus.Success
        state.pendingShipmentsError = undefined
      })
      .addCase(fetchPieces.fulfilled, (state, action) => {
        state.piecesList = action.payload
        state.piecesListStatus = RequestStatus.Success
      })
      .addCase(fetchPieces.pending, state => {
        state.piecesListStatus = RequestStatus.Pending
      })
      .addCase(fetchMediaList.fulfilled, (state, action) => {
        state.media = action.payload
        state.mediaStatus = RequestStatus.Success
      })
      .addCase(fetchMediaList.pending, state => {
        state.mediaStatus = RequestStatus.Pending
      })
  }
})
export const { setSelectedPieceIds, setShipmentInitialState, updateShipment, clearPiecesList, clearMediaList } =
  shipmentsSlice.actions

export const getPendingShipmentsList = (state: RootState): Shipment[] =>
  state.mailroomApp.shipmentDrawer.pendingShipments.data
export const isPendingShipmentsListReady = (state: RootState): boolean =>
  state.mailroomApp.shipmentDrawer.pendingShipmentsStatus === RequestStatus.Success
export const getPendingShipmentsListExists = (state: RootState): boolean =>
  isPendingShipmentsListReady(state) && getPendingShipmentsList(state).length > 0
export const getSelectedPieceIds = (state: RootState): number[] => state.mailroomApp.shipmentDrawer.selectedPieceIds
export const getPendingShipmentsCurrentPage = (state: RootState): number =>
  state.mailroomApp.shipmentDrawer.pendingShipments.current_page
export const getPendingShipmentsLastPage = (state: RootState): number =>
  state.mailroomApp.shipmentDrawer.pendingShipments.last_page
export const getPiecesListAndMediaReady = (state: RootState): boolean =>
  state.mailroomApp.shipmentDrawer.piecesListStatus === RequestStatus.Success &&
  state.mailroomApp.shipmentDrawer.mediaStatus === RequestStatus.Success
export const getShipmentDrawerItemImage =
  (pieceId: number) =>
  (state: RootState): string | undefined =>
    state.mailroomApp.shipmentDrawer.media.find(({ piece_id }) => pieceId === piece_id)?.url // eslint-disable-line @typescript-eslint/naming-convention
export const getShipmentDrawerPiecesList = (state: RootState): Piece[] => state.mailroomApp.shipmentDrawer.piecesList
export const getShipment = (state: RootState): DrawerShipment => state.mailroomApp.shipmentDrawer.shipment
export const getIsShipmentInternational = (state: RootState): boolean =>
  state.mailroomApp.shipmentDrawer.shipment.shipping_address?.tags.includes('international') ?? false
export const getRequiresITN = (state: RootState): boolean =>
  state.mailroomApp.shipmentDrawer.shipment.shipping_address?.requires_itn ?? true
export const getShipmentTotalDeclaredValueInCents = (state: RootState): number => {
  const shipment = state.mailroomApp.shipmentDrawer.shipment
  const isShipmentInternational = shipment.shipping_address?.tags.includes('international')
  const declaredValueInCents = shipment.declared_value_in_cents ?? 0
  return isShipmentInternational === true
    ? shipment.custom_items.reduce((acc, curr) => acc + curr.declared_value_in_cents, 0)
    : declaredValueInCents
}

export const getRatesRequestArgs = (state: RootState): RateRequestArgs | null => {
  const shipment = state.mailroomApp.shipmentDrawer.shipment
  const shippingAddressId = shipment.shipping_address?.id
  if (shippingAddressId == null) return null

  const declaredValueInCents = shipment.declared_value_in_cents ?? 0
  const rateRequestData = {
    is_insured: shipment.is_insured,
    declared_value_in_cents: declaredValueInCents,
    items: shipment.items,
    custom_items: shipment.custom_items,
    signature_on_delivery: shipment.signature_on_delivery
  }

  return {
    shippingAddressId,
    rateRequestData
  }
}

export const getUserHasPermissionToUpdateShipment =
  (shipmentId: number) =>
  (state: RootState): boolean => {
    const shipmentsList = getPendingShipmentsList(state)
    const shipmentToUpdate = shipmentsList.find(({ id }) => id === shipmentId)
    const userId = state.currentUser.currentUser?.id
    return (
      shipmentToUpdate != null &&
      userId != null &&
      (shipmentToUpdate.is_shared || shipmentToUpdate.owner_user_id === userId.toString())
    )
  }

export const getPreparedShipmentData = (state: RootState): PreparedShipmentData | undefined => {
  const shipment = state.mailroomApp.shipmentDrawer.shipment
  if (shipment.shipping_address == null) return
  return {
    aes_itn: shipment.aes_itn,
    is_shared: shipment.is_shared,
    contents_type: shipment.contents_type !== '' ? shipment.contents_type : null,
    declared_value_in_cents: shipment.declared_value_in_cents,
    rate_id: shipment.rate_id,
    shipping_address_id: shipment.shipping_address?.id,
    shipping_comment: shipment.shipping_comment,
    ship_after: format(new Date(shipment.ship_after), 'y-MM-dd'),
    delete_scans: shipment.delete_scans,
    items: shipment.items,
    custom_items: shipment.custom_items
  }
}

export const getShipmentAdditionalInformationValid = (state: RootState): boolean => {
  const shipment = state.mailroomApp.shipmentDrawer.shipment

  // declared value validation
  const isInsured = shipment.is_insured
  const shipmentTotalDeclaredValueInCents = getShipmentTotalDeclaredValueInCents(state)
  if (
    isInsured &&
    (shipmentTotalDeclaredValueInCents < DECLARED_MIN_VALUE_IN_CENTS ||
      shipmentTotalDeclaredValueInCents > DECLARED_MAX_VALUE_IN_CENTS)
  )
    return false
  if (
    !isInsured &&
    (shipmentTotalDeclaredValueInCents === 0 || shipmentTotalDeclaredValueInCents > DECLARED_MAX_VALUE_IN_CENTS)
  )
    return false

  // AES Itn validation
  const isInternational = shipment.shipping_address?.tags.includes('international') ?? false
  const requiresITN = getRequiresITN(state)

  return (
    !isInternational ||
    !requiresITN ||
    (shipment.custom_items.length > 0 &&
      (shipmentTotalDeclaredValueInCents <= ITN_THRESHOLD_VALUE_IN_CENTS || !!shipment.aes_itn))
  )
}

export default shipmentsSlice.reducer
