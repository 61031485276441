import { createSlice, createAsyncThunk, AnyAction } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestStatus } from 'interfaces/common.interface'
import { Insurance, Rate, RateRequestArgs, RateResponse } from 'apps/MailroomApp/interfaces/rates.interface'

interface Error {
  code: number
  message: string
}

interface RatesState {
  ratesList: Rate[]
  insurance?: Insurance
  ratesStatus: RequestStatus
  error: Error | undefined
}

const initialState: RatesState = {
  ratesList: [],
  ratesStatus: RequestStatus.Pending,
  error: undefined
}

export const fetchRates = createAsyncThunk(
  'rates/fetchRates',
  async ({ shippingAddressId, rateRequestData }: RateRequestArgs, { rejectWithValue }) => {
    try {
      const response: { data: RateResponse } = await apiClient.post(
        `shipping-addresses/${shippingAddressId}/rate`,
        rateRequestData
      )
      return response.data
    } catch (error: any) {
      if (error.response == null) {
        throw error
      }

      return rejectWithValue(error.response.data)
    }
  }
)

export const ratesSlice = createSlice({
  name: 'rates',
  initialState,
  reducers: {
    clearRatesList(state) {
      state.ratesList = []
      state.ratesStatus = RequestStatus.Pending
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRates.pending, state => {
        state.ratesStatus = RequestStatus.Pending
      })
      .addCase(fetchRates.fulfilled, (state, action) => {
        state.ratesList = action.payload.rates
        state.insurance = action.payload.insurance
        state.ratesStatus = RequestStatus.Success
        state.error = initialState.error
      })
      .addCase(fetchRates.rejected, (state, action: AnyAction) => {
        state.ratesList = initialState.ratesList
        state.ratesStatus = RequestStatus.Error
        state.error = action.payload.error
      })
  }
})
export const { clearRatesList } = ratesSlice.actions

export const getRatesListToSelect = (state: RootState): Rate[] => state.mailroomApp.rates.ratesList
export const getInsurance = (state: RootState): Insurance | null => state.mailroomApp.rates.insurance ?? null
export const getIsRatesListReady = (state: RootState): boolean =>
  state.mailroomApp.rates.ratesStatus !== RequestStatus.Pending
export const getRatesErrorMessage = (state: RootState): string =>
  state.mailroomApp.rates.error?.message.substring(state.mailroomApp.rates.error?.message?.indexOf(':') + 1) ?? ''
export const getRatesStatus = (state: RootState): RequestStatus => state.mailroomApp.rates.ratesStatus

export default ratesSlice.reducer
