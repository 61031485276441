import { combineReducers } from 'redux'
import { AnyAction, Reducer } from '@reduxjs/toolkit'
import piecesListReducer from './pieces_list'
import mediaListReducer from './media_list'
import activitiesListReducer from './activities'
import billingInfoReducer from './billing_info'
import statementReducer from './statements'
import recipientsReducer from './recipients'
import storedPiecesReducer from './stored_pieces'
import newScansReducer from './new_scans'
import uncompletedScansReducer from './uncompleted_scans'
import checksReducer from './checks'
import shipmentsReducer from './shipments'
import addressDocumentsReducer from './address_documents'

export type DashboardState = ReturnType<typeof combinedReducer>

export const DASHBOARD_RESET_APP_ACTION = 'dashboard/reset'

const combinedReducer = combineReducers({
  activitiesList: activitiesListReducer,
  billingInfo: billingInfoReducer,
  statement: statementReducer,
  piecesList: piecesListReducer,
  mediaList: mediaListReducer,
  recipients: recipientsReducer,
  storedPieces: storedPiecesReducer,
  newScans: newScansReducer,
  uncompletedScans: uncompletedScansReducer,
  checks: checksReducer,
  shipments: shipmentsReducer,
  addressDocuments: addressDocumentsReducer
})

const dashboardReducer: Reducer = (state: DashboardState, action: AnyAction): DashboardState => {
  if (action.type === DASHBOARD_RESET_APP_ACTION) {
    return combinedReducer(undefined, action)
  }
  return combinedReducer(state, action)
}

export default dashboardReducer
