import { TempPersonalRecipientDetails } from 'hooks/useBusinessRecipient'
import { AuthorizationDocument } from 'interfaces/authorization_document.interface'
import { InboxPieceCounts } from './inbox.interface'
import { PaginatedList } from './common.interface'

export type RecipientStatus = 'authorized'

export enum RecipientType {
  INDIVIDUAL = 'individual',
  BUSINESS = 'business'
}

export interface RecipientAlias {
  id: number
  name: string
  type: string
}

export interface Recipient {
  aliases: RecipientAlias[] // null
  created_at: string // "2021-04-16T14:32:28Z"
  ecm_number: string // "94575"
  id: number // 248694
  is_new_pmb: boolean // false
  name: string // "Zedd Worthy"
  piece_counts: InboxPieceCounts
  type: string | null // "recipient" || null
  updated_at: string // "2021-04-16T14:32:28Z"
}

export interface IndividualMinor {
  id?: string
  name: string
  date_of_birth: string
}

export interface IndividualRecipientDetails {
  minors: IndividualMinor[]
}

export interface IndividualRecipient {
  created_at: string
  id: string
  name: string
  status: RecipientStatus
  type: RecipientType.INDIVIDUAL
  update_at: string
  flow_version: 'v1' | 'v2'
  verified_at?: string
  personal_details: TempPersonalRecipientDetails
  user_id: string
}

export interface BusinessAddress {
  address_line_1?: string
  address_line_2?: string
  city: string
  country: string
  state: string
  zip_code: string
}

export interface BusinessMember {
  id?: string
  name: string
}

export interface BusinessRegistrationDetails {
  date: string
  state: string
  county: string
}

export interface BusinessRecipientDetails {
  applicants_role: string
  business_address: BusinessAddress
  business_address_id?: string
  business_phone: string
  business_type: string
  is_registered_business: boolean
  is_registered_as_corporation: boolean
  registration_details?: BusinessRegistrationDetails
  members: BusinessMember[]
  corporate_officers: string[]
}

export interface BusinessRecipient {
  business_details: BusinessRecipientDetails
  created_at: string
  id: string
  name: string
  status: RecipientStatus
  type: RecipientType.BUSINESS
  update_at: string
  flow_version: 'v1' | 'v2'
  verified_at?: string
  user_id: string
}

export interface Recipients1583List {
  next_page_token: string
  recipients: Array<IndividualRecipient | BusinessRecipient>
}

export interface RecipientAuthorization {
  authorized_at: string
  applicant_id: string
  applicant_name: string
  documents: AuthorizationDocument[]
  signature: string
}

export interface CurrentIndividualRecipient extends IndividualRecipient {
  authorization?: RecipientAuthorization
}

export interface CurrentBusinessRecipient extends BusinessRecipient {
  authorization?: RecipientAuthorization
}

export interface BusinessRecipientState {
  name: string
  applicants_role: string
  members: BusinessMember[] | Array<Pick<BusinessMember, 'name'>>
  is_registered_business: boolean
  is_registered_as_corporation: boolean
  corporate_officers: string[]
  registration_details?: BusinessRegistrationDetails
  business_address: BusinessAddress
  business_address_id?: string
  business_phone: string
  business_type: string
}

export interface RecipientsList extends PaginatedList<Recipient> {}
