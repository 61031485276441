import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sortBy } from 'lodash'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'
import {
  AccountAutomationConnection,
  AutomationFilter,
  AutomationIntegrationId,
  AutomationScannedEventAction,
  ScannedIndividualRecipientSettings,
  ScannedIndividualRecipientSettingsResponse
} from 'interfaces/account_automation.interface'

interface AccountAutomationIndividualScannedInitialState {
  individualRecipientSettings: ScannedIndividualRecipientSettings[]
  individualRecipientSettingsStatus: RequestStatus
  individualRecipientSettingsInitialLoading: boolean
}

const initialState: AccountAutomationIndividualScannedInitialState = {
  individualRecipientSettings: [],
  individualRecipientSettingsStatus: RequestStatus.Pending,
  individualRecipientSettingsInitialLoading: true
}

export interface ScannedIndividualRecipientSettingsRow {
  name: string
  recipient_id: string
  action: AutomationScannedEventAction
  integrations: AutomationIntegrationId[]
  filters: AutomationFilter[]
}

export const fetchIndividualRecipientSettings = createAsyncThunk(
  'individualScanned/fetchIndividualRecipientSettings',
  async (inboxId: InboxId) => {
    const response: { data: ScannedIndividualRecipientSettingsResponse } = await apiClient.get(
      `/inboxes/${inboxId}/automation-settings/scanned/recipients`
    )
    if (response.data.next_page_token == null) return response.data
    const fullResponse = { ...response.data }
    let nextPageToken = response.data.next_page_token
    while (nextPageToken != null) {
      const nextPageResponse: { data: ScannedIndividualRecipientSettingsResponse } = await apiClient.get(
        `/inboxes/${inboxId}/automation-settings/scanned/recipients`,
        { params: { page_token: nextPageToken } }
      )
      nextPageToken = nextPageResponse.data.next_page_token
      fullResponse.recipient_settings = [
        ...fullResponse.recipient_settings,
        ...nextPageResponse.data.recipient_settings
      ]
    }
    return fullResponse
  }
)

export const individualScannedSlice = createSlice({
  name: 'individualScanned',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchIndividualRecipientSettings.fulfilled, (state, action) => {
        state.individualRecipientSettings = action.payload.recipient_settings
        state.individualRecipientSettingsStatus = RequestStatus.Success
        state.individualRecipientSettingsInitialLoading = false
      })
      .addCase(fetchIndividualRecipientSettings.pending, state => {
        state.individualRecipientSettingsStatus = RequestStatus.Pending
      })
      .addCase(fetchIndividualRecipientSettings.rejected, state => {
        state.individualRecipientSettings = initialState.individualRecipientSettings
        state.individualRecipientSettingsStatus = RequestStatus.Error
      })
  }
})

export const getIndividualRecipientSettings =
  (id: number) =>
  (state: RootState): ScannedIndividualRecipientSettings | undefined =>
    state.settingsApp.accountAutomation.individualScanned.individualRecipientSettings.find(
      ({ recipient_id }) => recipient_id === String(id)
    ) // eslint-disable-line @typescript-eslint/naming-convention
export const getIndividualIntegrations =
  (integrationIds: string[]) =>
  (state: RootState): AccountAutomationConnection[] =>
    state.settingsApp.accountAutomation.account.integrationsList.filter(({ id }) =>
      integrationIds.includes(id.toString())
    )
export const getScannedIndividualRecipientSettingsViewReady = (state: RootState): boolean => {
  const {
    account: { integrationsListInitialLoading, recipientsListInitialLoading },
    individualScanned: { individualRecipientSettingsInitialLoading }
  } = state.settingsApp.accountAutomation
  return !individualRecipientSettingsInitialLoading && !integrationsListInitialLoading && !recipientsListInitialLoading
}
export const getRecipientSettingsList = (state: RootState): ScannedIndividualRecipientSettingsRow[] => {
  const { recipientsList } = state.settingsApp.accountAutomation.account

  const recipientRow = (settings: ScannedIndividualRecipientSettings): ScannedIndividualRecipientSettingsRow => {
    const recipient = recipientsList.find(({ id }) => String(id) === settings.recipient_id)
    return {
      recipient_id: settings.recipient_id,
      name: recipient?.name ?? '',
      action: settings.settings.scanned.action,
      integrations: settings.settings.scanned.integrations,
      filters: settings.settings.scanned.filters
    }
  }
  const recipientSettings =
    state.settingsApp.accountAutomation.individualScanned.individualRecipientSettings.map(recipientRow)
  return sortBy(recipientSettings, ['name']).filter(({ name }) => name !== '')
}

export default individualScannedSlice.reducer
