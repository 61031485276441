export enum ApplicantIdDocumentType {
  driversLicense = 'drivers_license',
  stateIdCard = 'state_id_card',
  usPassport = 'us_passport',
  nonUsPassport = 'non_us_passport',
  usPassportCard = 'us_passport_card',
  usPermResidentIdCard = 'us_perm_resident_id_card',
  votingRegistrationCard = 'voter_registration_card',
  vehicleRegistrationCard = 'vehicle_registration_card',
  vehicleInsurancePolicy = 'vehicle_insurance_policy',
  houseInsurancePolicy = 'home_insurance_policy',
  currentLease = 'current_lease',
  mortgage = 'mortgage',
  deedOfTrust = 'deed_of_trust'
}

export interface DocumentType {
  display_name: string
  id: string
  backside?: boolean
}

export enum IssuingEntityType {
  state = 'issuing_state',
  country = 'issuing_country'
}

export interface DocumentsGroup {
  group_id: string
  display_name: string
  verification_types: string[]
  ssn: boolean
  non_ssn: boolean
  documents: DocumentType[]
  issuing_entity_type: IssuingEntityType
}

export interface DocumentGroups {
  groups: {
    primary_ids: DocumentsGroup[]
    secondary_ids: DocumentsGroup[]
  }
  documents: DocumentType[]
}
