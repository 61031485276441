import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { ShippingAddress } from 'interfaces/shipment.interface'
import { InboxId } from 'interfaces/inbox.interface'
import { PaginatedList, RequestError, RequestStatus } from 'interfaces/common.interface'

const PER_PAGE = 20

export interface ShippingAddressesList extends PaginatedList<ShippingAddress> {}

export interface ShippingAdressesState {
  shippingAddressesPaginatedList: ShippingAddressesList
  searchedMatchPhrase: string
  shownMatchPhrase: string
  initialShippingAddressesTotal: number
  status: RequestStatus
  error: RequestError | undefined
}

interface FetchShippingAdressesArgs {
  inboxId: InboxId
  per_page?: number
  page?: number
}

const initialState: ShippingAdressesState = {
  shippingAddressesPaginatedList: {
    current_page: 1,
    data: [],
    from: 0,
    last_page: 0,
    per_page: PER_PAGE,
    to: 0,
    total: 0
  },
  searchedMatchPhrase: '',
  shownMatchPhrase: '',
  initialShippingAddressesTotal: 0,
  status: RequestStatus.Pending,
  error: undefined
}

export const fetchShippingAddressesList = createAsyncThunk(
  'sharedShippingAddresses/fetchShippingAddressesList',
  async ({ inboxId, page, per_page }: FetchShippingAdressesArgs, { getState }) => {
    // eslint-disable-line @typescript-eslint/naming-convention
    const state = getState() as RootState
    const currentPage = state.sharedShippingAddresses.shippingAddressesPaginatedList.current_page
    const searchedMatchPhrase = state.sharedShippingAddresses.searchedMatchPhrase
    const response: { data: ShippingAddressesList } = await apiClient.get(`/inboxes/${inboxId}/shipping-addresses`, {
      params: {
        per_page: per_page ?? PER_PAGE,
        page: page ?? currentPage,
        match_phrase: searchedMatchPhrase
      }
    })
    return response.data
  }
)

export const shippingAddressesSlice = createSlice({
  name: 'sharedShippingAddresses',
  initialState,
  reducers: {
    setShippingAddressesSearchedMatchPhrase(state, action: PayloadAction<string>) {
      state.searchedMatchPhrase = action.payload
    },
    setShippingAddressesInitialState: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(fetchShippingAddressesList.fulfilled, (state, action) => {
        state.status = RequestStatus.Success
        state.shippingAddressesPaginatedList = action.payload
        if (state.searchedMatchPhrase === '' && action.payload.current_page === 1)
          state.initialShippingAddressesTotal = action.payload.total
        state.shownMatchPhrase = state.searchedMatchPhrase
        state.error = undefined
      })
      .addCase(fetchShippingAddressesList.pending, state => {
        state.status = RequestStatus.Pending
      })
      .addCase(fetchShippingAddressesList.rejected, (state, action) => {
        state.status = RequestStatus.Error
        state.shippingAddressesPaginatedList = initialState.shippingAddressesPaginatedList
        state.error = action.error.message
      })
  }
})

export const { setShippingAddressesSearchedMatchPhrase, setShippingAddressesInitialState } =
  shippingAddressesSlice.actions
export const getShippingAddressesList = (state: RootState): ShippingAddress[] =>
  state.sharedShippingAddresses.shippingAddressesPaginatedList.data
export const getShippingAddressesTotal = (state: RootState): number =>
  state.sharedShippingAddresses.shippingAddressesPaginatedList.total
export const getShippingAddressesStatus = (state: RootState): RequestStatus => state.sharedShippingAddresses.status
export const isShippingAddressListReady = (state: RootState): boolean =>
  state.sharedShippingAddresses.status === RequestStatus.Success
export const getShippingAddressesSearchedMatchPhrase = (state: RootState): string =>
  state.sharedShippingAddresses.searchedMatchPhrase
export const getShippingAddressesShownMatchPhrase = (state: RootState): string =>
  state.sharedShippingAddresses.shownMatchPhrase
export const getShippingAddressesCurrentPage = (state: RootState): number =>
  state.sharedShippingAddresses.shippingAddressesPaginatedList.current_page
export const getShippingAddressesLastPage = (state: RootState): number =>
  state.sharedShippingAddresses.shippingAddressesPaginatedList.last_page
export const getHasAddresses = (state: RootState): boolean =>
  state.sharedShippingAddresses.initialShippingAddressesTotal > 0
export const getHasOneAddressOnLastPage = (state: RootState): boolean =>
  state.sharedShippingAddresses.shippingAddressesPaginatedList.from ===
  state.sharedShippingAddresses.shippingAddressesPaginatedList.to

export default shippingAddressesSlice.reducer
