import { datadogRum } from '@datadog/browser-rum'
import { getEnvConfig } from 'config/envconfig'

const { datadogAppId, datadogClientToken, env, apiBaseURL } = getEnvConfig()

const envMap = {
  production: 'p',
  development: 'd',
  qa: 'q',
  local: 'l'
}

export const initDatadogRum = () => {
  datadogRum.init({
    applicationId: datadogAppId,
    clientToken: datadogClientToken,
    site: 'datadoghq.com',
    service: 'ecm-app',
    env: envMap[env],
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
    allowedTracingUrls: [
      {
        match: apiBaseURL,
        propagatorTypes: ['tracecontext']
      }
    ]
  })
}
