import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { RecipientsBillingInfo } from 'apps/SettingsApp/interfaces/recipient_billing_info.interface'

interface RecipientsBillingInfoState {
  recipientsBillingInfo: RecipientsBillingInfo
  status: RequestStatus
  error: RequestError | undefined
}

interface FetchRecipientsBillingInfo {
  accountId: number
}

const initialState: RecipientsBillingInfoState = {
  recipientsBillingInfo: {
    current_plan_included_recipients: 0,
    current_recipient_usage: 0,
    extra_billing_unit_price: 0,
    next_recipient_extra_billing: false
  },
  status: RequestStatus.Pending,
  error: undefined
}

export const fetchRecipientsBillingInfo = createAsyncThunk(
  'recipientsBillingInfo/fetchRecipientsBillingInfo',
  async ({ accountId }: FetchRecipientsBillingInfo) => {
    const response: { data: RecipientsBillingInfo } = await apiClient.get(
      `accounts/${accountId}/recipients-billing-info`
    )
    return response.data
  }
)

export const recipientsBillingInfoSlice = createSlice({
  name: 'recipientsBillingInfo',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchRecipientsBillingInfo.fulfilled, (state, action) => {
        state.status = RequestStatus.Success
        state.recipientsBillingInfo = action.payload
        state.error = undefined
      })
      .addCase(fetchRecipientsBillingInfo.rejected, (state, action) => {
        state.status = RequestStatus.Error
        state.recipientsBillingInfo = initialState.recipientsBillingInfo
        state.error = action.error.message
      })
  }
})

export const getRecipientsBillingInfo = (state: RootState): RecipientsBillingInfo =>
  state.settingsApp.recipientsBillingInfo.recipientsBillingInfo

export default recipientsBillingInfoSlice.reducer
