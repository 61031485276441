import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { PiecesList } from 'interfaces/piece.interface'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'

interface StoredPiecesState {
  storedPiecesCount: number
  storedPiecesStatus: RequestStatus
  storedPiecesStatusError: RequestError | undefined
  storedPiecesIncurringFeeCount: number
  storedPiecesIncurringFeeStatus: RequestStatus
  storedPiecesIncurringFeeError: RequestError | undefined
}

const initialState: StoredPiecesState = {
  storedPiecesCount: 0,
  storedPiecesStatus: RequestStatus.Pending,
  storedPiecesStatusError: undefined,
  storedPiecesIncurringFeeCount: 0,
  storedPiecesIncurringFeeStatus: RequestStatus.Pending,
  storedPiecesIncurringFeeError: undefined
}

export const fetchStoredPieces = createAsyncThunk('storedPieces/fetchStoredPieces', async (inboxId: InboxId) => {
  const response: { data: PiecesList } = await apiClient.get(
    `/inboxes/${inboxId}/pieces?attributes=physical-mail&filters=in:all&page=1&payload_type=3&per_page=25&sort_by=received_at&sort_direction=1&view_type=mailroom`
  )
  return response.data.total
})

export const fetchStoredPiecesIncurringFee = createAsyncThunk(
  'storedPieces/fetchStoredPiecesIncurringFee',
  async (inboxId: InboxId) => {
    const response: { data: PiecesList } = await apiClient.get(
      `/inboxes/${inboxId}/pieces?attributes=stored&filters=in:all&page=1&payload_type=3&per_page=25&sort_by=received_at&sort_direction=1&view_type=mailroom`
    )
    return response.data.total
  }
)

export const storedPiecesSlice = createSlice({
  name: 'storedPieces',
  initialState,
  reducers: {
    resetStoredPiecesState: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStoredPieces.fulfilled, (state, action) => {
        state.storedPiecesStatus = RequestStatus.Success
        state.storedPiecesCount = action.payload
        state.storedPiecesStatusError = undefined
      })
      .addCase(fetchStoredPiecesIncurringFee.fulfilled, (state, action) => {
        state.storedPiecesIncurringFeeStatus = RequestStatus.Success
        state.storedPiecesIncurringFeeCount = action.payload
        state.storedPiecesIncurringFeeError = undefined
      })
  }
})

export default storedPiecesSlice.reducer

export const { resetStoredPiecesState } = storedPiecesSlice.actions
export const getStoredPieces = (state: RootState): number => state.dashboardApp.storedPieces.storedPiecesCount
export const getStoredPiecesIncurringFeeCount = (state: RootState): number =>
  state.dashboardApp.storedPieces.storedPiecesIncurringFeeCount
export const isStoredPiecesReady = (state: RootState): boolean =>
  state.dashboardApp.storedPieces.storedPiecesStatus === RequestStatus.Success
export const isPiecesIncurringFeeReady = (state: RootState): boolean =>
  state.dashboardApp.storedPieces.storedPiecesIncurringFeeStatus === RequestStatus.Success
