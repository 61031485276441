import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'
import { RootState } from 'store/store'
import { CurrentUserInfo, CurrentUserAttributes } from 'interfaces/settings_user.interface'
import { IdpProviders, RequestError, RequestStatus } from 'interfaces/common.interface'

interface CurrentInfoState {
  currentUserInfo: CurrentUserInfo | null
  status: RequestStatus
  error: RequestError | null
}

const initialState: CurrentInfoState = {
  status: RequestStatus.Pending,
  currentUserInfo: null,
  error: null
}

export const providerNameFromIdentities = (identities: string | undefined): string | undefined => {
  if (identities === undefined) return
  const parsedIdentities = JSON.parse(identities) as Array<{ providerName?: string }>
  if (parsedIdentities.length === 0) return
  return parsedIdentities[0].providerName === 'SignInWithApple'
    ? IdpProviders.Apple
    : (parsedIdentities[0].providerName as IdpProviders)
}

export const fetchCurrentUserInfo = createAsyncThunk('currentUserInfo/fetchCurrentUserInfo', async () => {
  const response: CurrentUserInfo = await Auth.currentUserInfo()
  return response
})

export const currentUserInfoSlice = createSlice({
  name: 'currentUserInfo',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCurrentUserInfo.fulfilled, (state, action) => {
      state.currentUserInfo = action.payload
      state.status = RequestStatus.Success
    })
  }
})

export const getCurrentUserAttributes = (state: RootState): CurrentUserAttributes | undefined =>
  state.settingsApp.currentUserInfo.currentUserInfo?.attributes
export const getCurrentUserAttributesReady = (state: RootState): boolean =>
  state.settingsApp.currentUserInfo.status === RequestStatus.Success
export const getCurrentUserProviderName = (state: RootState): string | undefined =>
  providerNameFromIdentities(state.settingsApp.currentUserInfo.currentUserInfo?.attributes?.identities)

export default currentUserInfoSlice.reducer
