import pluralize from 'pluralize'
import { Connection, Integration } from 'apps/SettingsApp/interfaces/integrations.interface'

export interface DecoratedIntegration extends Integration {
  displayName: string
  url: string
  title: string
}

export const decorateIntegration = (integration: Integration): DecoratedIntegration => {
  return {
    ...integration,
    displayName: INTEGRATIONS_DISPLAY_NAME[integration.name],
    url: INTEGRATIONS_URL[integration.name],
    title: INTEGRATIONS_TITLE[integration.name]
  }
}

export const connectionName = (connection: Connection): string | undefined => {
  if (connection.name != null && connection.name !== '') return connection.name
  if (connection.user_email != null && connection.user_email !== '') return connection.user_email
}

export const prepareDisconnectDescription = (
  integration: DecoratedIntegration,
  connection: Connection,
  rulesCount: number
): string => {
  const disconnectMessage = `You’re about to disconnect ${integration.displayName} integration`
  const accountName = connectionName(connection)
  const accountNameMessage = accountName != null ? ` for ${accountName} account` : ''
  const connectedRulesCountInformation =
    rulesCount > 0 ? ` and delete ${rulesCount} automation ${pluralize('rule', rulesCount)} associated with it.` : '.'
  const confirmationQuestion = rulesCount > 0 ? ' Proceed?' : ' Are you sure?'

  return [disconnectMessage, accountNameMessage, connectedRulesCountInformation, confirmationQuestion].join('')
}

export const prepareSuccessAlertMessage = (
  integration: DecoratedIntegration,
  connection: Connection,
  rulesCount: number
): string => {
  const integrationMessage = `${integration.displayName} integration`
  const accountName = connectionName(connection)
  const accountNameMessage = accountName != null ? ` for ${accountName} account` : ''
  const connectedRulesCountInformation =
    rulesCount > 0
      ? ` and ${rulesCount} automation ${pluralize('rule', rulesCount)} have been removed.`
      : ' has been removed.'
  return [integrationMessage, accountNameMessage, connectedRulesCountInformation].join('')
}

const INTEGRATIONS_DISPLAY_NAME = {
  billcom: 'Bill.com',
  box: 'Box',
  dropbox: 'Dropbox',
  googledrive: 'Google Drive',
  qbo: 'QuickBooks',
  xero: 'Xero'
}

const INTEGRATIONS_URL = {
  billcom: 'billcom',
  box: 'box',
  dropbox: 'dropbox',
  googledrive: 'googledrive',
  qbo: 'quickbooks',
  xero: 'xero'
}

const INTEGRATIONS_TITLE = {
  billcom: 'Save time by easily sharing scanned invoices to your Bill.com Inbox.',
  box: 'Copy and archive selected items from your ECM inbox in your Box account.',
  dropbox: 'Share and archive selected items from ECM inbox to your Dropbox account.',
  googledrive: 'Backup selected items from your ECM inbox to your Google Drive.',
  qbo: 'Seamlessly record checks deposited through ECM for easy bookkeeping.',
  xero: 'Apply received payment checks towards Invoices and Accounts in Xero.'
}
