import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { PiecesList, Piece } from 'interfaces/piece.interface'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'

interface PiecesListState {
  piecesList: Piece[]
  status: RequestStatus
  error: RequestError | undefined
}

const initialState: PiecesListState = {
  piecesList: [],
  status: RequestStatus.Pending,
  error: undefined
}

export const fetchPieces = createAsyncThunk('piecesList/fetchPieces', async (inboxId: InboxId) => {
  const response: { data: PiecesList } = await apiClient.get(
    `/inboxes/${inboxId}/pieces?filters=in:inbox&page=1&payload_type=3&per_page=4&sort_by=received_at&sort_direction=1&view_type=mailroom`
  )
  return response.data.data
})

export const piecesSlice = createSlice({
  name: 'piecesList',
  initialState,
  reducers: {
    resetPiecesState: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchPieces.fulfilled, (state, action) => {
      state.status = RequestStatus.Success
      state.piecesList = action.payload
      state.error = undefined
    })
  }
})

export default piecesSlice.reducer

export const { resetPiecesState } = piecesSlice.actions
export const getRecentPieces = (state: RootState): Piece[] => state.dashboardApp.piecesList.piecesList
export const isPiecesReady = (state: RootState): boolean =>
  state.dashboardApp.piecesList.status === RequestStatus.Success
