import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import { apiBaseURL, marketingRedirectUrl } from 'config/config'
import { getOrRefreshAccessTokenWithCognitoOrStoredCredentials } from './credentials_service'

const baseURL = apiBaseURL + '/v1/'

const getAuthorizationHeader = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  let responseTest
  try {
    responseTest = await getOrRefreshAccessTokenWithCognitoOrStoredCredentials()
  } catch (e) {
    console.log('apiClient.interceptors#e', e)
    throw e
  }
  // const bearerToken: string | null = accessTokenService.accessToken()
  config.headers.Authorization = `Bearer ${responseTest}`
  return config
}

export const apiClient: AxiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
})

apiClient.interceptors.request.use(async function (config) {
  return getAuthorizationHeader(config)
})

apiClient.interceptors.response.use(
  response => response,
  async error => {
    console.log('apiClient.interceptors#e', { error })
    if (error?.response?.status === 401) {
      window.location.href = '/logout'
    }
    return await Promise.reject(error)
  }
)

export const apiWithoutAuthorization: AxiosInstance = ((): AxiosInstance => {
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json'
    }
  })
})()

export const apiForLoginUsers: AxiosInstance = ((): AxiosInstance => {
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json'
    }
  })
})()

apiForLoginUsers.interceptors.response.use(
  response => response,
  async error => {
    console.log('apiForLoginUsers.interceptors#e', { error })
    if (error?.response?.status === 401) {
      if (error?.response.data?.error?.message === 'Account is closed or terminated, please contact support.') {
        window.location.href = '/logout?accountClosedOrTerminated=true'
      } else {
        window.location.href = marketingRedirectUrl
      }
    }
    return await Promise.reject(error)
  }
)

apiForLoginUsers.interceptors.request.use(async function (config) {
  return getAuthorizationHeader(config)
})

const moduleApi = {
  api: apiClient
}

export default moduleApi
